import { css as stitchesCss, VariantProps } from "@stitches/react";
import { ButtonHTMLAttributes, MouseEvent, ReactNode } from "react";
import { Css, Palette } from "src/Css";
import { useTestIds } from "src/hooks";

const styles = stitchesCss("button", {
  // mini reset
  appearance: "none",
  border: "none",
  backgroundColor: "transparent",

  // base button style
  ...Css.br4.df.aic.jcc.gapPx(4).relative.nowrap.cursorPointer.$,

  variants: {
    variant: {
      primary: {
        ...Css.bgBlue600.whitePure.$,
        "&:hover": Css.bgBlue800.$,
      },
      secondary: {
        ...Css.bgGray900.whitePure.$,
        "&:hover": Css.bgBlack.$,
      },
      tertiary: {
        ...Css.gray900.bGray300.boxShadow(`0 0 0 1px ${Palette.Black}`).$,
        "&:hover": Css.bgOffWhite.$,
      },
      ghost: {
        ...Css.blue600.$,
        "svg path": Css.add("fill", Palette.Blue600).$,
        "&:hover": Css.bgGray100.$,
      },
      // TODO: implement ghost and danger variants
    },
    size: {
      small: Css.px1.pyPx(3).f12.lh("18px").fw5.add("letterSpacing", "unset").$,
      medium: Css.pxPx(12).pyPx(7).f14.lh("18px").fw5.add("letterSpacing", "unset").$,
      large: Css.px2.pyPx(11).f14.lh("18px").fw5.add("letterSpacing", "unset").$,
      xl: Css.pxPx(20).pyPx(15).f16.lh("18px").fw5.add("letterSpacing", "unset").$,
    },
    disabled: {
      true: Css.cursorNotAllowed.$,
    },
    state: {
      active: Css.bgGray700.$,
      danger: Css.bgRed600.$,
    },
    width: {
      full: Css.w100.$,
      none: {},
    },
  },

  defaultVariants: {
    variant: "primary",
    size: "medium",
  },

  compoundVariants: [
    {
      variant: "tertiary",
      state: "active",
      css: {
        ...Css.bgGray900.bGray900.whitePure.$,
        "&:hover": Css.bgGray900.$,
      },
    },
    {
      variant: "primary",
      disabled: true,
      css: Css.bgGray300.gray500.$,
    },
    {
      variant: "tertiary",
      disabled: true,
      css: Css.boxShadow(`0 0 0 1px ${Palette.Gray500}`).gray500.$,
    },
    {
      variant: "ghost",
      state: "danger",
      css: {
        ...Css.bgTransparent.red600.$,
        "svg path": Css.add("fill", Palette.Red600).$,
      },
    },
  ],
});

type ButtonProps = Omit<VariantProps<typeof styles>, "disabled"> & {
  iconLeft?: keyof typeof ButtonIcons;
  children: ReactNode;
  /** Optional onClick since when used with forms, onClick is not required */
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  /**
   * Disables the button when given a truthy value. When given a string, a
   * Tooltip is shown with the string as the content.
   *
   * NOTE: To customize the tooltip place, change the tooltipPlacement prop.
   */
  disabled?: boolean | string;
  /**
   * Tooltip placement for tooltip and disabled reason.
   * @default "right"
   */
  openInNewTab?: boolean;
  /**
   * Prevents default and stops propagation of the event
   * @default false
   *
   * TODO: Should we be always stopping propagation and require an explicit
   * prop to allow propagation?
   * */
  stopPropagation?: boolean;
  /** Use for mapping when passing btn props */
  key?: string;
  /** Show spinner when loading */
  isLoading?: boolean;
  loadingText?: "Loading" | "Saving" | "Submitting";
};

export function Button(props: ButtonProps) {
  const {
    iconLeft,
    children,
    onClick,
    variant,
    size,
    disabled,
    stopPropagation = false,
    type,
    isLoading = false,
    width = "full",
    state,
  } = props;
  const tid = useTestIds("button");

  function handleClick(e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) {
    if (disabled) {
      return;
    }
    if (stopPropagation) {
      e.preventDefault();
      e.stopPropagation();
    }
    onClick?.(e);
  }

  return (
    <button
      className={styles({
        variant,
        size,
        disabled: !!disabled || isLoading,
        state,
        width,
      })}
      onClick={handleClick}
      disabled={!!disabled || isLoading}
      type={type}
      {...tid}
    >
      {iconLeft ? (
        <>
          {ButtonIcons[iconLeft]}
          {children}
        </>
      ) : (
        children
      )}
    </button>
  );
}

// Separating these from the other icons as they should be a standard of 16 x 16
const ButtonIcons = {
  plus: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" transform="translate(0 16) rotate(-90)" />
      <path
        d="M7.5 7.5L4 7.5L4 8.5L7.5 8.5L7.5 12L8.5 12L8.5 8.5L12 8.5L12 7.5L8.5 7.5L8.5 4L7.5 4L7.5 7.5Z"
        fill="#242424"
      />
    </svg>
  ),
  trash: (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.23333 14.1667C4.61749 14.1667 4.11667 13.6658 4.11667 13.05V6.35H3V5.23333H4.675H5.23333V4.11667C5.23333 3.50082 5.73416 3 6.35 3H9.69999C10.3158 3 10.8167 3.50082 10.8167 4.11667V5.23333H11.375H13.05V6.35H11.9333V13.05C11.9333 13.6658 11.4325 14.1667 10.8167 14.1667H5.23333ZM9.69999 4.11667H6.35V5.23333H9.69999V4.11667ZM10.2583 6.35H5.79166H5.23333V13.05H10.8172L10.8167 6.35H10.2583ZM7.46628 7.46667H6.34961V11.9333H7.46628V7.46667ZM9.69961 7.46667H8.58294V11.9333H9.69961V7.46667Z"
        fill="#DC2626"
      />
    </svg>
  ),
};
