import { Palette } from "src/Css";
import tinycolor from "tinycolor2";

export const chartColors = [
  Palette.Blue,
  Palette.Stone,
  Palette.GreenSaturated,
  Palette.BlueBright,
  Palette.RedDark,
  Palette.Orange,
  Palette.Yellow,
  Palette.BrightGreen,
  Palette.Toast,
  Palette.Red,
];

// list of 40 generated colors
export const generatedColors = [
  "#526675",
  "#a29983",
  "#657839",
  "#3a759d",
  "#8b514e",
  "#d17520",
  "#f6b14e",
  "#aeb564",
  "#cd9772",
  "#c86251",
  "#788c9b",
  "#c8bfa9",
  "#8b9e5f",
  "#609bc3",
  "#b17774",
  "#f79b46",
  "#ffd774",
  "#d4db8a",
  "#f3bd98",
  "#ee8877",
  "#3d4c57",
  "#8a8068",
  "#485529",
  "#2c5978",
  "#6a3e3c",
  "#a55c19",
  "#f49c1d",
  "#959c4a",
  "#c07b4c",
  "#af4837",
  "#436884",
  "#b2a073",
  "#6a852c",
  "#2a78ad",
  "#9b423e",
  "#e3740e",
  "#ffb345",
  "#bcc653",
  "#db9464",
  "#d95640",
];

export function generateColors() {
  const numColors = chartColors.length;
  const numToGenerate = 40;

  let generatedColors = [];
  for (let i = 0; i < numToGenerate; i++) {
    const color = tinycolor(chartColors[i % numColors]);

    if (i < 10) {
      generatedColors.push(color.toString());
    } else if (i < 20) {
      generatedColors.push(color.brighten(15).toString());
    } else if (i < 30) {
      generatedColors.push(color.darken(10).toString());
    } else {
      generatedColors.push(color.saturate(15).toString());
    }
  }
  return generatedColors;
}
