import { ReactNode } from "react";
import { Breakpoints, Css } from "src/Css";

/** Props */
interface PageContentProps<X> {
  children: ReactNode; // Children are required
  xss?: X;
}

/**
 * Component - Page Content
 *
 * Helper component to handle duplicate layout code for page content.
 */
export function PageContent<X>({ children, xss }: PageContentProps<X>) {
  return (
    <div
      css={{
        ...Css.gc("1 / -1").$,
        [Breakpoints.lgAndUp]: Css.gc("3 / -3").$,
        [Breakpoints.md]: Css.gc("2 / -2").$,
        ...xss,
      }}
    >
      {children}
    </div>
  );
}
