import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  IconButton as MuiIconButton,
  Link,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { Css, increment, Margin, Only, Palette, Position, px, Xss } from "src/Css";
import { useTestIds } from "src/hooks";

export type ButtonXss = Xss<
  Margin | Position | "textTransform" | "display" | "borderWidth" | "padding" | "borderRadius"
>;

/** Enable outline styles for buttons */
type Outline = { outline?: boolean };

// Base button interface which includes all HTML attributes and Button attributes
interface BaseButtonProps<X extends Only<ButtonXss, X>> extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  xss?: X;
  testid?: string;
  href?: HTMLAnchorElement["href"];
  target?: HTMLAnchorElement["target"];
  rel?: HTMLAnchorElement["rel"];
}

// Button props interface which only exposes used Mui props
export interface ButtonProps<X extends Only<ButtonXss, X>>
  extends BaseButtonProps<X>,
    Pick<MuiButtonProps, "fullWidth" | "startIcon" | "endIcon"> {}

export interface DestructiveButtonProps<X extends Only<ButtonXss, X>> extends ButtonProps<X> {
  destructive?: boolean;
}

export interface OutlineButtonProps<X extends Only<ButtonXss, X>> extends DestructiveButtonProps<X>, Outline {
  // Determine how wide the button is, using narrow renders a button not as wide as "wide" button
  size?: "wide" | "narrow";
}

export interface TextButtonProps<X extends Only<ButtonXss, X>> extends DestructiveButtonProps<X>, Outline {}

export interface IconButtonProps<X extends Only<ButtonXss, X>> extends BaseButtonProps<X>, Outline {
  // Render the small version of the icon button
  small?: boolean;
}

function buttonStyles() {
  return {
    root: {
      // Base button styles
      ...Css.px7.py1.t12up.cursorPointer.brPill.h(px(34)).outline0.shadowNone.bw1.bsSolid.bTransparent.$,
      "&:hover, &:active": Css.shadowNone.noUnderline.$,
      "&:focus": Css.shadowNone.bsDashed.$,
      "&:disabled, &[aria-disabled=true]": {
        ...Css.bw1.add("cursor", "not-allowed").add("pointerEvents", "auto").$,
        // Temporary solution to disable child click events on disabled buttons
        "& > *": {
          ...Css.add("pointerEvents", "none").$,
        },
      },
      // Primary button styles
      "&.primary": {
        ...Css.bgBlack.bTransparent.white.$,
        "&:focus": Css.bWhite.$,
        "&:hover, &:active": Css.bgBlackFaded.bTransparent.$,
        "&:disabled, &[aria-disabled=true]": Css.bgGray400.$,
      },
      // Outline button styles
      "&.outline": {
        ...Css.px(px(increment(7) - 2)).py(px(increment(1) - 2)).black.bBlack.bw2.$,
        "&:hover, &:active": Css.bgTransparentGray.bTransparent.bBlack.$,
        "&:disabled, &[aria-disabled=true]": Css.gray400.bGray400.$,
        "&.destructive": {
          ...Css.bgRed.bRed.white.$,
          "&:focus": Css.bWhite.$,
          "&:hover, &:active": Css.bgRedFaded.bTransparent.$,
          "&:disabled, &[aria-disabled=true]": Css.gray600.bGray400.bgRedFadedTransparent.$,
        },
      },
      "&.narrow": {
        ...Css.px5.$,
      },
      // Text button styles
      "&.text": {
        // Smaller padding left and right for text buttons
        ...Css.px2.black.$,
        "&:focus": Css.bGray600.bgTransparentGray.$,
        "&:hover, &:active": Css.bgTransparentGray.bTransparent.$,
        "&:disabled, &[aria-disabled=true]": Css.gray400.bgLightTransparentGray.add("stroke", Palette.Gray400).$,
        "&.destructive": {
          ...Css.red.$,
          "&:disabled, &[aria-disabled=true]": Css.redFaded.$,
        },
        "&.outline": Css.bGray400.$,
      },
      // Icon button styles
      "&.icon": {
        // Smaller padding all around for icon buttons
        ...Css.p(px(increment(1) - 2))
          .br100.bsSolid.black.bgTransparent.mw(px(40))
          .mh(px(40)).$,
        "&:focus": Css.bgTransparentGray.bGray600.bsDashed.$,
        "&:hover, &:active": Css.bgTransparentGray.bTransparent.$,
        "&:disabled, &[aria-disabled=true]": Css.bgTransparentGray.gray400.$,
        "&.outline": Css.bGray400.$,
        "&.small": Css.mw("auto").mh("auto").h(px(35)).w(px(35)).$,
      },
      // TODO: following should somehow be incorporated into a new button component as we remove MUI
      "&.secondary": {
        ...Css.bgWhite.bTransparent.black.bsSolid.$,
        "&:focus": Css.bsSolid.bBlack.$,
        "&:hover, &:active": Css.bgWhitePure.bGray700.$,
        "&:disabled, &[aria-disabled=true]": Css.bgGray400.$,
        "&.icon": {
          "&:focus": Css.bsSolid.bgWhite.bBlack.$,
          "&:hover, &:active": Css.bgWhitePure.bGray700.$,
        },
      },
    },
  };
}

const RawButton = React.forwardRef<HTMLButtonElement, any>((props, ref) => {
  const { xss, onClick, children, testid, href, target, rel, ...otherProps } = props;
  // uses button text as testId unless button already has a data-testid or testid prop on it
  const text = typeof children === "string" ? `${children.replace(/\W/g, "").toLowerCase()}Button` : "button";
  const [testId] = useTestIds(testid || text);
  const [buttonRef, handleClick] = useDisableClickFocus(ref, onClick);

  // If href is given, return an anchor tag
  if (href) {
    return (
      <Link
        {...{ href, target, rel, children, ...testId, ...otherProps }}
        css={xss}
        onClick={handleClick}
        ref={buttonRef}
        variant="inherit"
      />
    );
  }

  return <MuiButton ref={buttonRef} css={xss} onClick={handleClick} {...{ children, ...testId, ...otherProps }} />;
});
const StyledButton = withStyles(buttonStyles)(RawButton);
/** Primary button that wraps the MUI button with override styles. */
export const PrimaryButton = React.forwardRef<HTMLButtonElement, ButtonProps<ButtonXss>>((props, ref) => {
  const extraClassName = props.className ? ` ${props.className}` : "";
  return <StyledButton {...{ ref, ...props }} className={`primary${extraClassName}`} />;
});

// TODO: SecondaryButton should somehow be incorporated into a new button component as we remove MUI
/** Secondary button that wraps the MUI button with override styles. */
export const SecondaryButton = React.forwardRef<HTMLButtonElement, ButtonProps<ButtonXss>>((props, ref) => {
  const extraClassName = props.className ? ` ${props.className}` : "";
  return <StyledButton {...{ ref, ...props }} className={`secondary${extraClassName}`} />;
});

/** Outline button that wraps the MUI button with override styles. This button handles a destructive state as well. */
export const OutlineButton = React.forwardRef<HTMLButtonElement, OutlineButtonProps<ButtonXss>>((props, ref) => {
  const { destructive, size, ...otherProps } = props;
  let className = `outline ${destructive ? "destructive" : ""}`;
  if (size === "narrow") className += " narrow";
  return <StyledButton {...{ ref, className, ...otherProps }} variant="outlined" />;
});

/**
 * Text button which wraps the MUI button with override styles.
 * This button handles a destructive state as well.
 * */
export const TextButton = React.forwardRef<HTMLButtonElement, TextButtonProps<ButtonXss>>((props, ref) => {
  const { destructive, outline, ...otherProps } = props;

  let className = "text";
  if (outline) className += " outline";
  if (destructive) className += " destructive";
  if (props.className) className += ` ${props.className}`;

  return <StyledButton {...{ ref, className, ...otherProps }} variant="text" />;
});

const RawIconButton = React.forwardRef<HTMLButtonElement, any>((props, ref) => {
  const { xss, onClick, testid, href, target, rel, ...otherProps } = props;
  const [buttonRef, handleClick] = useDisableClickFocus(ref, onClick);
  const [testId] = useTestIds(props["data-testid"] || testid || "iconButton");

  // If href is given, return an anchor tag
  if (href) {
    return (
      <Link {...{ href, target, rel, ...testId, ...otherProps }} css={xss} onClick={handleClick} ref={buttonRef} />
    );
  }

  return <MuiIconButton ref={buttonRef} onClick={handleClick} {...{ ...otherProps, ...testId }} css={xss} />;
});

const StyledIconButton = withStyles(buttonStyles)(RawIconButton);

/**
 * Icon button component which accepts a MUI or React.Node icon child.
 *
 * TODO: Future enhancement
 * - Color prop for easy icon styling
 * */
export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps<ButtonXss>>((props, ref) => {
  const { outline, small, ...otherProps } = props;

  let className = "icon";
  if (outline) className += " outline";
  if (small) className += " small";
  if (props.className) className += ` ${props.className}`;

  return <StyledIconButton {...{ ref, ...otherProps, className }} />;
});

/** Hook which disabled the focus state when clicking on a button */
function useDisableClickFocus(ref: any, onClick: any) {
  const handleClick = (e: React.MouseEvent) => {
    // https://github.com/Microsoft/TypeScript/issues/5901#issuecomment-431649653
    if (ref?.current instanceof HTMLElement) ref?.current?.blur();
    onClick && onClick(e);
  };
  // Returns a new ref and pass through onClick event
  return [ref, handleClick];
}
