import React from "react";
import { Colors, HomeownerPalette } from "src/theme";

interface CheckmarkProps {
  complete?: boolean;
  completionColor?: Colors;
  checkColor?: Colors;
  size?: number;
}

export const Checkmark: React.FC<CheckmarkProps> = ({ complete = true, completionColor, size = 30, checkColor }) => {
  const color = complete ? completionColor || HomeownerPalette.Yellow : HomeownerPalette.Taupe;

  const image = checkmarkIcon(color, size, checkColor);

  return <div css={{ height: size }}>{image}</div>;
};

function checkmarkIcon(color: Colors, size: number, checkColor = "#FCFCFA") {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox="0 0 30 30"
    >
      <defs>
        <path id="checkmark__a" d="M6.782 12.3L3.489 9.008 2.368 10.121 6.782 14.534 16.255 5.061 15.142 3.947z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle cx="15" cy="15" r="15" fill={color} />
        <g transform="translate(5.526 5.526)">
          <path d="M0 0H18.947V18.947H0z" />
          <mask id="checkmark__b" fill="#fff">
            <use xlinkHref="#checkmark__a" />
          </mask>
          <use fill="#fff" fillRule="nonzero" xlinkHref="#checkmark__a" />
          <g fill={checkColor} mask="url(#checkmark__b)">
            <path d="M0 0H18.947V18.947H0z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
