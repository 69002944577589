import { Only } from "src/Css";
import { defaultIconProps, IconXss, InteractiveIconProps } from "./";

export function CircleCheckMark<X extends Only<IconXss, X>>(props: InteractiveIconProps<X>) {
  const { color, xss, secondaryColor } = {
    ...defaultIconProps,
    ...props,
  };
  return (
    <svg css={xss} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill={color}
      />
      <path
        d="M9.81352 15.2177L6.96873 12.3729L6 13.3348L9.81352 17.1484L18 8.96191L17.0381 8L9.81352 15.2177Z"
        fill={secondaryColor}
      />
    </svg>
  );
}
