import { Document, Page } from "react-pdf";
import { Icon } from "src/components";
import { Css } from "src/Css";
import { AssetInfoFragment } from "src/generated/graphql-types";

interface AssetPreviewProps {
  asset: AssetInfoFragment;
  dimensions?: {
    width?: number;
    height?: number;
  };
}

export function AssetPreview(props: AssetPreviewProps) {
  const { asset, dimensions } = props;
  const docType = asset.contentType;

  if (docType === "application/pdf") {
    return <PdfPreview asset={asset} dimensions={dimensions} />;
  } else if (docType.includes("image/") && asset.previewUrl) {
    return <ImagePreview asset={asset} dimensions={dimensions} />;
  } else {
    return <NoPreviewComponent asset={asset} dimensions={dimensions} />;
  }
}

function PdfPreview({ asset, dimensions }: AssetPreviewProps) {
  return (
    <Document file={asset.downloadUrl} loading="" renderMode="svg">
      <Page
        css={Css.w100.$}
        height={dimensions?.height ?? 200}
        loading=""
        pageNumber={1}
        renderMode="canvas"
        renderTextLayer={false}
        renderAnnotationLayer={false}
      />
    </Document>
  );
}

function ImagePreview({ asset, dimensions }: AssetPreviewProps) {
  const { downloadUrl } = asset;

  return (
    <img
      alt="Preview"
      src={downloadUrl}
      css={{
        ...previewStyles,
        ...Css.objectContain.ais.w100.h100.$,
      }}
    />
  );
}

function NoPreviewComponent({ dimensions }: AssetPreviewProps) {
  return (
    <div
      css={{
        ...previewStyles,
        ...Css.if(!!dimensions?.width).wPx(dimensions?.width!).mwPx(dimensions?.width!).else.w100.$,
        ...Css.hPx(dimensions?.height ?? 80).mhPx(dimensions?.height ?? 80).$,
      }}
    >
      <span css={Css.mr2.fs0.$}>
        <Icon icon="home" />
      </span>
      <div>No preview available</div>
    </div>
  );
}
const previewStyles = Css.bn.br4.df.aic.jcc.shadowBasic.cursorPointer.bgWhitePure.$;
