import { Typography } from "src/components/Typography";
import { Css, Palette } from "src/Css";
import { shortMonthYearFormat } from "src/utils";

interface ProgressBarProps {
  title: string;
  completed: number;
  total: number;
  start?: Date;
  end?: Date;
}

export function ProgressBar({ completed, total, start, end, title }: ProgressBarProps) {
  const allTasksComplete = total === completed;

  let unitArray = Array.from(Array(total).keys()).map((i) => {
    const isComplete = i < completed;
    return <UnitDiv isComplete={isComplete} key={i} />;
  });

  return (
    <>
      <div css={Css.mb2.df.$}>
        <div css={Css.mra.header16.fw4.$}>{title}</div>
        <div css={Css.f14.gray400.$}>
          {completed} of {total} Complete
        </div>
      </div>
      <div css={unitWrapper}>{unitArray}</div>
      {start && end && (
        <>
          <div css={Css.mt1.df.jcsb.$}>
            <Typography size="f12" lineHeight={18} color={Palette.Gray600}>
              {shortMonthYearFormat(start)}
            </Typography>
            <Typography size="f12" lineHeight={18} color={Palette.Gray600}>
              {shortMonthYearFormat(end)}
            </Typography>
          </div>
          <div css={Css.df.jcsb.$}>
            <Typography size="f12" color={Palette.Gray600}>
              Start
            </Typography>
            <Typography size="f12" color={Palette.Gray600}>
              {allTasksComplete ? "Completed" : "End"}
            </Typography>
          </div>
        </>
      )}
    </>
  );
}

const unitWrapper = {
  display: "flex",
  "div:first-of-type": {
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
    marginRight: 1,
  },
  "div:last-of-type": {
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    marginLeft: 1,
  },
  div: {
    margin: "0 1px",
    height: "16px",
    width: "100%",
  },
};

interface UnitDivProps {
  isComplete: boolean;
}

function UnitDiv(props: UnitDivProps) {
  const { isComplete } = props;
  return <div css={Css.bgGray200.if(isComplete).bgGreen200.$}></div>;
}
