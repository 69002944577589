import * as React from "react";
import { ChangeEvent } from "react";
import { Css, Palette } from "src/Css";
import Tribute from "tributejs";
import "tributejs/dist/tribute.css";

export interface TrixEditorProps {
  className?: string;
  autoFocus?: boolean;
  placeholder?: string;
  toolbar?: string;
  value?: string;
  uploadURL?: string;
  uploadData?: { [key: string]: string };
  fileParamName?: string;

  mergeTags: string[];

  onEditorReady?: (editor: any) => void;
  onChange: (html: string, text: string) => void;
}

export interface TrixEditorState {
  showMergeTags: boolean;
  tags: string[];
}

export type Editor = {
  expandSelectionInDirection?: (direction: "forward" | "backward") => void;
  insertString?: (s: string) => void;
} | null;

/**
 * A modified version of [react-trix]{@link https://github.com/dstpierre/react-trix},
 *
 * which is a React wrapper around [trix]{@link https://github.com/basecamp/trix}.
 *
 * We added mentions via [tributejs]{@link https://github.com/zurb/tribute} and removed buggy/unnecessary code.
 * */
export class TrixEditor extends React.Component<TrixEditorProps, TrixEditorState> {
  private id: string;
  private container: any = null;
  private editor: Editor = null;
  private d: HTMLDivElement | null = null;
  constructor(props: TrixEditorProps) {
    super(props);

    this.id = this.generateId();

    this.state = {
      showMergeTags: false,
      tags: [],
    };
  }
  private generateId(): string {
    let dt = new Date().getTime();
    let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      let r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
    return "T" + uuid;
  }
  componentDidMount() {
    let props = this.props;

    this.container = document.getElementById(`editor-${this.id}`);
    if (this.container) {
      this.container.addEventListener(
        "trix-initialize",
        () => {
          this.editor = this.container.editor;
          if (!this.editor) {
            console.error("cannot  find trix editor");
          }

          const values = this.props.mergeTags.map((value) => ({ value }));
          const tribute = new Tribute({
            trigger: "@",
            lookup: "value",
            allowSpaces: true,
            /** {@link https://github.com/zurb/tribute#hide-menu-when-no-match-is-returned} */
            noMatchTemplate: () => `<span style:"visibility: hidden;"></span>`,
            selectTemplate: ({ original: { value } }) => `<span style="color: ${Palette.White};">@${value}</span>`,
            values,
          });

          if (this.container.composition) {
            this.container.composition.delegate.inputController.events.keypress = function () {};
            this.container.composition.delegate.inputController.events.keydown = function () {};
            this.container.composition.delegate.inputController.events.keyup = function () {};
          }
          tribute.attach(this.container);

          if (props.onEditorReady && typeof props.onEditorReady == "function") {
            props.onEditorReady(this.editor);
          }
        },
        false,
      );
      this.container.addEventListener("trix-change", this.handleChange.bind(this), false);
    } else {
      console.error("editor not found");
    }
  }
  componentWillUnmount() {
    this.container.removeEventListener("trix-initialize", this.handleChange);
    this.container.removeEventListener("trix-change", this.handleChange);
  }

  private handleChange(e: ChangeEvent) {
    const { textContent, innerHTML } = e.target;
    this.props.onChange && this.props.onChange(innerHTML, textContent || "");
  }

  render() {
    let props = this.props;

    var attributes: { [key: string]: string } = {
      id: `editor-${this.id}`,
      input: `input-${this.id}`,
    };

    if (props.className) {
      attributes["class"] = props.className;
    }

    if (props.autoFocus) {
      attributes["autoFocus"] = props.autoFocus.toString();
    }

    if (props.placeholder) {
      attributes["placeholder"] = props.placeholder;
    }

    if (props.toolbar) {
      attributes["toolbar"] = props.toolbar;
    }

    return (
      <div id="trix-editor-top-level" ref={(d) => (this.d = d)} style={Css.relative.add("wordBreak", "break-word").$}>
        {React.createElement("trix-editor", attributes)}
        <input type="hidden" id={`input-${this.id}`} value={this.props.value} />
      </div>
    );
  }
}
