import { useMemo } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Accordion } from "src/components";
import { CircleCheckMark } from "src/components/Icons";
import { Css, Palette, px } from "src/Css";
import {
  HomeownerSelectionStatus,
  ListSelectionDetailsFragment,
  SelectionsByLocationFragment,
} from "src/generated/graphql-types";
import { useSelectionsBySpace } from "src/hooks";
import {
  projectIdParam,
  selectionIdParam,
  selectionRoute,
  SelectionRouteProps,
  spaceIdParam,
  SpaceRouteProps,
  spacesRoute,
} from "src/routes";
import { getSpace, getSpaceSelections } from "src/utils";
import { ToolbarHeader } from "./";

export function ToolbarList() {
  const { spaceId } = useRouteMatch<SpaceRouteProps>(selectionRoute(projectIdParam, spaceIdParam))?.params ?? {};
  const selectionsBySpace = useSelectionsBySpace();

  const spaceName = getSpace(selectionsBySpace, spaceId)?.location.name.toLowerCase() || "home";

  const spaceSelections = useMemo(
    () => formattedSpaceSelections(getSpaceSelections(selectionsBySpace, spaceId)),
    [selectionsBySpace, spaceId],
  );

  const allSelections = useMemo(() => getAllSelections(selectionsBySpace), [selectionsBySpace]);

  return (
    <ToolbarListDataView
      spaceName={spaceName}
      spaceSelections={spaceId ? spaceSelections : undefined}
      allSelections={!spaceId ? allSelections : undefined}
    />
  );
}

interface SelectionsBySpace {
  spaceSelections: ListSelection[];
  spaceName?: string;
  spaceId?: string;
  expanded?: boolean; // used for story
}

export interface ToolbarListDataViewProps {
  spaceName?: string;
  spaceSelections?: ListSelection[];
  allSelections?: SelectionsBySpace[];
}

interface ListSelection {
  name: string;
  status: HomeownerSelectionStatus;
  id: string;
}

export function ToolbarListDataView({ spaceName, spaceSelections, allSelections }: ToolbarListDataViewProps) {
  return (
    <div data-testid="toolbarComponentList">
      <ToolbarHeader
        title="Product List"
        subTitle={`Here are all of the products within your ${spaceName} and which ones are finalized or not.`}
      />

      {/* Displays selections for a single space when on SelectionView */}
      {spaceSelections && (
        <div css={Css.px2.$}>
          <SelectionsList selections={spaceSelections} />
        </div>
      )}

      {/* Displays all home selections grouped by space when on SpacesView */}
      {allSelections && <SpacesList allSelections={allSelections} />}
    </div>
  );
}

function SpacesList({ allSelections }: Pick<ToolbarListDataViewProps, "allSelections">) {
  return (
    <>
      {allSelections?.map(({ spaceName, spaceSelections: selections = [], expanded, spaceId }) => {
        const title = `${spaceName} (${selections.length})`;
        return (
          <Accordion key={spaceName} className="list" {...{ title, expanded }}>
            <SelectionsList {...{ selections, spaceId }} />
          </Accordion>
        );
      })}
    </>
  );
}

interface SelectionsListProps {
  selections: ListSelection[];
  spaceId?: string;
}

function SelectionsList(props: SelectionsListProps) {
  // Using useRouteMatch vs useParams due to parent only having access to params in the design studio route.
  const {
    projectId,
    spaceId: paramSpaceId,
    selectionId,
  } = useRouteMatch<SelectionRouteProps>([
    // Prioritize matching on selectionRoute, then spaceRoute. Order matters in this case.
    selectionRoute(projectIdParam, spaceIdParam, selectionIdParam),
    spacesRoute(projectIdParam),
  ])?.params ?? {};

  const spaceId = paramSpaceId || props.spaceId || "";

  return (
    <div css={Css.bb.bBlueLight.mb2.bgTaupe.w100.$}>
      {props.selections.map(({ name, status, id }) => {
        const isCurrentSelection = id === selectionId;
        const isFinalized = status === HomeownerSelectionStatus.Finalized;
        return (
          <Link
            to={selectionRoute(projectId, spaceId, id)}
            key={id}
            css={{
              "&:hover": Css.bgTransparentGray.if(isCurrentSelection).bgWhite.$,
              ...Css.noUnderline.bt.bBlueLight.df.jcsb.aic.px2.t14
                .h(px(50))
                .if(isCurrentSelection)
                .bgWhite.if(isFinalized).gray600.$,
            }}
          >
            {name}
            {isFinalized && (
              <CircleCheckMark
                xss={Css.h(px(18)).w(px(18)).$}
                color={isCurrentSelection ? Palette.Taupe : Palette.White}
                secondaryColor={Palette.Black}
              />
            )}
          </Link>
        );
      })}
    </div>
  );
}

function getAllSelections(selectionsBySpace: SelectionsByLocationFragment[] = []): SelectionsBySpace[] {
  return selectionsBySpace.map(({ location, selections }) => {
    return {
      spaceId: location.id,
      spaceName: location.name,
      spaceSelections: formattedSpaceSelections(selections),
    };
  });
}

function formattedSpaceSelections(spaceSelections: ListSelectionDetailsFragment[]): ListSelection[] {
  return spaceSelections?.map((selection: ListSelectionDetailsFragment) => {
    return (
      {
        name: selection.projectItem.name,
        status: selection.status.code,
        id: selection.id,
      } ?? []
    );
  });
}
