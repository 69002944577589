import { useHistory, useParams } from "react-router-dom";
import { Card, PageContent, QueryResultHandler } from "src/components";
import { Css } from "src/Css";
import {
  JourneyPageProjectFragment,
  JourneyPageQuery,
  JourneyPhaseFragment,
  ProgressSummaryStatus,
  useJourneyPageQuery,
} from "src/generated/graphql-types";
import { milestoneRoute } from "src/routes";
import { ProgressBar } from "./components/ProgressBar";
import { getSchedulePhaseInfo, getStatusDisplay } from "./utils/schedulePhaseInfo";

type MilestoneListRouteProps = {
  projectId: string;
};

export function MilestoneListPage() {
  const { projectId } = useParams<MilestoneListRouteProps>();
  const result = useJourneyPageQuery({
    variables: {
      projectId,
    },
  });

  return QueryResultHandler<JourneyPageQuery>({
    result,
    render: (data) => <MilestoneListPageView homeownerProject={data.homeownerProject} projectId={projectId} />,
  });
}

type MilestoneListPageViewProps = {
  homeownerProject: JourneyPageProjectFragment;
  projectId: string;
};

export function MilestoneListPageView({ homeownerProject, projectId }: MilestoneListPageViewProps) {
  const history = useHistory();

  const schedulePhases = homeownerProject.stage.schedulePhases;
  const firstUnitNotStartedIndex = schedulePhases.findIndex(
    (sp) => sp.progress.activityProgress.status === ProgressSummaryStatus.NotStarted,
  );

  return (
    <>
      <PageContent>
        <div css={Css.mt2.$}>
          <h6 css={Css.header20.$}>Overall Construction Progress</h6>
          <div css={Css.mt4.$}>
            <ProgressBar
              title="MileStone Progress"
              completed={
                schedulePhases.filter((u) => u.progress.activityProgress.status === ProgressSummaryStatus.Complete)
                  .length
              }
              total={schedulePhases.length}
            />
          </div>
          <div css={Css.mt4.relative.$}>
            <div css={Css.df.fdc.gap2.$}>
              {schedulePhases.map((sp, i) => (
                <MilestoneRow
                  key={sp.id}
                  schedulePhase={sp}
                  firstNotStarted={i === firstUnitNotStartedIndex}
                  onClick={() => history.push(milestoneRoute(projectId, sp.id), { from: "milestoneList" })}
                />
              ))}
            </div>
            <div css={Css.absolute.top0.bottom0.left("42px").add("borderLeft", "2px dotted #000;").z0.$} />
          </div>
        </div>
      </PageContent>
    </>
  );
}

function MilestoneRow({
  schedulePhase,
  firstNotStarted,
  onClick,
}: {
  schedulePhase: JourneyPhaseFragment;
  firstNotStarted: boolean;
  onClick: () => void;
}) {
  const statusDisplay = getStatusDisplay({ schedulePhase, firstNotStarted });
  const schedulePhaseInfo = getSchedulePhaseInfo(schedulePhase.name);
  return (
    <Card xss={Css.df.px3.py2.aic.z1.bgWhitePure.$} onClick={onClick}>
      <div css={Css.wPx(40).hPx(40).br100.bgTaupe.df.aic.jcc.$}>{schedulePhaseInfo.svg}</div>
      <div css={Css.header16.fw4.ml2.mra.$}>{schedulePhaseInfo.replacementTitle || schedulePhase.name}</div>

      <div
        css={{
          ...Css.px1.py("4px").add("fontSize", "12px").fw7.lh("14px").br4.nowrap.$,
          ...statusDisplay.css,
        }}
      >
        {statusDisplay.title}
      </div>
    </Card>
  );
}
