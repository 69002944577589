import { Avatar } from "@material-ui/core";
import { CommentEditor, SanitiseHTML } from "src/components";
import { Css } from "src/Css";
import {
  Comment,
  CommentStreamVisibility,
  Maybe,
  SaveCommentInput,
  useCurrentUserQuery,
  useSaveCommentMutation,
} from "src/generated/graphql-types";
import { shortMonthWithTimeFormat } from "src/utils";

export interface CommentFeedProps {
  comments: Comment[];
  commentableId: string;
  placeholder?: string;
  className?: string;
}

interface commentSaveFunctionArgs {
  text: String;
  html: String;
  streamVisibility: CommentStreamVisibility;
  commentableId: string;
}

export type CommentSaveFunction = (data: commentSaveFunctionArgs) => Promise<void>;

export function CommentFeed({ comments, commentableId, placeholder, className }: CommentFeedProps) {
  const { data } = useCurrentUserQuery();
  const [saveCommentMutation] = useSaveCommentMutation({ refetchQueries: ["HomeownerSelections"] });
  const isCurrentUser = (authorId: Maybe<string>) => authorId === data?.currentUser?.id;

  async function handleSave(text: string, html: string, mentions: string[]) {
    const data = {
      text,
      html,
      commentableId,
      streamVisibility: CommentStreamVisibility.Homeowner,
    };
    await saveComment(data);
  }

  const saveComment: CommentSaveFunction = async ({ text, html, streamVisibility, commentableId }) => {
    await saveCommentMutation({
      variables: { input: { text, html, streamVisibility, commentableId } as SaveCommentInput },
    });
  };

  return (
    <div>
      {comments &&
        comments.map(({ author: { id: authorId, name }, html, createdAt, id }: Comment) => (
          <div
            css={Css.df.fdr.mb1.if(!isCurrentUser(authorId)).jc("end").fdrr.$}
            key={id}
            className={className}
            data-testid="commentRow"
          >
            <Avatar css={Css.f16.$}>{avatarLetters(name)}</Avatar>
            <div
              css={Css.bgLightTransparentGray.br16.p2.mr6.ml1.if(!isCurrentUser(authorId)).ml6.mr1.$}
              data-testid={`comment-${id}`}
            >
              <SanitiseHTML css={Css.f12.add("wordBreak", "break-word").$} {...{ html }} />
              <p css={Css.f10.gray600.$}>{shortMonthWithTimeFormat(createdAt)}</p>
            </div>
          </div>
        ))}
      <div css={Css.df.$}>
        <div css={Css.mr1.$}>
          <Avatar css={Css.f16.$}>{data && data.currentUser && avatarLetters(data.currentUser.name)}</Avatar>
        </div>
        <CommentEditor onSave={handleSave} placeholder={placeholder} />
      </div>
    </div>
  );
}

// Exported for testing
export function avatarLetters(name: string | undefined | null) {
  if (!name) return "";
  const names = name.split(" ");
  return names
    .map((n) => n[0].toUpperCase())
    .join("")
    .substring(0, 2);
}
