import { useEffect, useRef, useState } from "react";
import { Css } from "src/Css";
import { useTestIds } from "src/hooks/useTestIds";
import { sanitizeHtml } from "src/utils";
import "trix/dist/trix";
import "trix/dist/trix.css";
import { OutlineButton } from "./Button";
import { TrixEditor } from "./Trix";

export interface CommentEditorProps {
  /** Input string without all the formatting */
  text?: string;
  /** Fully formatted input from the trix editor */
  html?: string;
  onSave: (text: string, html: string, mentions: string[]) => Promise<void>;
  onCancel?: () => void;
  submitText?: string;
  placeholder?: string;
}

export function CommentEditor(props: CommentEditorProps) {
  const { text = "", html = "", onSave, onCancel, submitText = "Send", placeholder = "" } = props;
  const [commentEditorId, submitId] = useTestIds("commentEditor", ["submit"]);

  function extractIdsFromMentions(content: string): string[] {
    // leaving this here if we decide to use mentions in the future
    //  return availableFollowers.filter(({ name }) => content.includes(`@${name}`)).map(({ id }) => id);
    return [];
  }

  const [commentHtml, setCommentHtml] = useState<string>(html); // default value from Trix html
  const [commentText, setCommentText] = useState<string>(text);
  const [mentions, setMentions] = useState<string[]>(extractIdsFromMentions(text));
  const [isDirty, setIsDirty] = useState<boolean>(false);

  // trix + react-trix do not appear to have any typings, so roll with editor: any for now.
  const trixEditor = useRef<any>(null);

  // We don't want to allow file attachment for now:  https://github.com/basecamp/trix#storing-attached-files
  useEffect(() => {
    const preventDefault = (e: any) => e.preventDefault();
    window.addEventListener("trix-file-accept", preventDefault);
    return () => {
      window.removeEventListener("trix-file-accept", preventDefault);
    };
  }, []);

  function onEditorReady(editor: any) {
    trixEditor.current = editor;
    editor.insertHTML(commentHtml);
  }

  function onEditorChange(newHtml: string, newText: string) {
    setCommentHtml(newHtml);
    setCommentText(newText);
    setMentions(extractIdsFromMentions(newText));
    const textDirty = newText !== text;
    const htmlDirty = newHtml !== html;
    setIsDirty(textDirty || htmlDirty);
  }

  return (
    <div css={{ ...Css.fg1.$, ...trixCssOverrides }} {...commentEditorId}>
      <div css={Css.mb2.$}>
        <TrixEditor
          mergeTags={[]}
          onChange={onEditorChange}
          onEditorReady={onEditorReady}
          placeholder={placeholder}
          css={Css.bgLightTransparentGray.bn.br16.pt("12px").pb("11px").px2.f12.mh("auto").$}
        />
      </div>
      <div css={Css.df.jcfe.$}>
        <OutlineButton
          css={Css.w("101px").bw2.bBlack.px5.$}
          disabled={!isDirty}
          onClick={async () => {
            const sanitized = sanitizeHtml(commentHtml);
            await onSave(commentText, sanitized, mentions);
            trixEditor.current?.loadHTML("");
          }}
          {...submitId}
        >
          {submitText}
        </OutlineButton>
        {onCancel && <OutlineButton onClick={onCancel}>Cancel</OutlineButton>}
      </div>
    </div>
  );
}

const trixCssOverrides = {
  // We don't support file attachment yet, so hide that control for now.
  "& .trix-button-group--file-tools": {
    display: "none",
  },
  ".trix-button--icon-increase-nesting-level, .trix-button--icon-decrease-nesting-level": {
    display: "none",
  },
  "trix-toolbar": {
    display: "none",
  },
};
