import { capitalize } from "lodash";
import { Icon, Tooltip } from "src/components";
import { Css } from "src/Css";
import {
  HomeownerSelectionOptionPricingType,
  Maybe,
  OptionDetailsFragment,
  SelectionPricingMode,
  UnitOfMeasure,
} from "src/generated/graphql-types";
import { centsToDollars } from "src/utils";

export interface PriceDetailsProps {
  msrpInCents?: number | null;
  priceDeltaInCents?: OptionDetailsFragment["priceDeltaInCents"];
  pricingType: HomeownerSelectionOptionPricingType;
  selectionPricingMode: SelectionPricingMode;
  unitOfMeasure: Pick<UnitOfMeasure, "name"> | null;
  unitPriceInCents?: number | null;
}

export function PriceDetails(props: PriceDetailsProps) {
  const { msrpInCents, priceDeltaInCents, pricingType, selectionPricingMode, unitOfMeasure, unitPriceInCents } = props;

  function priceDisplay() {
    if (selectionPricingMode === SelectionPricingMode.None) return null;

    if (selectionPricingMode === SelectionPricingMode.PriceDelta) {
      if (pricingType === HomeownerSelectionOptionPricingType.None) return null;

      if (pricingType === HomeownerSelectionOptionPricingType.Included) return capitalize(pricingType);

      return displayPriceDelta(pricingType, priceDeltaInCents);
    }

    if (selectionPricingMode === SelectionPricingMode.MsrpOnly) {
      if (msrpInCents) {
        return msrpOnly(msrpInCents);
      } else {
        return pricingRequested();
      }
    }

    if (selectionPricingMode === SelectionPricingMode.HbPriceOnly) {
      if (unitPriceInCents) {
        return unitPriceOnly(unitPriceInCents);
      } else {
        return pricingRequested();
      }
    }

    // else if selectionPricingMode === MSRP and HB Pricing:
    const unitIsEach = unitOfMeasure?.name?.toLowerCase() === "each";
    const unitPriceBeatsMsrp = (unitPriceInCents || 0) < (msrpInCents || 0);

    if (!unitPriceInCents) {
      return unitIsEach && msrpInCents ? msrpOnly(msrpInCents) : pricingRequested();
    } else {
      if (unitIsEach) {
        return msrpInCents && unitPriceBeatsMsrp
          ? unitPriceAndMSRP(unitPriceInCents, msrpInCents)
          : unitPriceOnly(unitPriceInCents);
      } else {
        return unitOfMeasure?.name ? unitPriceAndUnit(unitPriceInCents, unitOfMeasure.name) : pricingRequested();
      }
    }
  }

  return (
    <div css={Css.t14.mt1.$} data-testid="priceDetails">
      {priceDisplay()}
    </div>
  );
}

function displayPriceDelta(pricingType: HomeownerSelectionOptionPricingType, priceDeltaInCents?: Maybe<number>) {
  if (priceDeltaInCents) {
    return (
      <div>
        {/* `centsToDollars` adds a "-" sign if the value is negative, which would be the case in a downgrade */}
        {pricingType === HomeownerSelectionOptionPricingType.Upgrade && `${capitalize(pricingType)} +`}
        {centsToDollars(priceDeltaInCents, decimalPlaces(priceDeltaInCents))}
      </div>
    );
  }

  // if !priceDelta
  if (pricingType === HomeownerSelectionOptionPricingType.Downgrade) return null;

  if (pricingType === HomeownerSelectionOptionPricingType.Upgrade) {
    return capitalize(pricingType);
  }
}

function msrpOnly(msrpInCents: number) {
  return <div css={Css.t14.black.$}>MSRP {centsToDollars(msrpInCents, decimalPlaces(msrpInCents))}</div>;
}

function pricingRequested() {
  return (
    <div css={Css.df.aic.$}>
      <div css={Css.t14.black.$}>Pricing requested</div>
      <Tooltip title="We're tracking down the best price for this product" placement="bottom">
        <div css={Css.ml1.t10.$}>
          <Icon icon="info" />
        </div>
      </Tooltip>
    </div>
  );
}

function unitPriceOnly(unitPrice: number) {
  return <div>{centsToDollars(unitPrice, decimalPlaces(unitPrice))}</div>;
}

function unitPriceAndUnit(priceInCents: number, unit: string) {
  return (
    <div>
      {centsToDollars(priceInCents, decimalPlaces(priceInCents))}/ {unit}
    </div>
  );
}

function unitPriceAndMSRP(priceInCents: number, msrpInCents: number) {
  return (
    <div css={Css.df.aic.$}>
      <div css={Css.t14.black.$} data-testid="unitPrice">
        {centsToDollars(priceInCents, decimalPlaces(priceInCents))}
      </div>
      <div css={Css.t10.gray600.strike.ml1.$} data-testid="msrp">
        MSRP {centsToDollars(msrpInCents, decimalPlaces(priceInCents))}
      </div>
    </div>
  );
}

function decimalPlaces(price: number) {
  // show decimals if the absolute value of the number is < $20.00
  return Math.abs(price) < 20_00 ? 2 : 0;
}
