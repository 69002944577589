import { Breakpoints, Css } from "src/Css";
import { compareDates, numericMonthDateFormat } from "src/utils";
import { HomeownerDocument } from "src/views/Documents";
import { ListCard, TileCard } from "../DocumentsCards";
import { NoDocumentsIllustration } from "./NoDocumentsIllustration";

export interface DocumentResultsProps {
  documents: HomeownerDocument[];
  onModalOpen: (contract: HomeownerDocument) => void;
  tileView?: boolean;
  sortOrder: "ASC" | "DESC";
  filtersOpen: boolean;
}

/** Document result container showing total documents and sorting capabilities */
export function DocumentResults(props: DocumentResultsProps) {
  const { documents, onModalOpen, tileView = true, sortOrder, filtersOpen } = props;

  const sortedDocuments = documents.sort((a, b) => {
    const aDate = a.executionDate || a.createdAt;
    const bDate = b.executionDate || b.createdAt;
    if (sortOrder === "ASC") {
      if (!(aDate && bDate)) return a.displayName.localeCompare(b.displayName);
      return compareDates(aDate, bDate);
    }

    if (!(aDate && bDate)) return b.displayName.localeCompare(a.displayName);
    return compareDates(bDate, aDate);
  });

  return tileView ? (
    sortedDocuments.length ? (
      <div
        css={{
          ...Css.dg.gap2.gtc("repeat(3, 1fr)").if(filtersOpen).gtc("repeat(2, 1fr)").$,
          ...{
            [Breakpoints.sm]: Css.gtc("repeat(2, 1fr)").$,
            [Breakpoints.xs]: Css.gtc("repeat(1, 1fr)").$,
          },
        }}
      >
        {sortedDocuments.map((d, i) => (
          <TileCard
            createdAt={numericMonthDateFormat(d.createdAt, true)}
            asset={d.asset}
            name={d.displayName}
            onClick={() => onModalOpen(d)}
            key={"tile " + i + ":" + d.displayName}
          />
        ))}
      </div>
    ) : (
      <NoDocuments />
    )
  ) : (
    <div css={Css.fg1.df.gap2.fdc.$}>
      {sortedDocuments.length ? (
        sortedDocuments.map((d, i) => (
          <ListCard
            createdAt={numericMonthDateFormat(d.createdAt, true)}
            asset={d.asset}
            name={d.displayName}
            onClick={() => onModalOpen(d)}
            key={"list " + i + ":" + d.displayName}
            stackLabels={filtersOpen}
          />
        ))
      ) : (
        <NoDocuments />
      )}
    </div>
  );
}

function NoDocuments() {
  return (
    <div css={Css.df.fdc.aic.w100.mt7.$}>
      {NoDocumentsIllustration}
      <p css={Css.tc.t16.gray600.mt3.$}>
        Documents are on the way!
        <br />
        Stay tuned.
      </p>
    </div>
  );
}
