import { Redirect, useHistory, useParams } from "react-router-dom";
import {
  PageContent,
  PageTitle,
  PrimaryButton,
  QueryResultHandler,
  SwitcherAppBar,
  SwitcherOptionProps,
} from "src/components";
import { Icon } from "src/components/Icons";
import { Css, px } from "src/Css";
import {
  ContractType,
  HomeownerFinancialsQuery,
  HomeownerProjectFinancesFragment,
  HomeownerProjectFinanceStageFragment,
  ProjectFeature,
  ProjectStageStatus,
  SearchBy,
  Stage,
  useHomeownerFinancialsQuery,
} from "src/generated/graphql-types";
import { financesDetailsRoute, financesRoute, FinancesRouteProps, overviewRoute } from "src/routes";
import { centsToDollars, HomeownerStages, shortMonthDateFormat } from "src/utils";
import { FinanceSummaryCard, FinanceSummaryTable } from "./components";
import { FinancesDonut, formatDonutData } from "./FinancesDonut";

export function Finances() {
  const { projectId, selectedStage } = useParams<FinancesRouteProps>();
  const result = useHomeownerFinancialsQuery({
    variables: { searchBy: SearchBy.Project, projectId },
  });

  return QueryResultHandler<HomeownerFinancialsQuery>({
    result,
    render: (data) => <FinancesDataView homeownerProject={data.homeownerProject} {...{ projectId, selectedStage }} />,
  });
}

export interface FinancesDataViewProps {
  homeownerProject: HomeownerProjectFinancesFragment;
  selectedStage: HomeownerStages | undefined;
  projectId: string;
}

interface SwitcherOption {
  stageParam: HomeownerStages;
  code: Stage;
  title: string;
  icon: React.ReactElement;
}

export const supportedSwitcherOptions: SwitcherOption[] = [
  // We are not supporting planning at this time, but we may in the future
  // {
  //   stageParam: "planning",
  //   code: Stage.PreConPlanning,
  //   title: `planning summary`,
  //   icon: <Icon icon="luggage" />, // TODO: need a planning icon
  // },
  {
    stageParam: HomeownerStages[Stage.PreConExecution],
    code: Stage.PreConExecution,
    title: `${HomeownerStages[Stage.PreConExecution]} summary`,
    icon: <Icon icon="style" />,
  },
  {
    stageParam: HomeownerStages[Stage.Construction],
    code: Stage.Construction,
    title: `${HomeownerStages[Stage.Construction]} summary`,
    icon: <Icon icon="home" />,
  },
];

export function getCurrentSwitcherOption(
  switcherOptions: SwitcherOption[],
  stages: HomeownerProjectFinanceStageFragment[],
  selectedStage: HomeownerStages | undefined,
) {
  const projectStageCodes = stages.filter((s) => s.status.code === ProjectStageStatus.Active).map((s) => s.stage.code);

  return (
    // returns the option that matches the stage from url
    switcherOptions.find((option) => option.stageParam === selectedStage) ||
    // or the project's active stage
    switcherOptions.find((option) => projectStageCodes.includes(option.code)) ||
    // or the first option
    switcherOptions[0]
  );
}

export function FinancesDataView({ homeownerProject, selectedStage, projectId }: FinancesDataViewProps) {
  const history = useHistory();
  let { stages } = homeownerProject;

  // filter out pre-con if feature flag is on to hide it from financials
  if (homeownerProject?.features?.includes(ProjectFeature.HopHidePreconFinancials)) {
    stages = (stages || []).filter((s) => s.stage?.code !== Stage.PreConExecution);
  }

  const switcherOptions = getSwitcherOptions(stages);
  const currentSwitcherOption = getCurrentSwitcherOption(switcherOptions, stages, selectedStage);
  const currentStage = stages?.find(({ stage }) => stage?.code === currentSwitcherOption?.code);
  const hasFixedPriceContract = currentStage?.homeownerContracts.some((hc) => hc.contractType === ContractType.Fixed);

  if (!currentStage) return <Redirect to={overviewRoute(projectId)} />;

  const { amountInvoicedInCents, amountPaidInCents, balanceInCents, financialsLastUpdate, homeownerPriceInCents } =
    currentStage.homeownerFinancials;

  const formattedLastUpdate = shortMonthDateFormat(financialsLastUpdate, true);

  const donutData = formatDonutData(currentStage.invoices);

  // returns switcher options that are supported
  function getSwitcherOptions(stages: HomeownerProjectFinanceStageFragment[]): SwitcherOption[] {
    return supportedSwitcherOptions.filter((stage) => stages.map((s) => s.stage?.code)?.includes(stage?.code));
  }

  function handleSwitcherClick(option: SwitcherOptionProps) {
    const newOption = supportedSwitcherOptions.find((o) => o.title === option.title);
    history.push(financesRoute(projectId, newOption!.stageParam));
  }

  return (
    <PageContent>
      <div data-testid="financesPage">
        <PageTitle title="Finances" />
        <SwitcherAppBar
          options={switcherOptions.map(({ title, icon }) => ({ title, icon }))}
          selected={{ title: currentSwitcherOption.title, icon: currentSwitcherOption.icon }}
          onClick={handleSwitcherClick}
        />
        <FinanceSummaryCard {...{ amountPaidInCents, homeownerPriceInCents, stage: currentSwitcherOption.code }} />
        <div css={Css.py5.relative.$}>
          <FinancesDonut data={donutData} {...{ formattedLastUpdate, amountInvoicedInCents }} />
          <div css={Css.absolute.bottom(px(50)).$}>
            <div css={Css.t18.$}>{centsToDollars(balanceInCents, 2)}</div>
            <div css={Css.t12up.gray600.$}>Due now</div>
          </div>
        </div>
        <div css={Css.df.jcsb.mb1.$}>
          <h2 css={Css.t18.m1.$}>{`Invoices (${currentStage?.invoices.length})`}</h2>
          {!hasFixedPriceContract && (
            <PrimaryButton href={financesDetailsRoute(projectId, currentSwitcherOption.stageParam)}>
              View Details
            </PrimaryButton>
          )}
        </div>

        <FinanceSummaryTable invoices={currentStage.invoices} />
      </div>
    </PageContent>
  );
}
