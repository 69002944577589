import { useMediaQuery } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { QueryResultHandler } from "src/components";
import { Icon } from "src/components/Icons";
import { Breakpoints, Css } from "src/Css";
import { HomeownerInboxItemsFragment, OverviewPageQuery, useOverviewPageQuery } from "src/generated/graphql-types";
import { ProjectRouteProps } from "src/routes";
import { compareDates } from "src/utils";
import { NotificationMenu } from "./NotificationMenu";

type NotificationIconButtonProps = {
  openSideBar?: () => void;
};

export function NotificationIconButton(props: NotificationIconButtonProps) {
  const { openSideBar } = props;
  const { projectId } = useParams<ProjectRouteProps>();
  const result = useOverviewPageQuery({
    variables: { projectId },
  });
  return QueryResultHandler<OverviewPageQuery>({
    result: result,
    render: (data) => <NotificationDataView {...{ data, openSideBar }} />,
    loading: NotificationLoadingComponent,
    error: NotificationLoadingComponent,
  });
}

type NotificationDataViewProps = {
  data: OverviewPageQuery;
  openSideBar?: () => void;
};

/**
 * Displays Alert icon (bell) with a number to indicate unread project updates
 * Clicking on bell opens a menu that displays a list of project updates
 */
export const NotificationDataView = (props: NotificationDataViewProps) => {
  const { data, openSideBar } = props;
  const homeownerInbox = data?.homeownerProject?.homeownerInbox;
  const [currentAlertCount, setCurrentAlertCount] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [allItems, setAllItems] = useState<HomeownerInboxItemsFragment[] | undefined>(undefined);
  const [displayedItems, setDisplayedItems] = useState<HomeownerInboxItemsFragment[] | undefined>(undefined);
  const isMobile = useMediaQuery(Breakpoints.xs);

  const unreadCount = homeownerInbox?.unreadCount;
  const items = homeownerInbox?.items;

  const buttonRef = useRef(null);
  const anchorEl = buttonRef.current;

  useEffect(() => {
    if (unreadCount !== undefined) {
      setCurrentAlertCount(unreadCount);
    }
  }, [unreadCount]);

  useEffect(() => {
    if (items?.length > 0) {
      const sortedItems = [...items].sort((a, b) => compareDates(b.projectUpdate.eventTime, a.projectUpdate.eventTime));
      const shortItems = sortedItems.slice(0, 5);
      setAllItems(sortedItems);
      setDisplayedItems(shortItems);
    }
  }, [items]);

  return (
    <>
      <button
        aria-controls="notification-menu"
        aria-haspopup="true"
        css={{
          ...Css.bgHollow.bn.cursorPointer.p0.$,
          "&:focus, &:hover": Css.bn.outline0.$,
        }}
        onClick={openSideBar ? openSideBar : () => setIsMenuOpen(true)}
        ref={buttonRef}
      >
        <div css={Css.bgOffWhite.hPx(isMobile ? 24 : 32).wPx(isMobile ? 24 : 32).br100.df.jcc.aic.$}>
          <Icon size={isMobile ? "18" : "24"} icon={currentAlertCount > 0 ? "bellAlert" : "bell"} />
        </div>
      </button>
      <NotificationMenu {...{ anchorEl, setIsMenuOpen, isMenuOpen, allItems, displayedItems, setDisplayedItems }} />
    </>
  );
};

function NotificationLoadingComponent() {
  return (
    <div css={Css.mr4.$}>
      <Icon size="24" icon={"bell"} />
    </div>
  );
}
