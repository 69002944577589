import { Css } from "src/Css";

interface ToolbarHeaderProps {
  title: string;
  subTitle: string;
}

export function ToolbarHeader({ title, subTitle }: ToolbarHeaderProps) {
  return (
    <header css={Css.pb2.pl2.pr4.pt1.$}>
      <h1 css={Css.t14.$}>{title}</h1>
      <small css={Css.t12.gray600.$}>{subTitle}</small>
    </header>
  );
}
