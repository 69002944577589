import { Tooltip } from "src/components";
import { Css, Margin, Only, Padding, Palette, Xss } from "src/Css";

export type TabsXss = Xss<Margin | Padding | "backgroundColor" | "borderColor">;

export interface TabsProps<X> {
  tabs: TabProps[];
  /** When no match, will select the first tab */
  selectedTab: string;
  onClick?: (selectedTab: string) => void;
  /** Enable tab elements to be spaced evenly */
  spaceEvenly?: boolean;
  xss?: X;
}

export function Tabs<X extends Only<TabsXss, X>>(props: TabsProps<X>) {
  let { onClick, tabs, selectedTab, spaceEvenly = false, xss } = props;
  if (!tabs.length) return null;

  // Handle when there is no matching selectedTab
  if (!tabs.find((tab) => tab.name === selectedTab)) selectedTab = tabs[0].name;

  // Reduces onClick events which do not change the tab state
  function handleOnClick(currentSelectedTab: string) {
    if (currentSelectedTab !== selectedTab) onClick && onClick(currentSelectedTab);
  }

  return (
    <div
      css={{
        ...Css.df.bTaupe.bb.if(spaceEvenly).jcsa.$,
        ...(!spaceEvenly && { "> *": Css.mr3.$, "> *:last-child": Css.mr0.$ }),
        ...xss,
      }}
      role="tablist"
    >
      {tabs.map((tabProps) => {
        const { name: tabName } = tabProps;
        return (
          /* TODO: A future enhancement would be to have a parent width listener which reduces tab spacing when container shrinks since the mobile breakpoint does not help when in a modal, for example.*/
          <div key={tabName}>
            <Tab isSelected={tabName === selectedTab} onClick={handleOnClick} {...tabProps} />
          </div>
        );
      })}
    </div>
  );
}

export interface TabProps {
  name: string;
  /** Optional component to render as the tab, default to name otherwise.
   * The component is expected to accept a color prop to handle selection states
   */
  component?: React.FC<{ color: string }>;
  tooltipContent?: string;
}

/** Hidden TabItemProps that only the parent Tab component has access to */
interface TabComponentProps extends TabProps {
  isSelected: boolean;
  /** onClick handler passed by parent */
  onClick: (selectedTab: string) => void;
}

/** Single tab element component which is housed in the Tabs component
 * TODO: For accessibility, it would be nice to design focus states when using
 * the keyboard to navigate.
 */
function Tab(props: TabComponentProps) {
  const { name, component: Component, isSelected, tooltipContent, onClick } = props;
  const color = isSelected ? Palette.Black : Palette.Gray600;

  const tabContent: React.ReactElement = !Component ? (
    <div css={Css.t14.color(color).ttc.$}>{name}</div>
  ) : (
    <Component color={color} />
  );

  const tabContentWrapperStyles = {
    ...Css.pb1.outline0.if(!Component).cursorPointer.else.if(isSelected).bb.bw2.bBlack.$,
    "*": {
      transition: "color 0.3s, border-color 0.3s, background-color 0.3s, fill 0.3s, stroke 0.3s",
    },
  };

  const tabWrapperProps = {
    css: tabContentWrapperStyles,
    onClick: () => onClick(name),
    role: "tab",
    "aria-selected": isSelected,
    "aria-label": name,
    // Recommended https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/Tab_Role
    tabIndex: isSelected ? 0 : -1,
    // Denotes which tabpanel id it controls
    "aria-controls": name,
    id: name,
  };

  return tooltipContent ? (
    <Tooltip {...tabWrapperProps} title={<div>{tooltipContent}</div>} data-testid="tabTooltip">
      <div>{tabContent}</div>
    </Tooltip>
  ) : (
    <div {...tabWrapperProps}>{tabContent}</div>
  );
}
