import { Card, Icon, IconButton, Radio, TextButton } from "src/components";
import { Checkbox } from "src/components/Checkbox";
import { Css, px } from "src/Css";
import { DocumentType } from "src/generated/graphql-types";
import { groupBy, mapValues } from "src/utils";
import { HomeownerDocument } from "src/views/Documents/Documents";

export interface DocumentFilterFlyInProps {
  documents: HomeownerDocument[];
  onFilterChange: (typesFilter: DocumentType[]) => void;
  /** Document types that are checked */
  checkedTypes?: DocumentType[];
  sortOrder: "DESC" | "ASC";
  setSortOrder: (sortOrder: "DESC" | "ASC") => void;
  onClose: () => void;
}

interface DocumentTypeDetailCount {
  // The number of times this type appears in the HomeownerDocument set
  count: number;
  code: DocumentType;
  name: string;
}

export function DocumentFilterFlyIn({
  documents,
  onFilterChange,
  checkedTypes = [],
  sortOrder,
  setSortOrder,
  onClose,
}: DocumentFilterFlyInProps) {
  const documentTypes = createDocumentTypes(documents);

  /** Add type, remove type or reset the typesFilter and calls onFilterChange */
  function toggleTypesFilter(type?: DocumentType) {
    // If type is undefined, then reset typesFilter
    if (!type) {
      const newTypesFilter: DocumentType[] = [];
      onFilterChange(newTypesFilter);
    }
    // If type is included in typesFilter, then remove it
    else if (checkedTypes?.includes(type)) {
      const newTypesFilter = checkedTypes.filter((prevType) => prevType !== type);
      onFilterChange(newTypesFilter);
    }
    // If type is not included in typesFilter, then add it
    else {
      const newTypesFilter = [...checkedTypes, type];
      onFilterChange(newTypesFilter);
    }
  }

  return (
    <Card
      xss={{
        ...Css.mw(px(251)).maxw("33%").p2.hfc.$,
        ...Css.ifXs.absolute.top0.bottom0.left0.right0.z9999.bgWhitePure.maxw("unset").h("unset").p4.$,
      }}
      isClickable={false}
    >
      <div css={Css.dn.header24.mt6.mb3.ifXs.db.$}>Filters</div>
      <div css={Css.dn.absolute.top3.right3.ifXs.db.$}>
        <IconButton onClick={onClose}>
          <Icon icon="close" />
        </IconButton>
      </div>
      <div css={Css.df.aic.$}>
        <div css={Css.mra.t16.fw5.lh("24px").add("letterSpacing", "0.32px").$}>{documents.length} Results</div>
        {/* TODO: replace with tertiary button */}
        <TextButton
          onClick={() => toggleTypesFilter()}
          xss={Css.important.br4.ba.bBlack.px1.$}
          data-testid="clearFiltersButton"
        >
          <div css={Css.t14.fw4.lh("20px").gray800.ttn.add("letterSpacing", "0.1px").$}>Clear Filters</div>
        </TextButton>
      </div>
      <div css={Css.bb.bTaupe.pt2.$} />
      <div css={Css.pt3.pb1.px2.ifXs.px0.$}>
        <div css={Css.t12.gray600.mb1.$}>SORT BY</div>
        <div css={Css.df.gap2.fdc.$}>
          <div css={Css.df.aic.gap1.$}>
            <Radio selected={sortOrder === "DESC"} onClick={() => setSortOrder("DESC")} />
            <div css={Css.t14.gray700.fw3.lh("24px").if(sortOrder === "DESC").gray900.fw5.$}>Newest first</div>
          </div>
          <div css={Css.df.aic.gap1.$}>
            <Radio selected={sortOrder === "ASC"} onClick={() => setSortOrder("ASC")} />
            <div css={Css.t14.gray700.fw3.lh("24px").if(sortOrder === "ASC").gray900.fw5.$}>Oldest first</div>
          </div>
        </div>
        <div css={Css.t12.gray600.mb1.mt4.$}>TYPES</div>
        {(documentTypes.length ? documentTypes : defaultDocumentTypes).map(({ code, name, count }) => (
          <div css={Css.mb2.$}>
            <Checkbox<DocumentType>
              value={code}
              checked={checkedTypes.includes(code)}
              onChange={toggleTypesFilter}
              key={code}
            >
              <div
                css={Css.t14.gray700.fw3.lh("24px").if(checkedTypes.includes(code)).gray900.fw5.$}
              >{`${name} (${count})`}</div>
            </Checkbox>
          </div>
        ))}
      </div>
    </Card>
  );
}

function createDocumentTypes(documents: HomeownerDocument[]): DocumentTypeDetailCount[] {
  const typeCounts: Record<
    string,
    {
      count: number;
      name: string;
      code: DocumentType;
    }
  > = mapValues(
    groupBy(
      documents,
      // Group by
      (d) => d.documentType.code,
      // Using these values
      (d) => d.documentType,
    ),
    // Merge grouped document types and retrieve its count
    (a) => ({
      ...a[0],
      count: a.length,
    }),
  );

  // add any missing default filter types
  defaultDocumentTypes.forEach((nd) => {
    if (!typeCounts[nd.code]) {
      typeCounts[nd.code] = nd;
    }
  });

  return Object.values(typeCounts).sort((a, b) => a.name.localeCompare(b.name));
}

const defaultDocumentTypes = [
  {
    code: DocumentType.PreconAgreement,
    name: "Pre-Con Agreement",
    count: 0,
  },
  {
    code: DocumentType.PreconAgreementChangeOrder,
    name: "Pre-Con Agreement Change Order",
    count: 0,
  },
  {
    code: DocumentType.Invoice,
    name: "Invoice",
    count: 0,
  },
  {
    code: DocumentType.Photo,
    name: "Photo",
    count: 0,
  },
];
