import { ReactNode } from "react";
import { Css } from "src/Css";
import { Toolbar } from "./Toolbar";

/** Selections view wrapper which handles page names and back button routing */
export function SelectionsLayout({ children }: { children: ReactNode }) {
  return (
    <div css={Css.overflowHidden.df.h("calc(100vh - 64px)").ifXs.h("calc(100vh - 72px)").$}>
      {children}
      <Toolbar />
    </div>
  );
}
