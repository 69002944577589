import { Donut, Tooltip } from "src/components";
import { Icon } from "src/components/Icons";
import { Css, Palette, px } from "src/Css";
import { ProjectInvoiceFragment } from "src/generated/graphql-types";
import { centsToDollars } from "src/utils";

interface DonutInnerContentProps {
  formattedLastUpdate: string;
  amountInvoicedInCents: number;
}

interface FinancesDonutProps {
  data: any;
  formattedLastUpdate: string;
  amountInvoicedInCents: number;
}

const donutOuterDiameter = 260;
const donutInnerDiameter = 220;

export function FinancesDonut({ data, formattedLastUpdate, amountInvoicedInCents }: FinancesDonutProps) {
  if (data.length === 0) {
    return <EmptyDonut {...{ formattedLastUpdate, amountInvoicedInCents }} />;
  }

  return (
    <Donut
      data={data}
      innerContent={<DonutInnerContent {...{ formattedLastUpdate, amountInvoicedInCents }} />}
      size={donutOuterDiameter}
    />
  );
}

function EmptyDonut(props: DonutInnerContentProps) {
  return (
    <div css={Css.df.jcc.$} data-testid="emptyDonut">
      <div
        css={
          Css.h(px(donutOuterDiameter)).w(px(donutOuterDiameter)).ba.bGray400.br100.df.jcc.aic.bgLightTransparentGray.$
        }
      >
        <div css={Css.h(px(donutInnerDiameter)).w(px(donutInnerDiameter)).ba.bGray400.br100.bgWhite.$}>
          <div css={Css.absolute.top0.right0.bottom0.left0.df.fdc.jcc.tc.$}>
            <DonutInnerContent {...props} />
          </div>
        </div>
      </div>
    </div>
  );
}

export function DonutInnerContent({ formattedLastUpdate, amountInvoicedInCents }: DonutInnerContentProps) {
  return (
    <div css={Css.df.jcc.$}>
      <div css={Css.w(px(donutInnerDiameter)).relative.top1.$}>
        {/* Shrink text if amount is greater than $1M to fit within donut */}
        <div css={Css.t32.pb1.if(amountInvoicedInCents >= 1e8).f24.if(amountInvoicedInCents === 0).gray600.$}>
          {centsToDollars(amountInvoicedInCents, 2)}
        </div>
        <div css={Css.df.jcc.$}>
          <div css={Css.t14up.gray600.pr1.pb1.$}>Total Invoiced</div>
          <Tooltip
            title="Total Invoiced includes paid and unpaid invoice amounts."
            placement="right"
            css={Css.add("pointerEvents", "auto").$}
          >
            <div css={Css.h(px(16)).w0.$}>
              <Icon icon="circleQuestionMark" xss={Css.cursorPointer.$} tabIndex={0} color={Palette.Gray600} />
            </div>
          </Tooltip>
        </div>
        <div css={Css.t14.gray600.$}>Updated</div>
        <div css={Css.t14.gray600.$}>{formattedLastUpdate}</div>
      </div>
    </div>
  );
}

export function formatDonutData(invoices: ProjectInvoiceFragment[]) {
  // creates object where keys are cost code names and values are sum of total amount invoiced
  const lineItemsByCostCode = invoices.flatMap((i) => i.lineItemsByCostCode);
  const drawLineItems = invoices.flatMap((i) => i.drawLineItems);

  const invoicedLineItemsByCostCode = lineItemsByCostCode.reduce((acc: Record<string, number>, li) => {
    const name = li.costCode.name;
    acc[name] = (acc[name] ?? 0) + li.totalAmountInCents;
    return acc;
  }, {});
  const invoicedDrawLineItems = drawLineItems.reduce((acc: Record<string, number>, li) => {
    const name = li.draw.name;
    acc[name] = (acc[name] ?? 0) + li.draw.amountInCents;
    return acc;
  }, {});

  const allInvoicedAmounts = { ...invoicedLineItemsByCostCode, ...invoicedDrawLineItems };

  return Object.keys(allInvoicedAmounts).map((li, i) => ({
    id: i,
    label: li,
    value: allInvoicedAmounts[li],
  }));
}
