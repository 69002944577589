import { Link, useLocation, useParams } from "react-router-dom";
import { Css, Palette } from "src/Css";
import { useCurrentUser } from "src/hooks";
import { profileRoute, ProjectRouteProps } from "src/routes";
import { HomeownerPalette } from "src/theme";
import { Icon } from "./index";

/** Displays a profile icon that links to the profile page. Hidden on mobile */
export function ProfileLink() {
  const { avatarUrl, name } = useCurrentUser();
  const { projectId } = useParams<ProjectRouteProps>();
  const { pathname } = useLocation();
  const isOnProfilePage = pathname === profileRoute(projectId);

  return (
    <Link to={profileRoute(projectId)} css={Css.hPx(32).wPx(32).ifXs.hPx(24).wPx(24).$}>
      {hasAvatarUrl(avatarUrl) ? (
        <img referrerPolicy="no-referrer" css={Css.h100.w100.brPill.$} src={avatarUrl} alt={name} />
      ) : (
        <Icon icon="account" xss={Css.h100.w100.$} color={isOnProfilePage ? HomeownerPalette.Black : Palette.Gray600} />
      )}
    </Link>
  );
}

// TODO: graphql-service always returns an avatar URL when it should return
// string|undefined. It should return undefined for homeowners (until/when they have a
// custom profile image set
// https://github.com/homebound-team/graphql-service/blob/main/src/entities/User.ts#L73
function hasAvatarUrl(avatarUrl: string | undefined) {
  return avatarUrl && avatarUrl !== "/homeowner-icon.png";
}
