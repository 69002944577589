import { Link, Redirect, useParams } from "react-router-dom";
import { Checkmark, Icon, PageContent, PageTitle, QueryResultHandler } from "src/components";
import { Css } from "src/Css";
import {
  JourneyPageProjectFragment,
  JourneyPageQuery,
  JourneyTaskFragment,
  TaskStatus,
  useJourneyPageQuery,
} from "src/generated/graphql-types";
import { journeyRoute, milestoneTaskRoute } from "src/routes";
import { HomeownerPalette } from "src/theme";
import { ProgressBar } from "./components/ProgressBar";
import { getSchedulePhaseInfo } from "./utils/schedulePhaseInfo";

type TaskRouteProps = {
  projectId: string;
  unitId: string;
};

export function MilestonePage() {
  const { projectId, unitId } = useParams<TaskRouteProps>();
  const result = useJourneyPageQuery({
    variables: {
      projectId,
    },
  });

  return QueryResultHandler<JourneyPageQuery>({
    result,
    render: (data) => (
      <MilestonePageView homeownerProject={data.homeownerProject} projectId={projectId} unitId={unitId} />
    ),
  });
}

type MilestonePageViewProps = {
  homeownerProject: JourneyPageProjectFragment;
  projectId: string;
  unitId: string;
};

export function MilestonePageView({ homeownerProject, projectId, unitId }: MilestonePageViewProps) {
  const schedulePhase = homeownerProject.stage.schedulePhases.find((sp) => sp.id === unitId);
  if (!schedulePhase) {
    return <Redirect to={journeyRoute(projectId)} />;
  }

  const schedulePhaseInfo = getSchedulePhaseInfo(schedulePhase.name);
  const tasks = schedulePhase.scheduleSubPhases.flatMap((ssp) => ssp.scheduleTasks);

  return (
    <PageContent>
      <PageTitle title={schedulePhaseInfo.replacementTitle || schedulePhase.name} />

      <div css={Css.mt4.$}>
        <ProgressBar
          title="Estimated timeline and step progress"
          completed={tasks.filter((t) => t.status === TaskStatus.Complete).length}
          total={tasks.length}
          start={schedulePhase.interval?.startDate}
          end={schedulePhase.interval?.endDate}
        />
      </div>
      <div css={Css.w100.hPx(1).my4.bgGray200.$}></div>
      <div css={Css.df.fdc.gap2.$}>
        <div css={Css.header16.fw4.$}>Milestone Steps</div>
        {tasks.map((t) => (
          <MilestoneRow key={t.id} task={t} projectId={projectId} unitId={unitId} />
        ))}
      </div>
    </PageContent>
  );
}

function MilestoneRow({ task, projectId, unitId }: { task: JourneyTaskFragment; projectId: string; unitId: string }) {
  const taskComplete = task.status === TaskStatus.Complete;
  return (
    <div css={Css.df.aic.$}>
      <div css={Css.mr2.$}>
        <Checkmark
          complete={taskComplete}
          completionColor={HomeownerPalette.Green200}
          checkColor={taskComplete ? HomeownerPalette.Black : undefined}
        />
      </div>
      <Link to={milestoneTaskRoute(projectId, unitId, task.id)} css={Css.df.aic.textDecoration("none").$}>
        <div css={Css.f14.fw4.black.$}>{task.name}</div>
        <Icon icon="chevronRight" />
      </Link>
    </div>
  );
}
