import { Redirect, Route, Switch } from "react-router-dom";
import { PageLayout } from "src/components/Nav";
import {
  MockLayoutType,
  projectIdParam,
  selectionIdParam,
  selectionRoute,
  spaceIdParam,
  spacesRoute,
} from "src/routes";
import { RedirectComponent } from "..";
import { SelectionsLayout } from "./components";
import { SelectionView } from "./SelectionView";
import { SpacesView } from "./SpacesView";

/** Selections Page View */
export function Selections({ mockLayout }: MockLayoutType) {
  return (
    <PageLayout old>
      <SelectionsLayout>
        <Switch>
          <Route
            exact
            path={spacesRoute(projectIdParam)}
            render={() => (mockLayout ? mockLayout(<SpacesView />) : <SpacesView />)}
          />
          <Route
            exact
            path={selectionRoute(projectIdParam, spaceIdParam, selectionIdParam)}
            render={() => (mockLayout ? mockLayout(<SelectionView />) : <SelectionView />)}
          />
          <Route path={spacesRoute()} exact component={RedirectComponent} />
          <Redirect to={spacesRoute()} />
        </Switch>
      </SelectionsLayout>
    </PageLayout>
  );
}
