import { useParams } from "react-router-dom";
import { Breakpoints, Css } from "src/Css";
import { SearchBy, useHomeownerProjectsQuery } from "src/generated/graphql-types";
import { useHomeownerProjectSearchQuery } from "src/hooks";
import { ProjectRouteProps } from "src/routes";
import { NavBar } from ".";

interface PageLayoutProps {
  old?: boolean;
  children: React.ReactNode;
}

export function PageLayout({ children, old = false }: PageLayoutProps) {
  const { projectId } = useParams<ProjectRouteProps>();
  // Load all projects and current project as soon as you login successfully for caching purposes
  useHomeownerProjectsQuery();
  useHomeownerProjectSearchQuery(SearchBy.Project, projectId);

  return (
    <>
      <NavBar />
      <div css={Css.df.mh100.fdc.aifs.jcfs.ptPx(72).ifXs.pt8.$}>
        <div css={Css.w100.fg1.$}>
          <main
            css={{
              ...Css.dg.mh("calc(100vh - 72px)").ifXs.mh("calc(100vh - 64px)").$,
              ...(!old && {
                [Breakpoints.max]: Css.gtc("repeat(12, 1fr)").gap3.mxPx(120).$,
                [Breakpoints.xl]: Css.gtc("repeat(12, 1fr)").gap3.mxPx(100).$,
                [Breakpoints.lg]: Css.gtc("repeat(12, 1fr)").gap3.mxPx(80).$,
                [Breakpoints.md]: Css.gtc("repeat(12, 1fr)").gap2.mx8.$,
                [Breakpoints.sm]: Css.gtc("repeat(8, 1fr)").gap2.mx6.$,
                [Breakpoints.xs]: Css.gtc("repeat(4, 1fr)").gap2.mx3.$,
              }),
            }}
          >
            {children}
          </main>
        </div>
      </div>
    </>
  );
}
