import { GoodSansRegular } from "./GoodSansRegular";

export const emotionTheme = {
  fontFamily: GoodSansRegular.fontFamily,
  fontWeight: GoodSansRegular.fontWeight,
  fontStyle: GoodSansRegular.fontStyle,
  fontDisplay: GoodSansRegular.fontDisplay,
};

export const globalStyles = `
  * {
    font-family: ${emotionTheme.fontFamily};
    font-weight: ${emotionTheme.fontWeight};
    font-style: ${emotionTheme.fontStyle};
    font-display: ${emotionTheme.fontDisplay};
  }

  .darkScrollbar {
    /* Scrollbar styles for Firefox */
    scrollbar-width: 6px;
    scrollbar-color: #000 #fff;
  }

  /* Scrollbar styles for Chrome, Edge and Safari */
  .darkScrollbar::-webkit-scrollbar {
    width: 6px; /* For vertical scrollbar */
    height: 6px; /* For horizontal scrollbar */
    background-color: #fff;
  }

  .darkScrollbar::-webkit-scrollbar-track {
    background-color: #fff;
  }

  .darkScrollbar::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #000000;
  }

  h1, h2, h3, h4, h5, h6, p, hr {
    margin: 0;
  }

  /** Hide arrows from number inputs https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp **/
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;
