import { CircleCheckMark } from "src/components/Icons";
import { Css, Palette, px } from "src/Css";
import { centsToDollars } from "src/utils";

interface InvoiceStatusProps {
  balanceInCents: number;
  amountPaidInCents: number;
}

export function InvoiceStatusBar(props: InvoiceStatusProps) {
  const { balanceInCents, amountPaidInCents } = props;
  const isPaidInFull = balanceInCents <= 0;
  const hasPayments = amountPaidInCents > 0;

  return (
    <div css={Css.bgTaupe.df.px2.py3.fdc.w25.mw(px(200)).maxw(px(400)).fs0.$}>
      <h2 css={Css.t18.mb2.$}>Status</h2>
      {isPaidInFull
        ? paidInFullLine()
        : hasPayments
        ? partialPaymentLines(amountPaidInCents, balanceInCents)
        : invoiceLine("unpaid", balanceInCents)}
      {!isPaidInFull && (
        <>
          <h2 css={Css.t18.mt5.mb2.$}>How To Pay</h2>
          <p css={Css.t12.gray600.$}>
            Look for an email from <span css={Css.t12.black.$}>finance@homebound.com</span> with a link to this invoice
            and detailed instructions for each payment option.
          </p>
        </>
      )}
    </div>
  );
}

function paidInFullLine() {
  return (
    <div css={Css.mb2.$}>
      <div css={Css.df.mb2.$}>
        <CircleCheckMark
          xss={Css.mr1.h(px(16)).w(px(16)).$}
          color={Palette.BrightGreen}
          secondaryColor={Palette.White}
        />
        <p css={Css.f16.brightGreen.$}>Paid in Full</p>
      </div>
      <p css={Css.f14.$}>You're all set with this invoice!</p>
    </div>
  );
}

function partialPaymentLines(amountPaidInCents: number, balanceInCents: number) {
  return (
    <>
      {invoiceLine("paid", amountPaidInCents)}
      {invoiceLine("unpaid", balanceInCents)}
    </>
  );
}

function invoiceLine(status: "paid" | "unpaid", amount: number) {
  const formattedAmount: string = centsToDollars(amount, 2);
  const isPaid = status === "paid";

  return (
    <div css={Css.df.mb2.jcsb.t14.if(!isPaid).red.$}>
      <span>{isPaid ? "Paid" : "Unpaid"}</span>
      <span>{formattedAmount}</span>
    </div>
  );
}
