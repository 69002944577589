export function shortMonthDateFormat(date: Date, dateOnly = false): string {
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };

  // if is only a date output as UTC that way no timezone offset is accounted for
  if (dateOnly) {
    options.timeZone = "UTC";
  }
  return Intl.DateTimeFormat("default", options).format(new Date(date));
}

// m/d/yyyy
export function numericMonthDateFormat(date: Date, dateOnly = false): string {
  const options: Intl.DateTimeFormatOptions = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  };

  // if is only a date output as UTC that way no timezone offset is accounted for
  if (dateOnly) {
    options.timeZone = "UTC";
  }
  return Intl.DateTimeFormat("default", options).format(new Date(date));
}

export function longMonthDateFormat(date: Date, dateOnly = false): string {
  const options: Intl.DateTimeFormatOptions = {
    month: "long",
    day: "numeric",
    year: "numeric",
  };

  // if is only a date output as UTC that way no timezone offset is accounted for
  if (dateOnly) {
    options.timeZone = "UTC";
  }
  return Intl.DateTimeFormat("default", options).format(new Date(date));
}

export function shortMonthYearFormat(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    year: "numeric",
  };

  return Intl.DateTimeFormat("default", options).format(new Date(date));
}

export function shortMonthWithTimeFormat(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
  };

  const time = Intl.DateTimeFormat("default", options).format(new Date(date));
  return `${shortMonthDateFormat(date)} at ${time.toLocaleLowerCase()}`;
}

// use with sort function to sort dates
// arr.sort((a, b) => compareDates(a, b))
export function compareDates(a: Date, b: Date) {
  return new Date(a).getTime() - new Date(b).getTime();
}
