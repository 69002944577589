import { useState } from "react";
import { useParams } from "react-router-dom";
import { Icon, PageContent, QueryResultHandler } from "src/components";
import { Css } from "src/Css";
import {
  HomeownerInboxItem,
  HomeownerProjectInboxQuery,
  useHomeownerProjectInboxQuery,
} from "src/generated/graphql-types";
import { ProjectRouteProps } from "src/routes";
import { UpdatesFeed } from "../Overview/components/UpdatesFeed";

export function InboxPage() {
  const { projectId } = useParams<ProjectRouteProps>();
  const result = useHomeownerProjectInboxQuery({
    variables: { projectId },
  });
  return QueryResultHandler<HomeownerProjectInboxQuery>({
    result: result,
    render: (data) => <InboxDataView {...{ data }} />,
  });
}

type InboxDataViewProps = {
  data: HomeownerProjectInboxQuery;
};

export function InboxDataView(props: InboxDataViewProps) {
  const { data } = props;
  const { homeownerProject } = data;
  const items = homeownerProject.homeownerInbox.items;
  const [search, setSearch] = useState("");

  const bySearch = (item: HomeownerInboxItem, search: string) => {
    const lCaseSearch = search.toLowerCase();
    return (
      item.projectUpdate.document?.displayName.toLowerCase().includes(lCaseSearch) ||
      item.projectUpdate.homeownerNote?.title.toLowerCase().includes(lCaseSearch) ||
      item.projectUpdate.schedulePhase?.name.toLowerCase().includes(lCaseSearch)
    );
  };
  const filteredItems = items.filter((item) => bySearch(item as HomeownerInboxItem, search));

  return (
    <PageContent xss={Css.ptPx(60).pbPx(132).$}>
      <div css={Css.df.jcsb.$}>
        <div css={Css.header24.lh("40px").$}>Inbox</div>
        <div css={Css.wPx(180).hPx(36).bgTaupe.df.aic.br4.$}>
          <div css={Css.pl1.df.aic.mt1.$}>
            <Icon icon={"search"} />
          </div>
          <input
            css={Css.wPx(140).hPx(20).bgTaupe.br4.bsNone.$}
            type="search"
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>

      <div css={Css.shadowBasic.mt3.$}>
        <UpdatesFeed items={filteredItems} viewAll={false} />
      </div>
    </PageContent>
  );
}
