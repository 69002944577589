import { useParams } from "react-router-dom";
import { Checkmark, GridColumn, GridDataRow, GridRowStyles, GridTable, SimpleHeaderAndDataOf } from "src/components";
import { Css, increment, px } from "src/Css";
import { ProjectInvoiceFragment } from "src/generated/graphql-types";
import { invoiceRoute, ProjectRouteProps } from "src/routes";
import { HomeownerPalette } from "src/theme";
import { centsToDollars, shortMonthDateFormat } from "src/utils";

interface FinanceSummaryTableProps {
  invoices: ProjectInvoiceFragment[];
}

export function FinanceSummaryTable({ invoices }: FinanceSummaryTableProps) {
  const { projectId } = useParams<ProjectRouteProps>();
  return (
    <>
      <GridTable
        {...{ columns }}
        rows={createRows(invoices)}
        rowStyles={createRowStyles(projectId)}
        fallbackMessage="There are no invoices for this stage yet."
      />
    </>
  );
}

interface FormattedInvoice {
  id: string;
  amountInCents: string;
  dueDate: string;
  invoiceDate: string;
  invoiceNumber: string;
  isPaidInFull: boolean;
}

type Row = SimpleHeaderAndDataOf<FormattedInvoice>;
const columns: GridColumn<Row>[] = [
  {
    header: "Invoice #",
    data: ({ invoiceNumber, isPaidInFull }) => dataCell(invoiceNumber, isPaidInFull),
  },
  {
    header: "Invoice Date",
    data: ({ invoiceDate, isPaidInFull }) => dataCell(invoiceDate, isPaidInFull),
  },
  {
    header: "Due Date",
    data: ({ dueDate, isPaidInFull }) => dataCell(dueDate, isPaidInFull),
  },
  {
    header: "Amount",
    data: ({ amountInCents, isPaidInFull }) => dataCell(amountInCents, isPaidInFull),
  },
  {
    header: "Status",
    data: ({ isPaidInFull }) => (isPaidInFull ? <PaidComponent /> : <div css={Css.t14.red.$}>Unpaid</div>),
  },
];

function dataCell(data: string, isPaidInFull: boolean) {
  return <div css={Css.if(isPaidInFull).gray600.$}>{data}</div>;
}

function PaidComponent() {
  return (
    <div css={Css.df.aic.$}>
      <Checkmark completionColor={HomeownerPalette.BrightGreen} size={20} />
      <div css={Css.t14.brightGreen.pl(px(increment(1) / 2)).$}>Paid</div>
    </div>
  );
}

function createRows(invoices: ProjectInvoiceFragment[]): GridDataRow<Row>[] {
  const invoiceRows = invoices.map(({ amountInCents, balanceInCents, dueDate, id, invoiceDate, invoiceNumber }) => {
    return {
      amountInCents: centsToDollars(amountInCents, 2),
      dueDate: shortMonthDateFormat(dueDate, true),
      id,
      invoiceDate: shortMonthDateFormat(invoiceDate, true),
      invoiceNumber,
      isPaidInFull: balanceInCents <= 0,
    };
  });

  return [
    { kind: "header" as const, id: "header" },
    ...(invoiceRows?.map((v) => ({ kind: "data" as const, ...v })) || []),
  ];
}

function createRowStyles(projectId: string): GridRowStyles<Row> {
  return {
    header: { cellCss: Css.py2.t12up.gray600.bb.bGray400.bgLightTransparentGray.jcfs.$ },
    data: {
      cellCss: Css.t12.bb.bTaupe.bw2.$,
      rowLink: ({ id }) => invoiceRoute(projectId, id),
    },
  };
}
