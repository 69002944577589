import { Icon, IconButton, TextButton } from "src/components";
import { Css } from "src/Css";

export interface DocumentsHeaderProps {
  layoutView: "tile" | "list";
  onLayoutChange: (layoutView: "tile" | "list") => void;
  filtersOpen: boolean;
  onOpenFilters: (show: boolean) => void;
}

export function DocumentsHeader({ layoutView, onOpenFilters, filtersOpen, onLayoutChange }: DocumentsHeaderProps) {
  return (
    <div css={Css.df.gap2.aic.h4.w100.$}>
      {/* TYPE FILTER BUTTON */}
      <div css={Css.bgOffWhite.br3.$}>
        <TextButton
          startIcon={<Icon icon="filters" xss={Css.w3.h3.$} />}
          data-testid="openFiltersButton"
          onClick={() => {
            onOpenFilters(!filtersOpen);
          }}
          xss={Css.w100.df.aic.jcsb.bgOffWhite.add("borderRadius", "inherit").$}
        >
          <div css={Css.t12.fw5.add("letterSpacing", "-0.6px").$}>{filtersOpen ? "HIDE FILTERS" : "SHOW FILTERS"}</div>
        </TextButton>
      </div>
      {/* LAYOUT VIEW BTN */}
      <div css={Css.h4.df.bgOffWhite.ba.bTaupe.br3.maxwPx(64).aic.$}>
        <IconButton
          xss={
            Css.important.h100.w50.py0
              .px("4px")
              .br0.mh("unset")
              .mw("unset")
              .if(layoutView === "tile").bgTransparentGray.bTransparent.$
          }
          onClick={() => onLayoutChange("tile")}
        >
          <Icon icon={"fourSquare"} />
        </IconButton>
        <IconButton
          xss={
            Css.important.h100.w50.py0
              .px("4px")
              .br0.mh("unset")
              .mw("unset")
              .if(layoutView === "list").bgTransparentGray.bTransparent.$
          }
          onClick={() => onLayoutChange("list")}
        >
          <Icon icon={"list"} />
        </IconButton>
      </div>
    </div>
  );
}
