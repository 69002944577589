import { Divider, Hidden, Link } from "@material-ui/core";
import { Box } from "src/components/Box";
import { HomeboundLogo } from "src/components/Icons/HomeboundLogo";
import { Typography } from "src/components/Typography";
import { Palette } from "src/Css";
import { careEmail } from "src/email";
import { HomeownerPalette } from "src/theme/palettes";

export function SignInFooter() {
  return (
    <Box pb={4} pt={4} css={{ backgroundColor: HomeownerPalette.Blue }} display="flex">
      <Box pl={4} flexGrow={1}>
        <Box pb={2}>
          <HomeboundLogo width={126} height={21} color={HomeownerPalette.White} />
        </Box>
        <Typography size="f14" color={HomeownerPalette.White} lineHeight={20}>
          © Homebound Inc. {new Date().getFullYear()}
        </Typography>
      </Box>
      <Hidden smUp>
        <Box pt={2} pb={4}>
          <Divider />
        </Box>
      </Hidden>
      <ContactUs />
    </Box>
  );
}

function ContactUs() {
  return (
    <Box display="flex" pr={8} pl={4} alignItems="flex-start">
      <Typography size="f14" color={Palette.Gray400}>
        Need help?
      </Typography>
      <Link
        href={`mailto:${careEmail}`}
        css={{
          marginLeft: "8px",
          "&:hover": { textDecoration: "none", borderBottom: "1px solid white", paddingBottom: 2 },
        }}
      >
        <Typography size="f14" color={Palette.White}>
          Contact us.
        </Typography>
      </Link>
    </Box>
  );
}
