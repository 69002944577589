import React from "react";
import { Css } from "src/Css";

interface CardProps<X> {
  children?: React.ReactNode;
  /** Cards default to being "clickable", but setting to false removes the pointer and hover box shadow */
  isClickable?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  xss?: X;
}

export function Card<X>({ children, isClickable = true, onClick, xss }: CardProps<X>) {
  return (
    <div
      css={{
        transition: "box-shadow 0.3s",
        "&:hover": Css.if(isClickable).shadowDark.$,
        ...Css.br3.shadowBasic.overflowHidden.if(isClickable).cursorPointer.$,
        ...xss,
      }}
      onClick={onClick}
      data-testid="card"
    >
      {children}
    </div>
  );
}
