import { useState } from "react";
import { useParams } from "react-router";
import { PageContent, PageTitle, QueryResultHandler, Tabs, TabsContent } from "src/components";
import { Css } from "src/Css";
import {
  HomeownerProjectQuery,
  Market,
  ProjectStatus,
  SearchBy,
  useHomeownerProjectQuery,
} from "src/generated/graphql-types";
import { ProjectRouteProps } from "src/routes";
import { CustomerSupport } from "./components/CustomerSupport";
import { WarrantyList } from "./components/WarrantyList";
import { YourTeam } from "./components/YourTeam";

type resourcesTab = "Customer Support" | "Your Team" | "Warranty";

type ResourcesPageProps = {
  initialTab?: resourcesTab;
};

export function ResourcesPage(props: ResourcesPageProps) {
  const { initialTab } = props;
  const { projectId } = useParams<ProjectRouteProps>();
  const result = useHomeownerProjectQuery({ variables: { searchBy: SearchBy.Project, id: projectId! } });
  return QueryResultHandler<HomeownerProjectQuery>({
    result: result,
    render: (data) => <ResourcesDataView data={data} initialTab={initialTab} />,
  });
}

type ResourcesDataViewProps = {
  data: HomeownerProjectQuery;
  initialTab?: resourcesTab;
};

export function ResourcesDataView(props: ResourcesDataViewProps) {
  const { data, initialTab } = props;
  const { market, teamMembers, status } = data.homeownerProject;
  const warrantyEnabled = status?.code === ProjectStatus.UnderWarranty;
  const [selectedTab, setSelectedTab] = useState(initialTab ? initialTab : "Customer Support");
  let tabs = [{ name: "Customer Support" }, { name: "Your Team" }];
  if (warrantyEnabled) {
    tabs.push({ name: "Warranty" });
  }
  const tabsContent = {
    "Customer Support": <CustomerSupport market={market.name} warrantyEnabled={warrantyEnabled} />,
    "Your Team": <YourTeam market={market as Market} teamMembers={teamMembers} />,
    Warranty: <WarrantyList />,
  };

  const handleTabClick = (selectedTab: string) => {
    setSelectedTab(selectedTab as resourcesTab);
  };
  return (
    <PageContent xss={Css.pbPx(132).$}>
      <PageTitle title="Resources" />
      <Tabs tabs={tabs} selectedTab={selectedTab} onClick={handleTabClick} />
      <TabsContent selectedTab={selectedTab} contents={tabsContent} />
    </PageContent>
  );
}
