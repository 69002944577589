export enum HomeownerStages {
  PRE_CON_PLANNING = "pre-construction planning",
  PRE_CON_EXECUTION = "pre-construction",
  CONSTRUCTION = "construction",
  WARRANTY = "warranty",
  DEVELOPMENT = "development",
}

// index with getMonth()
export const MONTH_LABELS = [
  { short: "Jan", full: "January", monthValue: 0 },
  { short: "Feb", full: "February", monthValue: 1 },
  { short: "Mar", full: "March", monthValue: 2 },
  { short: "Apr", full: "April", monthValue: 3 },
  { short: "May", full: "May", monthValue: 4 },
  { short: "Jun", full: "June", monthValue: 5 },
  { short: "Jul", full: "July", monthValue: 6 },
  { short: "Aug", full: "August", monthValue: 7 },
  { short: "Sept", full: "September", monthValue: 8 },
  { short: "Oct", full: "October", monthValue: 9 },
  { short: "Nov", full: "November", monthValue: 10 },
  { short: "Dec", full: "December", monthValue: 11 },
];
