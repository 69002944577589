import { Box, Icon, makeStyles, TextField as MuiTextField } from "@material-ui/core";
import { TextFieldProps } from "@material-ui/core/TextField";
import clsx from "clsx";
import React from "react";
import ReactInputMask from "react-input-mask";
import { Typography } from "src/components/Typography";
import { Css, Palette } from "src/Css";
import { ErrorPalette, FontSize, fontStyles } from "src/theme";
import { makeId } from "src/utils";

interface Props
  extends Pick<
    TextFieldProps,
    | "type"
    | "placeholder"
    | "value"
    | "onChange"
    | "onKeyUp"
    | "multiline"
    | "inputRef"
    | "required"
    | "name"
    | "autoComplete"
  > {
  id?: string;
  alignment?: "left" | "center" | "right";
  disabled?: boolean;
  label: string;
  errors?: string[];
  hideError?: boolean;
  hideLabel?: boolean;
  // Always keep the label showing (i.e. a better name for shrink=true)
  fixedLabel?: boolean;
  min?: number;
  max?: number;
  size?: "small" | "regular";
  readOnly?: boolean;
  helperText?: React.ReactNode;
}

export function SignInTextField(props: Props) {
  const {
    alignment = "left",
    errors,
    disabled = false,
    type,
    label,
    hideError = false,
    hideLabel,
    fixedLabel,
    min,
    max,
    size = "regular",
    readOnly,
    helperText,
    name,
    autoComplete,
    ...others
  } = props;
  const { root, left, center, right, leftLabel, centerLabel, rightLabel, small, readOnlyStyle } = useStyles();

  const alignMapInput = { left, center, right };
  const alignMapLabel = {
    left: leftLabel,
    center: centerLabel,
    right: rightLabel,
  };

  const id = props.id || makeId(label);

  // We don't pass error=true to MuiTextField b/c it turns the labels red and we don't want that.
  const hasErrors = errors && errors.length > 0;

  const isSmall = size === "small";

  // Default to required b/c that is what most of our form fields are.
  const required = props.required === undefined ? true : props.required;

  // This is jank by `inputProps` and `InputProps` are detected by eslint as being duplicate props,
  // so set inputProps this way.
  (others as any).inputProps = {
    "aria-label": label,
    "data-testid": id,
    className: alignMapInput[alignment],
    ...(type === "tel" && { pattern: "^\\([0-9]{3}\\)\\s[0-9]{3}-[0-9]{4}$" }),
    ...(min && { min }),
    ...(max && { max }),
  };

  const isTel = type === "tel";
  const textField = (
    <MuiTextField
      id={id}
      disabled={!isTel && disabled}
      type={type}
      name={name}
      autoComplete={autoComplete}
      fullWidth={true}
      required={required}
      className={clsx(root, isSmall && small)}
      helperText={!hasErrors && helperText}
      // Allow hiding the label, but we'll always pass the text as aria-label.
      label={hideLabel ? "" : label}
      InputProps={{
        endAdornment: hasErrors && <Icon color="error">error</Icon>,
        className: clsx(isSmall && small, readOnly && readOnlyStyle),
        readOnly,
      }}
      InputLabelProps={{
        className: clsx(alignMapLabel[alignment], isSmall && small),
        shrink: fixedLabel !== undefined ? fixedLabel : undefined,
      }}
      {...others}
    />
  );

  return (
    <>
      {isTel && (
        <ReactInputMask
          mask="(999) 999-9999"
          value={props.value as string}
          disabled={disabled}
          onChange={props.onChange}
        >
          {() => textField}
        </ReactInputMask>
      )}
      {!isTel && textField}
      {!hideError && (
        <Box my={1}>
          <Typography size="f14" color={ErrorPalette.DarkRed} data-testid={`${id}.errors`}>
            {hasErrors && errors && errors.map((e, i) => <div key={i}>{e}</div>)}
            {!hasErrors && <div>&nbsp;</div>}
          </Typography>
        </Box>
      )}
    </>
  );
}

const useStyles = makeStyles({
  root: {
    marginTop: "8px",
    "& input": Css.pb2.$,
    "& .MuiFormLabel-root": {
      color: Palette.Gray600,
      ...fontStyles({ size: "F9" }),
    },
    "& .MuiFormHelperText-root": {
      fontSize: FontSize.PX12,
    },
    "&$small .MuiFormHelperText-root": {
      fontSize: FontSize.PX10,
    },
    "& .MuiInputBase-input": {
      ...fontStyles({ size: "F7" }),
    },
    "& .MuiInputLabel-root": {
      ...fontStyles({ size: "F8" }),
    },
    "& .MuiInputLabel-asterisk": {
      display: "none",
    },
  },
  left: { textAlign: "left" },
  center: { textAlign: "center" },
  right: { textAlign: "right" },
  leftLabel: { textAlign: "left" },
  centerLabel: {
    textAlign: "center",
    width: "100%",
    right: 0,
    transformOrigin: "top",
  },
  rightLabel: {
    textAlign: "right",
    left: "auto",
    right: 0,
    transformOrigin: "top right",
  },
  small: {
    fontSize: FontSize.PX12,
    "&.MuiInputLabel-shrink": {
      // Overrides the "scale" value as it was too small for 12px font size. Keeps translate the same value.
      transform: "translate(0, 1.5px) scale(0.9)",
    },
  },
  readOnlyStyle: {
    "&.MuiInput-underline": {
      "&:before, &:after, &:hover:before, &:hover:after": {
        borderBottom: 0,
      },
    },
  },
});
