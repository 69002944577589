import { Property } from "csstype";
import React from "react";
import { Css, Typography as Fonts } from "src/Css";
import { Colors } from "src/theme/palettes";

interface Props {
  children: React.ReactNode;
  size: Fonts;
  color: Colors;
  lineHeight?: number;
  tag?: React.ElementType;
  transform?: Property.TextTransform;
  "data-testid"?: string;
  className?: string;
}

/**  Our own Typography that speaks in our font sizes, colors, etc. */
export function Typography(props: Props) {
  const { tag: _tag, children, color, size, lineHeight, transform, ...rest } = props;
  const TagName = _tag || "div";

  return (
    <TagName
      css={{
        ...Css.color(color)[size].$,
        ...Css.if(!!lineHeight).lh(lineHeight + "px").$,
        ...Css.if(!!transform).tt(transform).$,
      }}
      data-testid={props["data-testid"] || "Typography"}
      {...rest}
    >
      {children}
    </TagName>
  );
}
