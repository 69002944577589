import { Modal, OutlineButton, TextButton } from "src/components";
import { Css } from "src/Css";

interface ChangeSelectionModalProp {
  open: boolean;
  onClose: () => void;
  onConfirmSelection?: () => void;
}

export function ChangeSelectionModal({ open, onClose, onConfirmSelection }: ChangeSelectionModalProp) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      css={Css.add("height", "fit-content").m("auto").$}
      title="Are you sure you want to change your finalized selection?"
    >
      <div css={Css.gray600.$}>
        You’ll need to finalize any new selection before your deadline so we can create an accurate final estimate.
      </div>
      <div css={Css.df.jcfe.mt6.$}>
        <TextButton css={Css.mr1.$} onClick={onClose}>
          Cancel
        </TextButton>
        <OutlineButton onClick={onConfirmSelection} size="narrow">
          Change Selection
        </OutlineButton>
      </div>
    </Modal>
  );
}
