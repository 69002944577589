import { useParams } from "react-router-dom";
import { NoNotifications } from "src/components/Icons";
import { LinkButton } from "src/components/v2/atoms/LinkButton";
import { Css } from "src/Css";
import {
  HomeownerInboxItemsFragment,
  InboxItemStatus,
  ProjectUpdateActionType,
  ProjectUpdateFragment,
  useInboxItemStatusMutation,
} from "src/generated/graphql-types";
import {
  documentsRoute,
  homeownerNoteRoute,
  inboxRoute,
  milestoneRoute,
  overviewRoute,
  ProjectRouteProps,
} from "src/routes";
import { compareDates } from "src/utils";

type UpdateFeedProps = {
  items: HomeownerInboxItemsFragment[];
  viewAll?: boolean;
};

export function UpdatesFeed(props: UpdateFeedProps) {
  const { projectId } = useParams<ProjectRouteProps>();
  const { items, viewAll = true } = props;
  const filteredItems = items.filter((item) => item.status !== InboxItemStatus.Archived);

  return (
    <div css={Css.df.fdc.bgWhitePure.h100.$}>
      <div css={Css.df.fdc.$}>
        {filteredItems?.length > 0 ? (
          filteredItems
            .sort((a, b) => compareDates(b.projectUpdate.eventTime, a.projectUpdate.eventTime))
            .map((item) => (
              <div key={item.id}>
                <Notification inboxItem={item} />
              </div>
            ))
        ) : (
          <div css={Css.pyPx(74).df.fdc.aic.if(viewAll).py8.$}>
            <NoNotifications />
            <div css={Css.f16.fw3.lh("24px").gray700.add("letterSpacing", "unset").$}>No Notifications Yet</div>
          </div>
        )}
      </div>
      {viewAll && filteredItems?.length > 0 && (
        <div css={Css.mta.$}>
          <LinkButton to={inboxRoute(projectId)}>
            <div css={Css.df.jcc.aic.p1.bgGray100.$}>
              <div css={Css.f14.gray700.$}>View All Updates</div>
            </div>
          </LinkButton>
        </div>
      )}
    </div>
  );
}

type NotificationProps = {
  inboxItem: HomeownerInboxItemsFragment;
  onClick?: () => void;
};

export const Notification = (props: NotificationProps) => {
  const { inboxItem, onClick } = props;
  const { projectId } = useParams<ProjectRouteProps>();
  const [markItemRead] = useInboxItemStatusMutation({ refetchQueries: ["HomeownerProjectUpdates"] });
  const { status, id, projectUpdate } = inboxItem;
  const notificationInfo = getNotificationInfo(inboxItem.projectUpdate);
  const unread = status === InboxItemStatus.Unread;
  const pathname = getPath(projectUpdate, projectId);

  async function handleClick() {
    await markItemRead({
      variables: {
        inboxItemId: id,
        status: InboxItemStatus.Read,
      },
    }).catch(console.error);
  }

  return (
    <LinkButton to={{ pathname, state: { fromOverview: true } }} onClick={onclick ? onClick : handleClick}>
      <div
        css={{
          ...Css.noUnderline.py1.bt.bTaupe.if(unread).bgGray100.$,
          "&:hover": Css.bgWhite.cursorPointer.$,
          "&:hover > .blueBox": Css.bgBlue50.br4.$,
          "&:hover > .blueBox > .notificationHeader": Css.underline.$,
        }}
      >
        <div className="blueBox" css={Css.mx1.pt1.$}>
          <div
            className="notificationHeader"
            css={Css.px2.pbPx(4).header16.add("letterSpacing", "0.32px").lh("24px").fw5.black.b.if(unread).normal.$}
          >
            {notificationInfo[0]}
          </div>
          <div css={Css.noUnderline.px2.pb1.f14.add("letterSpacing", "0.22px").lh("24px").fw3.gray700.$}>
            {notificationInfo[1]}
          </div>
        </div>
      </div>
    </LinkButton>
  );
};

function getNotificationInfo(projectUpdate: ProjectUpdateFragment) {
  switch (projectUpdate.actionType) {
    case ProjectUpdateActionType.DocumentAvailable:
      return ["New Document Available", projectUpdate.document!.displayName];
    case ProjectUpdateActionType.UnitComplete:
      return ["Milestone Complete", projectUpdate.schedulePhase!.name];
    case ProjectUpdateActionType.NewHomeownerNote:
      return ["New Journey Update", projectUpdate.homeownerNote!.title];
    default:
      return ["", ""];
  }
}

export function getPath({ actionType, homeownerNote, schedulePhase }: ProjectUpdateFragment, projectId: string) {
  switch (actionType) {
    case ProjectUpdateActionType.DocumentAvailable:
      // todo make this go to the right document
      return documentsRoute(projectId);
    case ProjectUpdateActionType.UnitComplete:
      return milestoneRoute(projectId, schedulePhase!.id);
    case ProjectUpdateActionType.NewHomeownerNote:
      return homeownerNoteRoute(projectId, homeownerNote!.id);
    default:
      return overviewRoute(projectId);
  }
}
