import { createMuiTheme } from "@material-ui/core";
import { FontSizes, FontSizesInPixels, LetterSpacing, LineHeight } from "./fonts";
import { GoodSansRegular } from "./GoodSansRegular";
import { ErrorPalette, HomeownerPalette } from "./palettes";

const { Black, White } = HomeownerPalette;

export const muiTheme = {
  themeName: "muiTheme",
  ...createMuiTheme({
    palette: {
      primary: {
        main: Black,
        contrastText: White,
      },

      error: {
        light: ErrorPalette.LightRed,
        main: ErrorPalette.Red,
        dark: ErrorPalette.DarkRed,
        contrastText: White,
      },
      background: {
        default: White,
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    shape: {
      borderRadius: 25,
    },
    typography: {
      fontFamily: GoodSansRegular.fontFamily,
    },
  }),
};

muiTheme.overrides = {
  MuiCssBaseline: {
    "@global": {
      // Setting this to ignore because the @font-face isn't allowed but should be
      // @ts-ignore
      "@font-face": [GoodSansRegular],
    },
  },
  MuiMenuItem: {
    // Make select box items the right font/color.
    root: {
      ...fontStyles({ size: "F8" }),
      color: Black,
    },
  },
  MuiDrawer: {
    paperAnchorDockedLeft: {
      borderRight: 0,
    },
  },
  MuiPaper: {
    elevation8: {
      boxShadow: `0px 2px 24px ${HomeownerPalette.DarkTransparentGray}, 0px 4px 8px ${HomeownerPalette.DarkTransparentGray}`,
    },
  },
  MuiList: {
    root: {
      padding: 0,
      "&:focus": {
        outline: "none",
      },
    },
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  MuiDialog: {
    container: {
      cursor: "pointer",
    },
    paper: {
      cursor: "auto",
    },
  },
};

export function fontStyles(props: { size: FontSizes; lineHeight?: number; serif?: boolean }) {
  return {
    fontSize: `${FontSizesInPixels[props.size as any]}px`,
    lineHeight: `${props.lineHeight || LineHeight[props.size]}px`,
    letterSpacing: LetterSpacing[props.size],
    fontFamily: GoodSansRegular.fontFamily,
    fontWeight: GoodSansRegular.fontWeight,
  };
}
