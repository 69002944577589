export const NoOptionsIllustration = (
  <svg width="120" height="81" viewBox="0 0 120 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M56.084 37.8921H1.64419C1.20828 37.8917 0.790363 37.7183 0.482129 37.41C0.173895 37.1018 0.000521332 36.6839 4.57764e-05 36.248V1.64415C0.000523361 1.20824 0.173898 0.790322 0.482131 0.482088C0.790365 0.173854 1.20828 0.000477992 1.64419 0H56.084C56.5199 0.00047637 56.9378 0.173852 57.246 0.482088C57.5543 0.790323 57.7276 1.20824 57.7281 1.64415V36.248C57.7276 36.6839 57.5543 37.1018 57.246 37.41C56.9378 37.7183 56.5199 37.8917 56.084 37.8921ZM1.64419 0.36536C1.30516 0.365771 0.980138 0.500632 0.740408 0.740362C0.500678 0.980092 0.365818 1.30512 0.365408 1.64415V36.248C0.365818 36.587 0.500676 36.912 0.740406 37.1518C0.980136 37.3915 1.30516 37.5264 1.64419 37.5268H56.084C56.423 37.5264 56.748 37.3915 56.9877 37.1518C57.2275 36.912 57.3623 36.587 57.3627 36.248V1.64415C57.3623 1.30512 57.2275 0.980092 56.9877 0.740362C56.748 0.500632 56.423 0.36577 56.084 0.36536H1.64419Z"
        fill="#353535"
      />
      <path
        d="M33.2957 32.2109C38.8881 32.2109 43.4216 27.6774 43.4216 22.0849C43.4216 16.4925 38.8881 11.959 33.2957 11.959C27.7033 11.959 23.1697 16.4925 23.1697 22.0849C23.1697 27.6774 27.7033 32.2109 33.2957 32.2109Z"
        fill="#CECECE"
      />
      <path
        d="M111.289 80.2257C111.671 76.1057 112.728 72.0766 114.416 68.299C114.887 67.2478 115.405 66.2197 115.97 65.2147C116.115 64.9562 115.718 64.7239 115.573 64.9824C113.517 68.6427 112.077 72.616 111.309 76.7434C111.096 77.8961 110.936 79.0568 110.828 80.2257C110.801 80.5205 111.262 80.5187 111.289 80.2257H111.289Z"
        fill="#CECECE"
      />
      <path
        d="M117.085 65.9528C118.695 65.9528 120 64.6477 120 63.0377C120 61.4277 118.695 60.1226 117.085 60.1226C115.475 60.1226 114.17 61.4277 114.17 63.0377C114.17 64.6477 115.475 65.9528 117.085 65.9528Z"
        fill="#CECECE"
      />
      <path
        d="M111.587 80.3029C111.835 77.6301 112.52 75.0162 113.616 72.5655C113.921 71.8835 114.257 71.2166 114.624 70.5646C114.642 70.5305 114.647 70.4906 114.636 70.4533C114.626 70.416 114.601 70.3842 114.568 70.3647C114.534 70.3451 114.495 70.3393 114.457 70.3485C114.419 70.3577 114.387 70.3811 114.366 70.4139C113.032 72.7885 112.098 75.3662 111.6 78.0438C111.462 78.7916 111.358 79.5446 111.288 80.3029C111.271 80.4942 111.569 80.493 111.587 80.3029Z"
        fill="#CECECE"
      />
      <path
        d="M115.347 71.0436C116.392 71.0436 117.238 70.1969 117.238 69.1524C117.238 68.1079 116.392 67.2612 115.347 67.2612C114.303 67.2612 113.456 68.1079 113.456 69.1524C113.456 70.1969 114.303 71.0436 115.347 71.0436Z"
        fill="#CECECE"
      />
      <path
        d="M111.21 80.1245C109.762 77.8644 108.694 75.3818 108.051 72.7758C107.872 72.0503 107.727 71.3177 107.615 70.5781C107.608 70.5399 107.587 70.5057 107.556 70.4827C107.525 70.4597 107.486 70.4498 107.448 70.4549C107.409 70.4601 107.374 70.48 107.35 70.5104C107.326 70.5407 107.315 70.5793 107.319 70.6178C107.728 73.3105 108.577 75.9176 109.831 78.3349C110.182 79.0097 110.563 79.6674 110.975 80.3082C111.078 80.4699 111.313 80.2852 111.21 80.1245V80.1245Z"
        fill="#CECECE"
      />
      <path
        d="M107.317 70.9108C108.361 70.9108 109.208 70.0641 109.208 69.0196C109.208 67.9751 108.361 67.1284 107.317 67.1284C106.272 67.1284 105.425 67.9751 105.425 69.0196C105.425 70.0641 106.272 70.9108 107.317 70.9108Z"
        fill="#CECECE"
      />
      <path
        d="M96.0807 52.8545C96.2049 52.6662 96.2863 52.453 96.3191 52.2299C96.352 52.0067 96.3356 51.7791 96.2709 51.563C96.2063 51.3469 96.0951 51.1476 95.9451 50.9791C95.7952 50.8106 95.6101 50.6771 95.4029 50.5879L95.8004 45.1196L93.3145 46.5116L93.3181 51.4909C93.1913 51.8489 93.2003 52.2409 93.3432 52.5927C93.4862 52.9445 93.7532 53.2317 94.0937 53.3998C94.4343 53.5679 94.8246 53.6052 95.1908 53.5047C95.557 53.4042 95.8736 53.1728 96.0807 52.8545Z"
        fill="#FFB8B8"
      />
      <path
        d="M95.4623 31.5974L96.142 48.9989L93.3803 48.8455L92.7666 30.5876L93.349 30.3135C93.5706 30.2092 93.8146 30.1615 94.0591 30.1744C94.3037 30.1874 94.5412 30.2608 94.7505 30.3879C94.9598 30.5151 95.1343 30.6921 95.2585 30.9031C95.3827 31.1142 95.4527 31.3527 95.4623 31.5974Z"
        fill="#F6B14E"
      />
      <path d="M86.9213 78.5859H85.0404L84.1455 71.3306H86.9216L86.9213 78.5859Z" fill="#FFB8B8" />
      <path
        d="M83.6968 78.0483H87.3244V80.3324H81.4128C81.4128 80.0324 81.4718 79.7354 81.5866 79.4583C81.7014 79.1812 81.8697 78.9294 82.0818 78.7173C82.2938 78.5052 82.5456 78.337 82.8228 78.2222C83.0999 78.1074 83.3969 78.0483 83.6968 78.0483Z"
        fill="#353535"
      />
      <path d="M102.571 76.3379L100.864 77.1281L97.0041 70.9203L99.5235 69.7539L102.571 76.3379Z" fill="#FFB8B8" />
      <path
        d="M99.4195 77.205L102.711 75.681L103.671 77.7538L98.3063 80.2373C98.1803 79.9651 98.1091 79.6707 98.0969 79.371C98.0846 79.0713 98.1315 78.7722 98.2349 78.4906C98.3382 78.209 98.4961 77.9506 98.6993 77.73C98.9026 77.5094 99.1473 77.331 99.4195 77.205Z"
        fill="#353535"
      />
      <path
        d="M82.455 54.9077L84.3949 77.0456L87.0032 76.7388L88.9309 56.517L99.5842 75.8182L102.346 75.0511C102.346 75.0511 93.457 48.4621 92.7666 46.2374C92.0097 43.7986 86.0158 42.862 86.0158 42.862L85.7856 42.6318C83.2101 46.0989 82.1785 50.5975 82.455 54.9077Z"
        fill="#353535"
      />
      <path
        d="M92.7895 28.3931C94.8707 28.3931 96.5578 26.706 96.5578 24.6248C96.5578 22.5436 94.8707 20.8564 92.7895 20.8564C90.7083 20.8564 89.0211 22.5436 89.0211 24.6248C89.0211 26.706 90.7083 28.3931 92.7895 28.3931Z"
        fill="#FFB8B8"
      />
      <path
        d="M95.4417 30.4038L92.5364 45.5471C92.5364 45.5471 88.5565 44.7523 87.1388 43.795C86.5773 43.4145 86.0679 43.0217 85.6383 42.6657C85.1551 42.2689 84.6922 41.848 84.2513 41.4046C84.2513 41.4046 85.2701 36.4013 85.6337 35.0619C86.0559 33.5104 87.5045 31.7032 88.6327 30.4717C89.0417 30.0256 89.5645 29.6991 90.1448 29.5273C90.7252 29.3555 91.3414 29.3449 91.9274 29.4965L95.4417 30.4038Z"
        fill="#F6B14E"
      />
      <path
        opacity="0.2"
        d="M87.1388 43.7949C86.5774 43.4144 86.0679 43.0216 85.6384 42.6656C85.7933 40.3734 89.1702 35.5742 90.5388 35.5742C90.5388 35.5742 90.8625 42.971 87.1388 43.7949Z"
        fill="#353535"
      />
      <path
        d="M80.188 49.815C80.4 49.7382 80.5926 49.6157 80.7522 49.4563C80.9118 49.297 81.0346 49.1045 81.1117 48.8926C81.1889 48.6807 81.2187 48.4544 81.199 48.2297C81.1793 48.005 81.1105 47.7874 80.9976 47.5921L84.5815 43.4429L81.7562 43.0752L78.786 47.0717C78.4706 47.2832 78.2437 47.603 78.1483 47.9706C78.0529 48.3381 78.0957 48.7279 78.2685 49.0661C78.4413 49.4042 78.7322 49.6672 79.0859 49.8053C79.4397 49.9433 79.8318 49.9468 80.188 49.815Z"
        fill="#FFB8B8"
      />
      <path
        d="M90.2077 29.671C90.0516 29.6742 89.8962 29.692 89.7434 29.7242C89.4342 29.7949 89.1473 29.9412 88.9085 30.15C88.6697 30.3589 88.4864 30.6238 88.3752 30.9209L83.9445 40.177L79.1883 45.2401L81.5664 47.1579L89.0843 40.4071L92.0878 32.2323C92.1837 31.937 92.208 31.6232 92.1587 31.3168C92.1094 31.0103 91.9879 30.72 91.8042 30.4698C91.6205 30.2195 91.38 30.0166 91.1024 29.8777C90.8248 29.7388 90.5181 29.668 90.2077 29.671Z"
        fill="#F6B14E"
      />
      <path
        d="M93.0126 28.3583C93.5489 27.5556 93.9722 26.4424 93.3817 25.6786C92.7988 24.9246 91.6492 25.0357 90.7565 24.7021C89.5131 24.2374 88.7919 22.7767 89.0168 21.4685C89.2417 20.1603 90.2919 19.0759 91.5429 18.6322C92.7939 18.1884 94.2046 18.3328 95.4297 18.8437C96.9378 19.4727 98.2315 20.6895 98.774 22.2308C99.3164 23.7722 99.0264 25.6228 97.9011 26.8075C96.6945 28.0776 94.801 28.4161 93.0492 28.3956"
        fill="#353535"
      />
      <path
        d="M98.9301 23.9153C99.0836 24.7731 100.025 25.2603 100.889 25.3674C101.754 25.4745 102.649 25.3372 103.491 25.5621C104.975 25.9586 105.954 27.3861 106.493 28.8248C107.032 30.2635 107.271 31.8164 107.971 33.1839C108.671 34.5515 110.028 35.7571 111.559 35.6292C111.26 36.3843 110.734 37.0285 110.054 37.4739C109.375 37.9192 108.574 38.1441 107.762 38.1177C106.95 38.0914 106.166 37.8152 105.517 37.3267C104.868 36.8383 104.385 36.1614 104.134 35.3884C103.572 33.6521 104.218 31.5815 103.234 30.044C102.69 29.1932 101.75 28.6928 100.844 28.2469C99.9375 27.801 98.9863 27.3433 98.3788 26.5366C97.7712 25.7298 97.6498 24.4557 98.3985 23.7778"
        fill="#353535"
      />
      <path
        d="M117.281 80.4132H68.1386C68.1146 80.4133 68.0908 80.4086 68.0685 80.3995C68.0463 80.3903 68.0261 80.3769 68.0091 80.3599C67.992 80.3429 67.9786 80.3228 67.9693 80.3006C67.9601 80.2784 67.9554 80.2546 67.9554 80.2305C67.9554 80.2065 67.9601 80.1827 67.9693 80.1605C67.9786 80.1383 67.992 80.1181 68.0091 80.1012C68.0261 80.0842 68.0463 80.0708 68.0685 80.0616C68.0908 80.0525 68.1146 80.0478 68.1386 80.0479H117.281C117.305 80.0478 117.328 80.0525 117.351 80.0616C117.373 80.0708 117.393 80.0842 117.41 80.1012C117.427 80.1181 117.441 80.1383 117.45 80.1605C117.459 80.1827 117.464 80.2065 117.464 80.2305C117.464 80.2546 117.459 80.2784 117.45 80.3006C117.441 80.3228 117.427 80.3429 117.41 80.3599C117.393 80.3769 117.373 80.3903 117.351 80.3995C117.328 80.4086 117.305 80.4133 117.281 80.4132Z"
        fill="#353535"
      />
      <path
        d="M30.2334 34.238C28.2003 34.238 26.2129 33.6351 24.5225 32.5056C22.8321 31.376 21.5145 29.7706 20.7365 27.8923C19.9585 26.014 19.7549 23.9472 20.1516 21.9532C20.5482 19.9592 21.5272 18.1276 22.9648 16.69C24.4024 15.2524 26.234 14.2734 28.228 13.8767C30.222 13.4801 32.2889 13.6836 34.1672 14.4617C36.0455 15.2397 37.6509 16.5572 38.7804 18.2476C39.9099 19.9381 40.5128 21.9255 40.5128 23.9585C40.5098 26.6839 39.4258 29.2967 37.4987 31.2238C35.5716 33.1509 32.9587 34.2349 30.2334 34.238ZM30.2334 13.9861C28.261 13.9861 26.3329 14.5709 24.693 15.6668C23.053 16.7626 21.7748 18.3201 21.02 20.1423C20.2652 21.9645 20.0677 23.9697 20.4525 25.9042C20.8373 27.8386 21.7871 29.6156 23.1818 31.0103C24.5765 32.4049 26.3535 33.3547 28.2879 33.7395C30.2224 34.1243 32.2276 33.9268 34.0498 33.172C35.872 32.4172 37.4295 31.139 38.5253 29.499C39.6211 27.859 40.206 25.9309 40.206 23.9585C40.203 21.3146 39.1513 18.7798 37.2818 16.9102C35.4122 15.0407 32.8774 13.989 30.2334 13.9861Z"
        fill="#353535"
      />
      <path
        d="M23.7773 5.68115L14.3064 22.0852H33.2482L23.7773 5.68115L14.3064 22.0852H33.2482L23.7773 5.68115Z"
        fill="#F6B14E"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="120" height="80.4461" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
