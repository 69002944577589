import { useMemo, useState } from "react";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { Accordion } from "src/components";
import { CircleCheckMark } from "src/components/Icons";
import { Css, increment, Palette, px } from "src/Css";
import {
  HomeownerSelectionStatus,
  SelectionDetailsFragment,
  SelectionsByLocationDetailsFragment,
} from "src/generated/graphql-types";
import { useSelections } from "src/hooks";
import { projectIdParam, ProjectRouteProps, selectionRoute, spaceIdParam, SpaceRouteProps } from "src/routes";
import { getSpace, getSpaceSelections } from "src/utils";
import { ToolbarHeader } from "./";

export function ToolbarMoodboard() {
  const { spaceId } = useRouteMatch<SpaceRouteProps>(selectionRoute(projectIdParam, spaceIdParam))?.params ?? {};
  const selectionsBySpace = useSelections();
  const spaceName = getSpace(selectionsBySpace, spaceId)?.location.name.toLowerCase() || "home";

  const spaceSelections = useMemo(
    () =>
      formattedSpaceSelections(getSpaceSelections(selectionsBySpace, spaceId) as SelectionDetailsFragment[], spaceId),
    [selectionsBySpace, spaceId],
  );

  const allSelections = useMemo(() => getAllSelections(selectionsBySpace), [selectionsBySpace]);

  return (
    <ToolbarMoodboardDataView
      spaceName={spaceName}
      spaceSelections={spaceId ? spaceSelections : undefined}
      allSelections={!spaceId ? allSelections : undefined}
    />
  );
}

interface SelectionsBySpace {
  selections: MoodboardSelection[];
  spaceName?: string;
  spaceId?: string;
  expanded?: boolean; // used for story
}

interface ToolbarMoodboardProps {
  spaceName: string;
  spaceSelections?: MoodboardSelection[];
  allSelections?: SelectionsBySpace[];
}

interface MoodboardSelection {
  id: string;
  finalized: boolean;
  name: string;
  src: string;
  spaceId: string;
}

export function ToolbarMoodboardDataView({ spaceSelections, spaceName, allSelections }: ToolbarMoodboardProps) {
  return (
    <div data-testid="toolbarComponentMoodboard">
      <ToolbarHeader title="Coming Together" subTitle={`Compare products that are selected in your ${spaceName}.`} />

      {/* displays selections images for a single space when on SelectionView */}
      {spaceSelections && (
        <div css={Css.p2.$}>
          <PhotoGrid selections={spaceSelections} />
        </div>
      )}

      {/* displays selection images grouped by space when on SpacesView */}
      {allSelections && <AllSelectionsGrid allSelections={allSelections} />}
    </div>
  );
}

function AllSelectionsGrid({ allSelections }: Pick<ToolbarMoodboardProps, "allSelections">) {
  return (
    <>
      {allSelections!.map(({ spaceName, selections, expanded }) => {
        const title = `${spaceName} (${selections.length})`;
        return (
          <Accordion key={spaceName} className="list" {...{ title, expanded }}>
            <PhotoGrid selections={selections} />
          </Accordion>
        );
      })}
    </>
  );
}

// Photos are displayed in a 2x2, 1x1, 1x1 grid pattern
// Pattern repeats every 4 images
function PhotoGrid({ selections }: Pick<SelectionsBySpace, "selections">) {
  const lastPhotoIndex = selections.length - 1;
  let elements = [];

  // creates pattern for groups of 4 images
  for (let i = 0; i <= lastPhotoIndex; i++) {
    // create 2x2 grid at start of pattern of 4 unless this is the last photo
    if (i % 4 === 0 && i !== lastPhotoIndex) {
      elements.push(<DualPhotos {...{ i, selections }} key={selections[i].id} />);
      i++;
    } else {
      elements.push(<SinglePhoto {...{ i, selections }} key={selections[i].id} />);
    }
  }

  return (
    <div
      css={{
        ...Css.dg.add("gridGap", px(increment(1))).$,
        "div:last-of-type img": Css.mb2.$,
      }}
    >
      {elements}
    </div>
  );
}

interface PhotoProps {
  i: number;
  selections: readonly MoodboardSelection[];
}

function DualPhotos({ i, selections }: PhotoProps) {
  return (
    <div css={Css.dg.add("gridTemplateColumns", "1fr 1fr").add("gridGap", px(increment(1))).$} key={selections[i].id}>
      <PhotoElement photo={selections[i]} h={200} key={selections[i].id} />
      <PhotoElement photo={selections[i + 1]} h={200} key={selections[i + 1].id} />
    </div>
  );
}

function SinglePhoto({ i, selections }: PhotoProps) {
  return <PhotoElement photo={selections[i]} h={185} key={selections[i].id} />;
}

interface PhotoElementProps {
  photo: MoodboardSelection;
  h: number;
  isHovered?: boolean; // only used for the story
}

export function PhotoElement(props: PhotoElementProps) {
  const { photo, h, isHovered = false } = props;
  const { src, name, finalized, id } = photo;
  const { projectId } = useParams<ProjectRouteProps>();
  const [hovered, setHovered] = useState(isHovered);

  return (
    <Link
      css={Css.relative.h(px(h)).bgWhite.br3.$}
      key={id}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      to={selectionRoute(projectId, photo.spaceId, id)}
    >
      {hovered && <div css={Css.absolute.bgBlueTransparent.br3.h100.w100.df.aic.jcc.tc.ttc.t18.white.$}>{name}</div>}
      {finalized && (
        <CircleCheckMark color={Palette.White} secondaryColor={Palette.Black} xss={Css.absolute.right1.top1.$} />
      )}
      <img src={src} alt={name} css={Css.w100.br3.h(px(h)).add("objectFit", "contain").$} />
    </Link>
  );
}

function getAllSelections(selectionsBySpace: SelectionsByLocationDetailsFragment[] = []): SelectionsBySpace[] {
  return selectionsBySpace.map(({ location, selections }) => ({
    spaceId: location.id,
    spaceName: location.name,
    selections: formattedSpaceSelections(selections, location.id),
  }));
}

// Exported for tests
export function formattedSpaceSelections(
  spaceSelections?: SelectionDetailsFragment[],
  spaceId?: string,
): MoodboardSelection[] {
  return (
    spaceSelections
      ?.map((selection) => {
        const { selectedOption, recommendedOption, options, projectItem, status, id } = selection;
        const option = selectedOption ?? recommendedOption ?? options?.[0];
        return {
          id,
          finalized: status.code === HomeownerSelectionStatus.Finalized,
          name: projectItem.name,
          src: option?.product?.images?.[0]?.downloadUrl,
          spaceId: spaceId!,
        };
      })
      .filter((s) => !!s.src) ?? []
  );
}
