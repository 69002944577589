import { Only } from "src/Css";
import { defaultIconProps, IconProps, IconXss } from "./";

export function Moodboard<X extends Only<IconXss, X>>(props: IconProps<X>) {
  const { color, xss } = {
    ...defaultIconProps,
    ...props,
  };
  return (
    <svg css={xss} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.97 7.26999C21.36 6.87999 21.36 6.24999 20.97 5.85999L18.14 3.02999C17.75 2.63999 17.12 2.63999 16.73 3.02999L12.24 7.51999L8.35 3.62999C7.57 2.84999 6.3 2.84999 5.52 3.62999L3.62 5.52999C2.84 6.30999 2.84 7.57999 3.62 8.35999L7.51 12.25L3 16.76V21H7.24L11.76 16.48L15.65 20.37C16.6 21.32 17.88 20.97 18.48 20.37L20.38 18.47C21.16 17.69 21.16 16.42 20.38 15.64L16.49 11.75L20.97 7.26999ZM5.04 6.93999L6.93 5.03999L8.2 6.30999L7.02 7.49999L8.43 8.90999L9.62 7.71999L10.82 8.91999L8.92 10.82L5.04 6.93999ZM16.27 14.38L15.08 15.57L16.49 16.98L17.68 15.79L18.95 17.06L17.05 18.96L13.16 15.07L15.06 13.17L16.27 14.38ZM6.41 19H5V17.59L14.61 7.97999L15.91 9.27999L16.02 9.38999L6.41 19ZM16.02 6.55999L17.43 5.14999L18.84 6.55999L17.43 7.96999L16.02 6.55999Z"
        fill={color}
      />
    </svg>
  );
}
