import { AuthViewProps, signOut as authSignOut } from "@homebound/auth-components";
import { useParams } from "react-router-dom";
import { OutlineButton, PageContent, PageTitle } from "src/components";
import { Css } from "src/Css";
import { careEmail } from "src/email";
import { SearchBy } from "src/generated/graphql-types";
import { useHomeownerProjectSearchQuery } from "src/hooks";
import { ProjectRouteProps } from "src/routes";

export function Profile({ user, setIsAuthenticated }: AuthViewProps) {
  const { projectId } = useParams<ProjectRouteProps>();

  function signOut() {
    authSignOut();
    setIsAuthenticated(false);
  }

  return (
    <PageContent>
      <PageTitle title="Profile" />
      <div css={Css.f14.fw3.gray700.$}>
        If you would like to change any of the details below, please contact{" "}
        <a
          css={{
            ...Css.noUnderline.gray700.cursorPointer.underline.$,
            "&:hover": Css.noUnderline.$,
          }}
          href={`mailto:${careEmail}`}
        >
          {careEmail}
        </a>
      </div>
      <div css={Css.pt7.df.ifXsOrSm.pt4.fdc.$}>
        <div css={Css.pr8.ifXsOrSm.pb4.pr0.$}>
          <div css={Css.f16.black.$}>Account information</div>
          <UserProfile name={user!.name} email={user!.email} phone={user?.phone} />
        </div>
        <div css={Css.bl.bGray300.gray400.pl8.ifXsOrSm.add("border", "0").bt.bb.pl0.py4.$}>
          <CollaboratorProfiles projectId={projectId} userEmail={user!.email} />
        </div>
      </div>
      <div css={Css.pt6.$}>
        <OutlineButton onClick={signOut}>Log out</OutlineButton>
      </div>
    </PageContent>
  );
}

interface UserProfileProps {
  name: string | undefined;
  email: string | undefined;
  phone: string | undefined;
}

const UserProfile = ({ name, email, phone }: UserProfileProps) => {
  return (
    <div css={Css.mt3.df.fdc.gap2.$}>
      {name && (
        <div css={Css.df.$}>
          <div css={Css.wPx(65).f14.gray600.$}>Name</div>
          <div css={Css.f14.$}>{name}</div>
        </div>
      )}
      {phone && (
        <div css={Css.df.$}>
          <div css={Css.wPx(65).f14.gray600.$}>Phone</div>
          <div css={Css.f14.$}>{phone}</div>
        </div>
      )}
      {email && (
        <div css={Css.df.$}>
          <div css={Css.wPx(65).f14.gray600.$}>Email</div>
          <div css={Css.f14.$}>{email}</div>
        </div>
      )}
    </div>
  );
};

interface CollaboratorProfilesProps {
  projectId: string;
  userEmail: string;
}

export function CollaboratorProfiles({ projectId, userEmail }: CollaboratorProfilesProps) {
  const { data } = useHomeownerProjectSearchQuery(SearchBy.Project, projectId);
  const collaborators = data?.homeownerProject?.collaborators?.filter((c) => c.collaborator.email !== userEmail);

  if (!collaborators || collaborators.length === 0) return null;

  return (
    <div>
      <div css={Css.f16.black.$}>Shared with</div>
      {collaborators.map((c, index) => {
        const { firstName, lastName, email, phone } = c.collaborator;
        const fullName = firstName && lastName ? firstName + " " + lastName : undefined;
        return <UserProfile name={fullName} email={email} key={index} phone={phone || undefined} />;
      })}
    </div>
  );
}
