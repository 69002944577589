import { Link, Redirect, useParams } from "react-router-dom";
import { AssetGallery, ImageCarousel, LinkButton, PageContent, QueryResultHandler } from "src/components";
import { Css } from "src/Css";
import { JourneyNoteFragment, JourneyPageQuery, useJourneyPageQuery } from "src/generated/graphql-types";
import { journeyRoute, milestoneRoute, NoteRouteProps, taskRoute } from "src/routes";
import { compareDates, longMonthDateFormat } from "src/utils";
import { getSchedulePhaseInfo } from "./utils/schedulePhaseInfo";

export function NoteDetail() {
  const { projectId, homeownerNoteId } = useParams<NoteRouteProps>();
  const result = useJourneyPageQuery({
    variables: {
      projectId,
    },
  });
  return QueryResultHandler<JourneyPageQuery>({
    result,
    render: (data) => (
      <NoteDetailView homeownerNote={data.homeownerProject.homeownerNotes.find((hn) => hn.id === homeownerNoteId)} />
    ),
  });
}

type NoteDetailViewProps = {
  homeownerNote?: JourneyNoteFragment;
};

export function NoteDetailView(props: NoteDetailViewProps) {
  const { projectId } = useParams<NoteRouteProps>();

  if (!props.homeownerNote) return <Redirect to={journeyRoute(projectId)} />;
  const { homeownerNote } = props;

  const scheduleTasks = homeownerNote.scheduleTasks;
  const completedTasks = scheduleTasks.filter((task) => task.completedAt <= homeownerNote.createdAt);
  const inProgressTasks = scheduleTasks.filter(
    (task) =>
      task.interval.startDate <= homeownerNote.createdAt &&
      task.interval.endDate > homeownerNote.createdAt &&
      task.completedAt === null,
  );
  const upcomingTasks = scheduleTasks.filter((task) => task.interval.startDate > homeownerNote.createdAt);

  return (
    <PageContent>
      <div css={Css.mxa.w100.pb4.ptPx(61).ifXs.pt0.$}>
        <div css={Css.ifXs.w("100vw").relative.left("-24px").$}>
          <AssetGallery assets={homeownerNote.jobLogImages.map((i) => i.asset)} showZoomInOutIcons download zoom>
            {(openGallery) => (
              <div css={Css.w100.$}>
                <ImageCarousel
                  items={homeownerNote.jobLogImages.map(({ asset, id }) => (
                    <div key={id} css={Css.wPx(565).hPx(340).cursorPointer.$} onClick={() => openGallery(asset)}>
                      <img
                        src={asset?.previewUrl || ""}
                        alt={id}
                        placeholder={id}
                        css={Css.w100.objectCover.add("aspectRatio", "5/3").$}
                      />
                    </div>
                  ))}
                />
              </div>
            )}
          </AssetGallery>
        </div>
        <div css={Css.df.w100.pt7.plPx(90).jcfs.gapPx(133).ifXs.fdc.pt4.pl0.$}>
          <div css={Css.df.fdc.w75.ifXs.w100.$}>
            <div css={Css.f12.fw4.add("letterSpacing", ".48px").ttu.$}>
              {longMonthDateFormat(homeownerNote.createdAt)}
            </div>
            <h5 css={Css.f24.pt1.fw4.add("letterSpacing", ".48px").lh("40px").$}>{homeownerNote.title}</h5>
            <div css={Css.f16.pt1.fw3.lh("24px").ifSmAndUp.pb3.$}>{homeownerNote.description}</div>
            <div css={Css.dn.ifXs.df.fdc.my6.$}>
              <div css={Css.f12.pb3.fw4.add("letterSpacing", ".48px").ifXs.pb1.$}>MILESTONES</div>
              {homeownerNote.schedulePhases.map((schedulePhase) => {
                const schedulePhaseInfo = getSchedulePhaseInfo(schedulePhase.name);
                return (
                  <Link
                    key={schedulePhase.id}
                    css={Css.f18.fw5.lh("28px").pt1.add("letterSpacing", ".36px").gray800.$}
                    to={{
                      pathname: milestoneRoute(projectId, schedulePhase.id),
                      state: { from: "homeownerNote", homeownerNoteId: homeownerNote.id },
                    }}
                  >
                    {schedulePhaseInfo.replacementTitle || schedulePhase.name}
                  </Link>
                );
              })}
            </div>
            {completedTasks.length > 0 && (
              <>
                <div css={Css.f20.fw4.add("letterSpacing", ".48px").lh("32px").$}>Completed tasks:</div>
                <ol css={Css.mt2.mb4.$}>
                  {completedTasks
                    .sort((a, b) => compareDates(a.completedAt, b.completedAt))
                    .map((task) => (
                      <LinkButton
                        key={task.id}
                        to={taskRoute(projectId, homeownerNote.id, task.id)}
                        underline="initial"
                      >
                        <li css={Css.f16.fw3.lh("24px").gray700.$} key={task.id}>
                          {task.name}
                        </li>
                      </LinkButton>
                    ))}
                </ol>
              </>
            )}
            {inProgressTasks.length > 0 && (
              <>
                <div css={Css.f20.fw4.add("letterSpacing", ".48px").lh("32px").$}>In Progress tasks:</div>
                <ol>
                  {inProgressTasks.map((task) => (
                    <LinkButton key={task.id} to={taskRoute(projectId, homeownerNote.id, task.id)} underline="initial">
                      <li css={Css.f16.fw3.lh("24px").gray700.$} key={task.id}>
                        {task.name}
                      </li>
                    </LinkButton>
                  ))}
                </ol>
              </>
            )}
            {upcomingTasks.length > 0 && (
              <>
                <div css={Css.f20.fw4.add("letterSpacing", ".48px").lh("32px").$}>Up Next:</div>
                <ol>
                  {upcomingTasks.map((task) => (
                    <LinkButton key={task.id} to={taskRoute(projectId, homeownerNote.id, task.id)} underline="initial">
                      <li css={Css.f16.fw3.lh("24px").gray700.$} key={task.id}>
                        {task.name}
                      </li>
                    </LinkButton>
                  ))}
                </ol>
              </>
            )}
          </div>
          <div css={Css.df.w25.fdc.ifXs.dn.$}>
            <div css={Css.f12.pb3.fw4.add("letterSpacing", ".48px").$}>MILESTONES</div>
            {homeownerNote.schedulePhases.map((schedulePhase) => {
              const schedulePhaseInfo = getSchedulePhaseInfo(schedulePhase.name);
              return (
                <Link
                  key={schedulePhase.id}
                  css={Css.f18.fw5.lh("28px").pt1.add("letterSpacing", ".36px").gray800.$}
                  to={{
                    pathname: milestoneRoute(projectId, schedulePhase.id),
                    state: { from: "homeownerNote", homeownerNoteId: homeownerNote.id },
                  }}
                >
                  {schedulePhaseInfo.replacementTitle || schedulePhase.name}
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </PageContent>
  );
}
