import { Menu, MenuItem } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { Css } from "src/Css";
import {
  HomeownerInboxItemsFragment,
  InboxItemStatus,
  useAllInboxItemStatusMutation,
  useInboxItemStatusMutation,
} from "src/generated/graphql-types";
import { inboxRoute, ProjectRouteProps } from "src/routes";
import { getPath, Notification } from "src/views/Overview/components/UpdatesFeed";

interface NotificationMenuProps {
  anchorEl: HTMLElement | null;
  setIsMenuOpen: Function;
  isMenuOpen: boolean;
  allItems: HomeownerInboxItemsFragment[] | undefined;
  displayedItems: HomeownerInboxItemsFragment[] | undefined;
  setDisplayedItems: Function;
}

export function NotificationMenu({
  anchorEl,
  setIsMenuOpen,
  isMenuOpen,
  allItems,
  displayedItems,
  setDisplayedItems,
}: NotificationMenuProps) {
  const { projectId } = useParams<ProjectRouteProps>();
  let history = useHistory();

  const isEachItemDisplayed = allItems?.length === displayedItems?.length;
  const isEachItemRead = allItems?.every((item) => item.status === InboxItemStatus.Read) || false;

  const [markAllItemsRead] = useAllInboxItemStatusMutation({ refetchQueries: ["HomeownerProjectUpdates"] });
  const [markItemRead] = useInboxItemStatusMutation({ refetchQueries: ["HomeownerProjectUpdates"] });

  async function markAllRead() {
    await markAllItemsRead({
      variables: {
        projectId,
        status: InboxItemStatus.Read,
      },
    }).catch(console.error);
  }

  async function handleInboxItemClick(item: HomeownerInboxItemsFragment) {
    setIsMenuOpen(false);
    const path = getPath(item.projectUpdate, projectId);
    history.push(path);

    await markItemRead({
      variables: {
        inboxItemId: item.id,
        status: InboxItemStatus.Read,
      },
    }).catch(console.error);
  }

  function handleViewAllClick() {
    setIsMenuOpen(false);
    history.push(inboxRoute(projectId));
  }

  return (
    <Menu
      {...{ anchorEl }}
      css={Css.p0.$}
      getContentAnchorEl={null}
      id="notification-menu"
      keepMounted
      onClose={() => setIsMenuOpen(false)}
      open={isMenuOpen}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        style: Css.shadowDark.br3.wPx(366).$,
      }}
    >
      <MenuItem
        css={[
          {
            "&:hover": Css.if(isEachItemRead).bgLightTransparentGray.else.bgTransparentGray.$,
            ...(Css.df.f12.h("44px").jcfe.pr3.ttu.if(isEachItemRead).gray400.bgLightTransparentGray.else.black.bgWhite
              .$ as {}),
          },
          { cursor: isEachItemRead ? "unset" : "pointer" },
        ]}
        key="mark-all-read"
        selected={false}
        onClick={markAllRead}
      >
        <div css={Css.pl1.$}>Mark All Read</div>
      </MenuItem>
      {/* MenuItem height = 67, max # items to display = 5 */}
      <div css={{ height: 67 * 5, overflowY: "auto" }}>
        {displayedItems?.map((item) => (
          <Notification key={item.id} inboxItem={item} onClick={() => handleInboxItemClick(item)} />
        ))}
      </div>
      {/* todo link to view all */}
      <MenuItem
        css={[
          {
            "&:hover": Css.bgTransparentGray.$,
            ...(Css.f12.h("53px").pl3.ttu.black.bgWhite.$ as {}),
          },
        ]}
        data-testid="viewAll"
        key="view-all"
        onClick={handleViewAllClick}
        selected={false}
      >
        <div css={Css.pl1.$}>View All</div>
      </MenuItem>
    </Menu>
  );
}
