import { Typography } from "src/components/Typography";
import { Palette } from "src/Css";
import { HomeownerPalette } from "src/theme/palettes";

export function NotStartedBadge() {
  return (
    <div css={[badgeStyles.notStarted, badgeStyles.badge]}>
      <Typography size="f14" color={HomeownerPalette.White}>
        Not Started
      </Typography>
    </div>
  );
}

export function InProgressBadge() {
  return (
    <div css={[badgeStyles.inProgress, badgeStyles.badge]}>
      <Typography size="f14" color={HomeownerPalette.White}>
        In Progress
      </Typography>
    </div>
  );
}

export function CompletedBadge() {
  return (
    <div css={[badgeStyles.completed, badgeStyles.badge]}>
      <Typography size="f14" color={HomeownerPalette.White}>
        Completed
      </Typography>
    </div>
  );
}

const badgeStyles = {
  badge: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "24px",
    width: "96px",
    color: "#fff",
    borderRadius: 3,
  },
  notStarted: {
    backgroundColor: Palette.Gray400,
  },
  completed: {
    backgroundColor: HomeownerPalette.BrightGreen,
  },
  inProgress: {
    backgroundColor: HomeownerPalette.BlueBright,
  },
};
