import { useMediaQuery } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import { IconButton } from "src/components";
import { Icon, List, Moodboard } from "src/components/Icons";
import { Tabs } from "src/components/Tabs";
import { Breakpoints, Css, Palette, px } from "src/Css";
import { updateSwiper as resizeItemSelectionSwiper } from "src/views/Selections/SelectionView";
import { ToolbarList, ToolbarMoodboard } from "./";

type Tab = undefined | "moodboard" | "list";

export interface ToolbarProps {
  // Toolbar is closed when undefined
  initialSelectedTab?: Tab;
}

interface ToolbarTab {
  name: string;
  icon: (info: { color: string }) => React.ReactElement;
  content: React.ReactNode;
}

/**
 * Toolbar component shown on the right side of the design studio
 * Note: This component is not controlled
 */
export function Toolbar(props: ToolbarProps) {
  const { initialSelectedTab } = props;
  const storageKey = "toolbarTab";
  const [selectedTab, setSelectedTab] = useState<Tab>(
    initialSelectedTab || ((sessionStorage.getItem(storageKey) as Tab) ?? undefined),
  );
  // Toolbar is always open for large screen widths
  const isAlwaysOpen = useMediaQuery(Breakpoints.lgAndUp);

  const tabs = useMemo(
    () => [
      {
        name: "moodboard",
        // TODO: Use button element for tabs https://github.com/homebound-team/homeowner-frontend/pull/345/files#r482082327
        icon: ({ color }: { color: string }) => <Moodboard xss={Css.cursorPointer.$} color={color} />,
        content: <ToolbarMoodboard />,
      },
      {
        name: "list",
        icon: ({ color }: { color: string }) => <List xss={Css.cursorPointer.$} color={color} />,
        content: <ToolbarList />,
      },
    ],
    [],
  );

  // Resize the SelectionView Swiper component each time selectedTab changes
  useEffect(() => resizeItemSelectionSwiper(), [selectedTab]);
  // Opens toolbar on resize to large screen width to selected tab or moodboard
  useEffect(() => {
    isAlwaysOpen && !selectedTab && setTab("moodboard");
  }, [isAlwaysOpen, selectedTab]);

  function setTab(tab: Tab) {
    if (tab) sessionStorage.setItem("toolbarTab", tab);
    else sessionStorage.removeItem("toolbarTab");
    setSelectedTab(tab);
  }

  return selectedTab ? (
    <OpenToolbar {...{ selectedTab, tabs }} onTabSelect={setTab} />
  ) : (
    <ClosedToolbar {...{ tabs }} onTabSelect={setTab} />
  );
}

interface OpenToolbarProps extends ClosedToolbarProps {
  selectedTab: Tab;
}

/** Open version of the Toolbar */
function OpenToolbar({ selectedTab, tabs, onTabSelect }: OpenToolbarProps) {
  const isAlwaysOpen = useMediaQuery(Breakpoints.lgAndUp);

  // prettier-ignore
  function handleClose() { onTabSelect(undefined); }
  // prettier-ignore
  function handleTabClick(tab: string) { onTabSelect(tab as Tab); }

  return (
    <div css={Css.bgTaupe.vh100.df.fdc.w25.mw(px(200)).maxw(px(400)).fs0.$} data-testid="toolbarOpen">
      <div
        css={{
          ...Css.asfe.pr1.pt1.h(px(40)).$,
          button: Css.mh(px(30)).mw(px(30)).bgTransparentGray.important.if(isAlwaysOpen).dn.$,
          svg: Css.h(px(20)).w(px(20)).$,
        }}
      >
        <IconButton onClick={handleClose} testid="toolbarButtonClose">
          <Icon icon="close" />
        </IconButton>
      </div>
      {/* Header */}
      <Tabs
        spaceEvenly
        tabs={tabs.map(({ name, icon: component }) => ({
          name,
          component,
        }))}
        selectedTab={selectedTab!}
        onClick={handleTabClick}
        xss={Css.px2.bGray400.$}
      />
      {/* Active Component */}
      <div className="darkScrollbar" css={Css.pt2.fg1.add("overflow", "auto").$}>
        {tabs.find(({ name }) => name === selectedTab)?.content}
      </div>
    </div>
  );
}

interface ClosedToolbarProps {
  tabs: ToolbarTab[];
  onTabSelect: (tab: Tab) => void;
}

/** Close version of the Toolbar */
function ClosedToolbar({ tabs, onTabSelect }: ClosedToolbarProps) {
  // prettier-ignore
  function handleTabClick(tab: string) { onTabSelect(tab as Tab); }

  return (
    <div css={Css.bgTaupe.w(px(76)).vh100.df.fdc.aic.$} data-testid="toolbarClose">
      {tabs.map(({ name, icon: Component }) => {
        return (
          <div css={Css.p3.$} key={name} onClick={() => handleTabClick(name)} data-testid={`toolbarTab${name}`}>
            <Component color={Palette.Black} />
          </div>
        );
      })}
    </div>
  );
}
