import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Icon } from "src/components/Icons";
import { Css } from "src/Css";
import { IconButton } from "./Button";

type CarouselProps = {
  /* Carousel for uniform width items, Currently carousel pagination is based of the
  width of the first item */
  items: React.ReactNode[];
};

/**
 * @param inc - number of item lengths to scroll; Defaults to 1
 */
export interface CarouselRef {
  forward: (inc?: number) => void;
  backward: (inc?: number) => void;
}

// FIXME: Resizing the window messes up the scroll calculations <- legacy issue from personalization-fe
// TODO: Needs that giant right inset shadow but its a background so not sure how best to do it
export const ImageCarousel = forwardRef((props: CarouselProps, ref) => {
  const { items } = props;
  // item container ref
  const containerRef = useRef<HTMLDivElement>(null);
  // itemref determines the width of the first item to set how far to scroll
  const firstItemRef = useRef<HTMLDivElement>(null);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const parentControlled = ref !== null;
  // const tid = useTestIds(props, "carousel");

  // Handlers
  // Direction tells us to scroll right or left
  const scrollX = (direction: "left" | "right", inc = 1) => {
    if (!containerRef.current && !firstItemRef.current) return;
    setScrollLeft((old) => {
      return old + (firstItemRef.current?.clientWidth || 0) * (direction === "left" ? -1 : 1) * inc;
    });
  };

  useEffect(() => {
    // Update carousel left most side of scroll position
    if (containerRef.current) {
      containerRef.current.scrollLeft = scrollLeft;
    }
    // handle left scrolling button
    setCanScrollLeft(scrollLeft > 0);

    // handle right scrolling button
    // total width including all that is only visible if you scroll
    const scrollWidth = containerRef.current?.scrollWidth;
    // is the inner width including padding but excluding borders
    const visibleWidth = containerRef.current?.clientWidth;
    if (scrollWidth && visibleWidth) {
      setCanScrollRight(scrollLeft + visibleWidth < scrollWidth);
    }
  }, [parentControlled, scrollLeft]);

  useImperativeHandle(ref, () => ({
    forward: (inc: number) => {
      scrollX("right", inc);
    },
    backward: (inc: number) => {
      scrollX("left", inc);
    },
  }));

  return (
    <div css={Css.relative.if(parentControlled).w100.overflowHidden.$}>
      {/* Always overlaps with item and only shows when there is room to scroll */}
      {!parentControlled && canScrollLeft && (
        <div css={Css.absolute.top("50%").add("transform", "translateY(-50%)").left0.ml7.$}>
          <IconButton className="secondary" onClick={() => scrollX("left")}>
            <Icon icon="chevronLeft" />
          </IconButton>
        </div>
      )}

      {!parentControlled && canScrollRight && (
        <div css={Css.absolute.top("50%").add("transform", "translateY(-50%)").right0.mr7.$}>
          <IconButton className="secondary" onClick={() => scrollX("right")}>
            <Icon icon="chevronRight" />
          </IconButton>
        </div>
      )}
      {/* item container */}
      <div
        id="carousel-item-container"
        ref={containerRef}
        css={
          Css.df.gap2.add("scrollSnapType", "x mandatory").add("scrollBehavior", "smooth").if(!parentControlled)
            .overflowHidden.$
        }
      >
        {items.map((item, index) => (
          <span ref={index === 0 ? firstItemRef : undefined} css={Css.add("scrollSnapAlign", "start").$} key={index}>
            {item}
          </span>
        ))}
      </div>
    </div>
  );
});

type CarouselControlsProps = {
  carouselRef: React.RefObject<CarouselRef>;
  /** Image Gallery length used to calculate fill % of progress bar. Single count arrays will not render */
  imageCount: number;
  buttonPosition?: "left" | "right";
};

// Carousel Control with progress bar. This is a separate component because it needs to be rendered outside the carousel
export const CarouselControls = (props: CarouselControlsProps) => {
  const { carouselRef, imageCount, buttonPosition = "right" } = props;
  const { forward = () => undefined, backward = () => undefined } = carouselRef.current ?? {};
  const [viewIndex, setViewIndex] = useState(0);
  // const tid = useTestIds(props, "carousel-controls");

  if (imageCount <= 1) return null;

  return (
    <div css={Css.df.gap2.aic.jcc.add("flexWrap", "wrap").if(buttonPosition === "left").fdrr.$}>
      <div id="bar-container" css={Css.wPx(325).hPx(1).bgGray700.aic.jcc.relative.z0.$}>
        <div
          id="carousel-progress-bar"
          css={
            Css.add("transition", "0.75s")
              .w(`${((viewIndex + 1) / imageCount) * 100}%`)
              .hPx(2).bgGray900.df.absolute.z1.left0.bottom0.$
          }
        ></div>
      </div>
      <div id="carousel-controls" css={Css.df.gap2.$}>
        <IconButton
          color="secondary"
          disabled={viewIndex === 0}
          onClick={() => {
            setViewIndex(viewIndex - 1);
            backward();
          }}
        >
          <Icon icon="chevronLeft" />
        </IconButton>
        <IconButton
          color="secondary"
          disabled={viewIndex === imageCount - 1}
          onClick={() => {
            setViewIndex(viewIndex + 1);
            forward();
          }}
        >
          <Icon icon="chevronRight" />
        </IconButton>
      </div>
    </div>
  );
};
