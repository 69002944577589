import { useState } from "react";
import { IconButton } from "src/components";
import { Icon } from "src/components/Icons";
import { Css, Palette } from "src/Css";

interface PageControlsProps {
  changePage: (x: number) => void;
  currentPage: number;
  numPages: number;
}

/**
 * Displays x (current page) of y (total number of pages) with prev/next buttons that update the current page.
 */
export function PageControls({ changePage, currentPage, numPages }: PageControlsProps) {
  const [pageNum, setPageNum] = useState(currentPage);

  if (numPages < 2) return null;

  function changeControls(offset: number) {
    changePage(pageNum + offset);
    setPageNum((prevPageNumber) => prevPageNumber + offset);
  }

  return (
    <div css={Css.df.z1.aic.bgWhite.jcsa.absolute.left("50%").add("transform", "translateX(-50%)").top("46px").$}>
      <IconButton disabled={pageNum === 1} onClick={() => changeControls(-1)}>
        {<Icon icon="chevronLeft" color={pageNum === 1 ? Palette.Gray400 : Palette.Black} />}
      </IconButton>
      <div css={Css.m1.black.nowrap.$}>
        {pageNum} of {numPages}
      </div>
      <IconButton disabled={pageNum === numPages} onClick={() => changeControls(1)}>
        {<Icon icon="chevronRight" color={pageNum === numPages ? Palette.Gray400 : Palette.Black} />}
      </IconButton>
    </div>
  );
}
