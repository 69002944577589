import { Icon } from "src/components/Icons";
import { Css } from "src/Css";
import { AssetInfoFragment } from "src/generated/graphql-types";
import { openUrl } from "src/utils";
import { AssetPreview } from "src/utils/AssetPreview";

export type ListCardProps = {
  name: string;
  createdAt: string;
  asset: AssetInfoFragment;
  onClick: () => void;
  stackLabels: boolean;
};

export function ListCard(props: ListCardProps) {
  const { name, createdAt, asset, onClick, stackLabels } = props;
  //  todo if preview url is undefined, show a placeholder image
  return (
    <div
      onClick={onClick}
      css={
        Css.hPx(64)
          .cursorPointer.overflowHidden.p1.w100.br3.bgWhitePure.df.boxShadow(
            "0px 2px 16px 0px rgba(53, 53, 53, 0.03), 0px 4px 8px 0px rgba(53, 53, 53, 0.08)",
          )
          .aic.jcse.if(stackLabels)
          .w("calc(100% - 267px)").$
      }
    >
      <div css={Css.df.gap4.w("calc(100% - 32px)").h100.overflowHidden.$}>
        <div css={Css.wPx(68).hPx(48).relative.overflowHidden.mwPx(68).$}>
          <AssetPreview asset={asset} dimensions={{ width: 68, height: 100 }} />
        </div>
        <div css={Css.df.aic.w("calc(100% - 68px)").pr4.if(stackLabels).fdc.aifs.else.gap2.jcfs.$}>
          <div css={Css.truncate.f16.lh("24px").add("letterSpacing", "-0.32px").fw5.if(stackLabels).w("inherit").$}>
            {name}
          </div>
          {/* todo reformat date */}
          <div css={Css.f12.lh("20px").fw3.wPx(130).gray400.$}>Added {createdAt}</div>
        </div>
      </div>

      <div css={Css.df.wPx(32).hPx(32).bgTaupe.br100.relative.asc.$}>
        <div
          onClick={(e) => {
            openUrl(asset.attachmentUrl);
            e.stopPropagation();
          }}
          css={Css.absolute.top("25%").left("31%").$}
        >
          <Icon icon="download" />
        </div>
      </div>
    </div>
  );
}
