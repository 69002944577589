import { Css, increment, px } from "src/Css";
import { PriceDetails, PriceDetailsProps } from "./PriceDetails";

export interface ProductDetailsProps extends PriceDetailsProps {
  brand: string;
  finish?: string;
  name: string;
}

export function ProductDetails(props: ProductDetailsProps) {
  const {
    brand,
    finish,
    msrpInCents,
    name,
    priceDeltaInCents,
    pricingType,
    selectionPricingMode,
    unitOfMeasure,
    unitPriceInCents,
  } = props;

  return (
    <div css={Css.df.fdc.$}>
      <div css={Css.mb(px(increment(1) / 2)).t14.$}>{finish ? `${name} - ${finish}` : name}</div>
      <div css={Css.mb(px(increment(1) / 2)).t12.gray600.$}>{brand}</div>
      <PriceDetails
        msrpInCents={msrpInCents}
        priceDeltaInCents={priceDeltaInCents}
        pricingType={pricingType}
        selectionPricingMode={selectionPricingMode}
        unitOfMeasure={unitOfMeasure}
        unitPriceInCents={unitPriceInCents}
      />
    </div>
  );
}
