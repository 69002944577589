import { useParams } from "react-router-dom";
import { GridColumn, GridDataRow, GridRowStyles, GridTable } from "src/components";
import { Css } from "src/Css";
import { CostDivision, ProjectItemCostCodeFragment } from "src/generated/graphql-types";
import { ProjectRouteProps } from "src/routes";
import { centsToDollars } from "src/utils";

interface FinanceDetailsTableProps {
  groups: DetailTableGroup[];
}

type FinanceTableCostDivision = Pick<CostDivision, "id" | "name" | "number">;

export interface DetailTableGroup {
  division: FinanceTableCostDivision;
  costCodes: DetailTableCostCode[];
}

interface DetailTableCostCode {
  costCode: ProjectItemCostCodeFragment;
  contractPriceInCents: number;
  paidToDateInCents: number;
}

export function FinanceDetailsTable({ groups }: FinanceDetailsTableProps) {
  const { projectId } = useParams<ProjectRouteProps>();
  return (
    <GridTable
      stickyHeader
      {...{ columns }}
      rows={createRows(groups)}
      rowStyles={createRowStyles(projectId)}
      fallbackMessage="There are no budget details available yet."
    />
  );
}

interface LineItemData {
  contractPriceInCents: number;
  paidToDateInCents: number;
}

type HeaderRow = { kind: "header" };
type CostCodeRow = { kind: "costCode"; costCode: ProjectItemCostCodeFragment; data: LineItemData };
type DivisionRow = { kind: "division"; division: FinanceTableCostDivision };
type Row = HeaderRow | CostCodeRow | DivisionRow;

const columns: GridColumn<Row>[] = [
  {
    align: "left",
    header: "Cost Code",
    division: ({ division }) => division.number,
    costCode: ({ costCode }) => costCode.number,
  },
  {
    align: "left",
    header: "Description",
    division: ({ division }) => division.name,
    costCode: ({ costCode }) => costCode.name,
  },
  {
    align: "right",
    header: "Contract price",
    division: "",
    costCode: ({ data }) => ({ content: centsToDollars(data.contractPriceInCents, 2) }),
  },
  {
    align: "right",
    header: "Paid to date",
    division: "",
    costCode: ({ data }) => ({ content: centsToDollars(data.paidToDateInCents, 2) }),
  },
  {
    align: "right",
    header: "Remaining",
    division: "",
    costCode: ({ data }) => ({
      content: centsToDollars(data.contractPriceInCents - data.paidToDateInCents, 2),
    }),
  },
];

function createRows(groups: DetailTableGroup[]): GridDataRow<Row>[] {
  return [
    { kind: "header" as const, id: "header" },
    ...groups.flatMap((group: DetailTableGroup) => {
      const { division, costCodes } = group;
      return [
        { kind: "division" as const, id: division.id, division },
        ...costCodes.map(({ costCode, contractPriceInCents, paidToDateInCents }: DetailTableCostCode) => {
          return {
            kind: "costCode" as const,
            id: costCode.id,
            costCode,
            data: {
              contractPriceInCents,
              paidToDateInCents,
            },
          };
        }),
      ];
    }),
  ];
}

function createRowStyles(projectId: string): GridRowStyles<Row> {
  return {
    header: { cellCss: Css.f12.ttu.gray600.bb.bGray400.$ },
    division: { cellCss: Css.t14.bgLightTransparentGray.$ },
    costCode: {
      cellCss: Css.t12.bb.bTaupe.bw2.$,
      // rowLink: ({ activityId }) => activityRoute(projectId, activityId),??
    },
  };
}
