import { CSSProperties, ReactNode, RefObject, useRef, useState } from "react";
import { AriaOverlayProps, DismissButton, useOverlay } from "react-aria";
import { Icon } from "src/components";
import { Css, Palette } from "src/Css";
import { useTestIds } from "src/hooks/useTestIds";

type SelectFieldProps = {
  label?: ReactNode;
  placeholder?: string;
  options: string[];
  selectedOptions: string[];
  onChange: (selectedOptions: string[]) => void;
  /** If true, the field cannot be set to empty after being chosen. Defaults to true */
  allowDeselect?: boolean;
  triggerOverrides?: CSSProperties;
};

export function SelectField(props: SelectFieldProps) {
  const {
    label,
    selectedOptions,
    onChange,
    options,
    placeholder = null,
    allowDeselect = true,
    triggerOverrides,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [tid, labelId, buttonId, listBoxId] = useTestIds("selectField", ["label", "button", "listBox"]);

  const handleOnChange = (item: string) => {
    if (allowDeselect && selectedOptions.includes(item)) {
      onChange([]);
    } else {
      onChange([item]);
    }

    setIsOpen(false);
  };

  return (
    <div css={Css.df.fdc.relative.w100.$} {...tid}>
      {label && (
        <label css={Css.f14.gray800.fw7.lh("16px").add("letterSpacing", "0.32px").mbPx(12).$} {...labelId}>
          {label}
        </label>
      )}

      {/* Trigger */}
      <button
        css={{
          ...Css.px2.pyPx(15).relative.bgWhitePure.ba.bGray600.br4.if(isOpen).color("rgba(53, 53, 53, 0.65)").$,
          ...triggerOverrides,
        }}
        onClick={() => setIsOpen(!isOpen)}
        {...buttonId}
      >
        <div css={Css.f14.add("letterSpacing", "0.16px").fw4.tl.if(!selectedOptions.length).gray800.fw3.$}>
          {!selectedOptions.length && (placeholder || "\u00A0")}
          {/* "\u00A0" is the same as &nbsp; maintains the line height if a placeholder isn't provided */}
          {!!selectedOptions.length && selectedOptions[0]}
        </div>

        <div aria-hidden={true} css={Css.absolute.right1.top("calc(50% + 2px)").add("transform", "translateY(-50%)").$}>
          {isOpen ? (
            <Icon icon="chevronUp" color={Palette.Gray800} />
          ) : (
            <Icon icon="chevronDown" color={Palette.Gray800} />
          )}
        </div>
      </button>

      {isOpen && (
        // hack to prevent setIsOpen from being called twice
        <Popover isOpen={isOpen} onClose={() => setTimeout(() => setIsOpen(false), 0)}>
          <ListBox onChange={handleOnChange} options={options} selectedOptions={selectedOptions} {...listBoxId} />
        </Popover>
      )}
    </div>
  );
}

type PopoverProps = AriaOverlayProps & {
  popoverRef?: RefObject<HTMLDivElement>;
  children: ReactNode;
};

function Popover(props: PopoverProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { popoverRef = ref, isOpen, onClose, children } = props;
  // Handle interacting outside the dialog or pressing the `Escape` key to close the modal.
  const { overlayProps } = useOverlay({ isOpen, onClose, shouldCloseOnBlur: true, isDismissable: true }, popoverRef);

  return (
    <>
      <div {...overlayProps} ref={ref} css={Css.absolute.z1.br4.top("100%").w100.bgWhite.$}>
        {children}
      </div>
      <DismissButton onDismiss={() => onClose} />
    </>
  );
}

type ListBoxProps = {
  onChange: (selectedOptions: string) => void;
  options: string[];
  selectedOptions: string[];
};

function ListBox(props: ListBoxProps) {
  const { onChange, options, selectedOptions } = props;
  const [tid, itemId] = useTestIds("listBox", ["item"]);

  return (
    <ul css={Css.maxhPx(300).overflowAuto.listReset.m0.boxShadow("0px 2px 6px #D5D5D5").$} {...tid}>
      {options.map((item, index) => {
        const isString = typeof item === "string";
        const selected = selectedOptions.includes(item);
        return (
          <li
            css={{
              ...Css.listReset.f14.outline0.cursorPointer.relative.bgWhitePure.addIn(
                "::after",
                Css.if(index !== options.length - 1).add({
                  content: '""',
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  bottom: "0",
                  height: "1px",
                  width: "100%",
                  borderBottom: `1px solid ${Palette.Gray200}`,
                }).$,
              ).onHover.bgGray100.$,
            }}
            key={item}
            {...itemId}
          >
            <button
              css={
                Css.db.p2.w100.tl
                  .add("backgroundColor", "transparent")
                  .bn.f14.add("letterSpacing", "0.16px")
                  .lh("18px")
                  .fw3.gray700.if(selected).gray800.fw7.$
              }
              onClick={() => onChange(item)}
            >
              {item}
            </button>
          </li>
        );
      })}
    </ul>
  );
}
