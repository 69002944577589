import { Css } from "src/Css";

export interface TabsContentProps {
  selectedTab: string;
  contents: {
    [key: string]: React.ReactElement;
  };
}

export function TabsContent(props: TabsContentProps) {
  const { selectedTab, contents } = props;
  return (
    <div css={{ "> div[hidden]": Css.dn.$ }}>
      {Object.keys(contents).map((key) => (
        <div
          // id matches tab aria-controls
          id={key}
          key={key}
          role="tabpanel"
          tabIndex={0}
          // Matched the tab id to which its visibility is being controlled
          aria-labelledby={key}
          hidden={selectedTab === key ? undefined : true}
        >
          {contents[key]}
        </div>
      ))}
    </div>
  );
}
