import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import { Card, IconButton } from "src/components";
import { Css, px } from "src/Css";
import { useWindowSize } from "src/hooks";
import { DocumentInfo } from "src/views";
import { DocumentMenu } from "src/views/Documents/components";

interface DocumentPreviewCardProps {
  document: DocumentInfo;
  menuOpen?: boolean;
  onModalOpen: Function;
}

export function DocumentPreviewCard({ document, menuOpen = false, onModalOpen }: DocumentPreviewCardProps) {
  const { title, url } = document;
  const [isMenuOpen, setIsMenuOpen] = useState(menuOpen);
  const [anchorEl, setAnchorEl] = useState(null);
  const iconRef = useRef(null);

  const previewDivRef = useRef<HTMLDivElement>(null);
  const [previewDivWidth, setPreviewDivWidth] = useState(0);

  useEffect(() => {
    setAnchorEl(iconRef.current);
  }, [iconRef]);

  const windowWidth = useWindowSize().width;

  useEffect(() => {
    setPDFWidth();
  }, [windowWidth]);

  function handleButtonClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setIsMenuOpen(true);
  }

  function setPDFWidth() {
    const width = previewDivRef?.current?.offsetWidth || 400;
    setPreviewDivWidth(width);
  }

  return (
    <>
      <Card onClick={() => onModalOpen(document)} xss={Css.w100.p2.fdc.$}>
        <div css={Css.df.w100.mb1.jcsb.aic.$}>
          <h2 css={Css.t14.$}>{title}</h2>
          <IconButton ref={iconRef} onClick={handleButtonClick}>
            <MoreVertIcon />
          </IconButton>
        </div>
        <div ref={previewDivRef} css={Css.w100.h(px(300)).overflowHidden.$}>
          <Document file={url} renderMode="svg" onLoadSuccess={setPDFWidth}>
            <Page loading={`Loading ${title}...`} pageNumber={1} width={previewDivWidth} renderMode="canvas" />
          </Document>
        </div>
      </Card>
      {isMenuOpen && anchorEl && (
        <DocumentMenu
          anchorEl={anchorEl!}
          {...{ closeMenu: () => setIsMenuOpen(false), isMenuOpen, onModalOpen, url }}
        />
      )}
    </>
  );
}
