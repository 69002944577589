import { currentUser, HomeboundUser } from "@homebound/auth-components";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Css } from "src/Css";
import { addGATracking, identifyHeap } from "./context";
import { useCurrentUserQuery } from "./generated/graphql-types";
import { useFullStoryIdentify } from "./hooks";
import { useDatadogRumIdentify } from "./hooks/useDatadogRumIdentify";
import Routes from "./routes/Routes";

export function App() {
  // FIXME: The usage of useHistory in an application wrapper is causing
  // unnecessary component re-renders on URL changes. Recommended fix is to
  // move all hook usages outside this components lifecycle by creating a
  // useAnalytics and useIdentity hook. https://homebound.atlassian.net/browse/COAST-653
  const history = useHistory();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<HomeboundUser>();
  const signInPath = "/login";

  const { data } = useCurrentUserQuery();
  useDatadogRumIdentify(data);
  useFullStoryIdentify(data);

  useEffect(() => {
    // Called on each history change due to user could change
    (async function onLoad() {
      const { data: user } = await currentUser();

      identifyHeap(user);

      addGATracking(user, history);

      setUser(user);
      setIsAuthenticated(user !== undefined);
      setIsAuthenticating(false);
    })();
  }, [history]);

  // Ignoring history changes
  const RoutesComponent = useCallback(
    () => (
      <div css={Css.bgWhite.$}>
        <Routes authProps={{ isAuthenticated, setIsAuthenticated, user, setUser, signInPath }} />
      </div>
    ),
    [isAuthenticated, user],
  );

  if (isAuthenticating) return <div />;
  return <RoutesComponent />;
}
