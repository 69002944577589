import { useParams } from "react-router-dom";
import { PageLayout } from "src/components/Nav";
import { getExploreUrl } from "src/context";
import { Css } from "src/Css";
import { SearchBy } from "src/generated/graphql-types";
import { useHomeownerProjectSearchQuery } from "src/hooks";
import { ProjectRouteProps } from "src/routes";

// We cant throwout the old selections screens until we've completed all the new HOP updates
export function V2Selections() {
  const { projectId } = useParams<ProjectRouteProps>();
  const { data, loading } = useHomeownerProjectSearchQuery(SearchBy.Project, projectId);

  return (
    <PageLayout old>
      <div css={Css.overflowHidden.h("calc(100vh - 72px)").ifXs.h("calc(100vh - 64px)").$}>
        {!loading && (
          <iframe
            css={Css.h100.w100.add("border", "none").$}
            title={"Homeowner Selections"}
            src={getExploreUrl(data?.homeownerProject?.checkoutCustomerConfigId)}
          />
        )}
      </div>
    </PageLayout>
  );
}
