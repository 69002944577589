import { FieldFunctionOptions } from "@apollo/client";

export const assetTypePolicy = {
  Asset: {
    fields: {
      downloadUrl: {
        merge: mergeDownloadUrl,
      },
    },
  },
};

/**
 * Ensures that downloadUrl fields do not cause unnecessary rendering.
 *
 * Returns the incoming URL when:
 * 1) The existing URL is undefined
 * 2) The s3 key has changed
 * 3) The existing URL is going to expire in the same day
 *
 * NOTE: Exported for testing
 *
 * @param existing Currently cached downloadUrl or undefined
 * @param incoming Most recently received downloadUrl
 * @param now for testing purposes
 */
export function mergeDownloadUrl(
  existing: string | undefined,
  incoming: string | undefined,
  options: FieldFunctionOptions = undefined as any,
  now: Date = new Date(),
) {
  if (!existing || !incoming) {
    return incoming;
  }
  const { s3Key: existingS3Key, expires: existingExpires } = existing.match(downloadUrlRegex)?.groups ?? {};
  const incomingS3Key = incoming.match(downloadUrlRegex)?.groups?.s3Key;

  if (existingS3Key === incomingS3Key && existingExpires) {
    // S3 key hasn't changed, check the expirations
    const existingExpiresDate = new Date(parseInt(existingExpires) * 1000);
    if (now < existingExpiresDate || now.getUTCDate() < existingExpiresDate.getUTCDate()) {
      // The existing URL is already expired or expires today, take the incoming
      return existing;
    }
  }
  return incoming;
}

// Exported for testing
export const downloadUrlRegex = /.*\/(?<s3Key>assets\/[0-9a-z-.]+)\?.*Expires=(?<expires>[0-9]+).*/i;
