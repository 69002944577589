import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";
import { CurrentUserQuery } from "src/generated/graphql-types";

// Identify user with Datadog Real User Monitoring
// https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#identify-user-sessions
export function useDatadogRumIdentify(user?: CurrentUserQuery) {
  const { id, name, email } = user?.currentUser || {};

  useEffect(() => {
    if (id) {
      datadogRum.setUser({
        id,
        name,
        email,
      });
    } else {
      datadogRum.removeUser();
    }
  }, [id, name, email]);
}
