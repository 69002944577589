import { Card, Icon } from "src/components";
import { Css } from "src/Css";

type OverviewCardProps = {
  icon: keyof typeof cardIcons;
  title: string;
  subTitle: string;
};

export function OverviewCard({ icon, title, subTitle }: OverviewCardProps) {
  return (
    <Card xss={Css.pPx(18).df.fdc.br8.w25.mwPx(104).hPx(134).bgWhitePure.$} isClickable={false}>
      <div css={Css.bgTaupe.br100.w4.h4.df.aic.jcc.$}>{cardIcons[icon]}</div>
      <div css={Css.mtPx(12).f14.lh("20px").fw7.add("letterSpacing", "unset").$}>{title}</div>
      <div css={Css.f12.pt1.lh("16px").fw5.add("letterSpacing", "unset").gray700.$}>{subTitle}</div>
    </Card>
  );
}

const cardIcons = {
  homeThin: <Icon icon="homeThin" width="16" height="16" viewBox="0 0 16 16" />,
  keys: <Icon icon="keys" width="19" height="19" viewBox="0 0 19 19" />,
  clock: <Icon icon="clock" width="16" height="17" viewBox="0 0 16 17" />,
  checkPlus: <Icon icon="checkPlus" width="19" height="18" viewBox="0 0 19 18" />,
};
