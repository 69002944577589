import { Card } from "src/components";
import { Css } from "src/Css";
import { Market, ProjectRole, TeamMemberFragment } from "src/generated/graphql-types";

type YourTeamProps = {
  teamMembers: TeamMemberFragment[] | undefined | null;
  market: Market;
};

export function YourTeam(props: YourTeamProps) {
  const { market, teamMembers } = props;

  if (!market) return null;
  return (
    <div>
      <TeamMemberGrid teamMembers={teamMembers} />
    </div>
  );
}

function TeamMemberGrid({ teamMembers }: { teamMembers: TeamMemberFragment[] | undefined | null }) {
  if (!teamMembers || teamMembers.length === 0) {
    return (
      <div css={Css.my3.$}>
        <p css={Css.t16.gray600.$}>
          We are working on putting together an amazing team of experts for you, please check back soon!
        </p>
      </div>
    );
  }

  return (
    <div
      css={{
        ...Css.dg.pt4.$,
        gridColumnGap: "24px",
        gridRowGap: "16px",
        gridTemplateColumns: "repeat(auto-fit, minmax(260px, 1fr))",
      }}
    >
      {sortTeamMembers(teamMembers).map((tm) => (
        <div key={tm.role.code}>
          <MemberCard teamMember={tm} />
        </div>
      ))}
    </div>
  );
}

function sortTeamMembers(teamMembers: TeamMemberFragment[]): TeamMemberFragment[] {
  const pm = teamMembers.find((tm) => tm.role.code === ProjectRole.ConOpsProjectManager);
  if (!pm) {
    return teamMembers;
  }
  return [pm, ...teamMembers.filter((tm) => tm !== pm)];
}

function MemberCard({ teamMember }: { teamMember: TeamMemberFragment }) {
  const { user, role } = teamMember;
  return (
    <Card xss={Css.py3.px2.df.aic.gap3.hPx(110).bgWhitePure.$} isClickable={false}>
      <img src={user?.avatar} alt={user?.name} css={Css.wPx(60).hPx(60).br100.overflowHidden.$} />
      <div css={Css.df.fdc.maxw("calc(100% - 84px)").$}>
        <div css={Css.t16.fw5.black.add("letterSpacing", "0.32px").lh("24px").$}>{`${user?.name}`}</div>
        <div css={Css.t14.gray500.fw3.ptPx(4).truncate.$}>{role.name}</div>
      </div>
    </Card>
  );
}
