import { Dialog, useMediaQuery } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Icon, IconButton, PageControls, TextButton } from "src/components";
import { Css, px } from "src/Css";
import { muiTheme } from "src/theme";
import { DocumentInfo } from "src/views";
import { SlideTransition } from "./SlideTransition";

// Create React App is not yet compatible with worker-loader, so this line is needed to enable PDF.js worker.
// Instructions: https://github.com/wojtekmaj/react-pdf#enable-pdfjs-worker
pdfjs.GlobalWorkerOptions.workerSrc = `/vendors/pdf.worker.min.js`;

export interface DocumentModalProps {
  isOpen: boolean;
  onClose: () => void;
  document: DocumentInfo;
}

const defaultDocWidth = 900;

export function DocumentModal({ isOpen, onClose, document }: DocumentModalProps) {
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (!isOpen) {
      setNumPages(0);
      setCurrentPage(1);
    }
  }, [isOpen]);

  const isFullScreen = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const { contentType, subTitle, title, url } = document;
  const isImage = contentType?.includes("image/");
  const isPdf = contentType === "application/pdf";

  // content must be an image or pdf
  if (!(isImage || isPdf)) return null;

  return (
    <Dialog
      fullScreen={isFullScreen}
      fullWidth={true}
      open={isOpen}
      onClose={onClose}
      aria-label={document.title}
      TransitionComponent={SlideTransition}
      PaperProps={{
        style: {
          ...Css.br3.shadowNone.m0.top0.absolute.overflowHidden.mt0.mw0.if(!isFullScreen).mt4.mw(px(defaultDocWidth)).$,
        },
      }}
    >
      <div css={Css.overflowYHidden.relative.df.fdc.vh100.$}>
        <header css={Css.py3.px4.df.aic.jcsb.bgWhite.ifXs.pl2.pr0.$}>
          <div css={Css.nowrap.maxw("calc(100% - 62px)").$}>
            <h2 css={Css.t18.overflowHidden.nowrap.add("textOverflow", "ellipsis").ifXs.t16.$}>{title}</h2>
            <p css={Css.t14.gray600.mt(px(4)).overflowHidden.nowrap.add("textOverflow", "ellipsis").$}>{subTitle}</p>
          </div>
          <PageControls
            numPages={numPages}
            changePage={(currentPage) => setCurrentPage(currentPage)}
            currentPage={currentPage}
          />
          <HeaderButtons onClose={onClose} url={url} isFullScreen={isFullScreen} />
        </header>
        {isPdf && (
          <div
            css={
              Css.df.jcc.relative.fg1.overflowYScroll.addIn("canvas", Css.if(isFullScreen).important.w100.h("auto").$).$
            }
          >
            <Document file={url} onLoadSuccess={({ numPages }) => setNumPages(numPages)} css={Css.absolute.$}>
              <Page loading="" pageNumber={currentPage} />
            </Document>
          </div>
        )}
        {isImage && <img src={document.url} alt={document.title} css={Css.objectContain.h100.$} />}
      </div>
    </Dialog>
  );
}

interface HeaderButtonsProps {
  onClose: Function;
  isFullScreen: boolean;
  url: string;
}

function HeaderButtons({ onClose, isFullScreen, url }: HeaderButtonsProps) {
  function DesktopButtons() {
    return (
      <div css={Css.df.aic.$}>
        <TextButton href={url} target="_blank" rel="noopener noreferrer" xss={Css.mr1.$}>
          Download
        </TextButton>
        <IconButton onClick={() => onClose()}>
          <Icon icon="close" />
        </IconButton>
      </div>
    );
  }

  function MobileButtons() {
    return (
      <TextButton onClick={() => onClose()} aria-label="close">
        <Icon icon="close" />
      </TextButton>
    );
  }

  if (isFullScreen) {
    return <MobileButtons />;
  }
  return <DesktopButtons />;
}
