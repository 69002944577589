import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ProjectRole, ProjectTeamMember, SearchBy, useHomeownerProjectLazyQuery } from "src/generated/graphql-types";
import { ProjectRouteProps } from "src/routes";

type ProjectRoles = keyof typeof ProjectRole;

/** Returns an object of team member roles with the associated team member */
export function useTeamMembers(): {
  [R in ProjectRoles]?: ProjectTeamMember;
} {
  const { projectId } = useParams<ProjectRouteProps>();
  const [getTeamMembers, { data }] = useHomeownerProjectLazyQuery({
    variables: {
      id: projectId,
      searchBy: SearchBy.Project,
    },
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    getTeamMembers();
  }, [projectId]); //eslint-disable-line

  const teamMembers = data?.homeownerProject?.teamMembers ?? [];

  return teamMembers.reduce((roles, teamMember) => {
    return {
      ...roles,
      [Object.keys(ProjectRole).find(
        (enumKey) => ProjectRole[enumKey as ProjectRoles] === teamMember.role.code,
      ) as string]: teamMember,
    };
  }, {});
}
