import { Avatar, Divider } from "@material-ui/core";
import { startCase, toLower } from "lodash";
import { useState } from "react";
import { CommentFeed, CommentFeedProps, OutlineButton, Tooltip } from "src/components";
import { Modal, ModalProps } from "src/components/Modal";
import { Tabs, TabsContent } from "src/components/Tabs";
import { Breakpoints, Css, increment, px } from "src/Css";
import { PriceDetails } from "src/views/Selections/SelectionView/components/PriceDetails";
import { ProductDetailsProps } from "../ProductDetails";
import { ProductOverviewProps } from "./ProductOverview";

export interface ItemDetailsModalProps extends ProductDetailsProps, Pick<ModalProps, "open" | "title" | "onClose"> {
  commentOptions?: CommentFeedProps;
  designerAvatar: string | undefined;
  downloads?: React.ReactElement;
  finalized: boolean;
  image: string;
  locked: boolean;
  onSelectionChange?: () => void;
  overview: React.ReactElement | React.FC<ProductOverviewProps>;
  selected: boolean;
  specs?: React.ReactElement;
}

/** Item picture and information modal */
export function ItemDetailsModal(props: ItemDetailsModalProps) {
  const {
    brand,
    commentOptions,
    designerAvatar,
    downloads,
    finalized,
    image,
    locked,
    msrpInCents,
    name,
    onClose,
    onSelectionChange,
    open,
    overview,
    selected,
    specs,
    title,
    unitPriceInCents,
    unitOfMeasure,
    priceDeltaInCents,
    pricingType,
    selectionPricingMode,
  } = props;
  const [selectedTab, setSelectedTab] = useState("Overview");

  // Capitalize title
  const formattedTitle = startCase(toLower(title));

  const tabs = [
    {
      name: "Overview",
      content: overview,
    },
  ];
  if (specs) {
    tabs.push({
      name: "Specs",
      content: specs,
    });
  }
  if (downloads) {
    tabs.push({
      name: "Downloads",
      content: downloads,
    });
  }
  const isFinalized = finalized && selected;
  const buttonText = selected ? "Finalize" : "Select";

  return (
    <Modal title={formattedTitle} open={open} onClose={onClose} data-testid="itemDetailsModal">
      <div
        css={{
          ...Css.dg.$,
          gridTemplateColumns: "3fr 2fr",
          gap: "40px",
          [Breakpoints.xs]: {
            gridTemplateColumns: "1fr",
          },
          gridColumnGap: px(increment(7)),
          gridRowGap: px(increment(7)),
        }}
      >
        <img
          css={{
            ...Css.w100.$,
            maxHeight: 500,
            objectFit: "contain",
          }}
          src={image}
          alt={name}
        />
        <div>
          <div css={Css.df.fdr.$}>
            <h4 css={Css.t18.mb1.mr2.w("65%").ttc.$}>{name}</h4>
            {selected && <SelectionStatusBadge isFinalized={isFinalized} />}
          </div>
          <div css={Css.mb1.t14.gray600.$}>{brand}</div>
          <div css={Css.mb2.$}>
            <PriceDetails
              msrpInCents={msrpInCents}
              priceDeltaInCents={priceDeltaInCents}
              pricingType={pricingType}
              selectionPricingMode={selectionPricingMode}
              unitOfMeasure={unitOfMeasure}
              unitPriceInCents={unitPriceInCents}
            />
          </div>
          {locked ? (
            <Tooltip title="Your selection has been locked in">
              <OutlineButton xss={Css.my2.$} onClick={onSelectionChange} size="narrow" disabled={true}>
                {isFinalized ? "Undo Finalization" : buttonText}
              </OutlineButton>
            </Tooltip>
          ) : (
            <OutlineButton xss={Css.my2.$} onClick={onSelectionChange} size="narrow">
              {isFinalized ? "Undo Finalization" : buttonText}
            </OutlineButton>
          )}
          {designerAvatar && <hr css={Css.my4.bn.h("2px").bgTaupe.$} />}
          {designerAvatar && (
            <div css={Css.df.mb4.$}>
              <Avatar css={Css.mr2.$} src={designerAvatar} alt="designer" />
              <p css={Css.t14.gray600.$}>
                Your designer has curated and recommended this selection specifically for you based on your style and
                budget.
              </p>
            </div>
          )}
          {/* Formatting the tabs variable to match Tabs props */}
          <Tabs tabs={tabs.map(({ name }) => ({ name }))} selectedTab={selectedTab} onClick={setSelectedTab} />
          <hr css={Css.mb2.bn.h("2px").bgTaupe.$} />
          <TabsContent
            selectedTab={selectedTab}
            // Formatting tabs variable to match TabContent props
            contents={tabs.reduce((acc, { name, content }) => ({ ...acc, [name]: content }), {})}
          />
        </div>
      </div>
      <div>
        <Divider css={Css.my3.$} />
        <div css={Css.w75.$}>
          <p css={Css.f16.mb2.$}>My Designer Messages</p>
          {commentOptions && (
            <CommentFeed {...(commentOptions as CommentFeedProps)} placeholder="Ask your designer a question" />
          )}
        </div>
      </div>
    </Modal>
  );
}

interface StatusBadgeProps {
  isFinalized: boolean;
}

function SelectionStatusBadge({ isFinalized }: StatusBadgeProps) {
  return (
    <div css={Css.t12.p("4px").br3.h3.nowrap.bgYellow.if(isFinalized).bgBrightGreen.$}>
      {isFinalized ? "Finalized Selection" : "Current Selection"}
    </div>
  );
}
