import {
  AuthError,
  AuthResult,
  AuthViewProps,
  completeLogin,
  HomeboundUser,
  startLogin,
} from "@homebound/auth-components";
import { Box, CircularProgress, Divider, Hidden, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { RouteProps, useHistory, useParams } from "react-router-dom";
import { PrimaryButton, SignInTextField } from "src/components";
import { HomeboundLogo } from "src/components/Icons/HomeboundLogo";
import { Typography } from "src/components/Typography";
import { amplifyConfig, identifyHeap } from "src/context";
import { Css, Palette } from "src/Css";
import { SignInRouteProps } from "src/routes";
import { ErrorPalette, HomeownerPalette } from "src/theme";
import { StringParam, useQueryParams } from "use-query-params";
import { GoogleLoginButton } from "./GoogleButton";
import { SignInFooter } from "./SignInFooter";

export interface SignInProps extends Pick<AuthViewProps, "setUser" | "setIsAuthenticated">, RouteProps {
  // For testing/mocking purposes
  completeLogin?: (config: any, token: string) => Promise<AuthResult<HomeboundUser>>;
  // For testing/mocking purposes
  startLogin?: (email: string) => Promise<AuthResult<any>>;
}

export function SignIn(props: SignInProps) {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState<AuthError>();
  const [handlingRequest, setHandlingRequest] = useState(false);

  const { linkCode } = useParams<SignInRouteProps>();

  const [{ signInError }] = useQueryParams({ signInError: StringParam });

  useEffect(() => {
    if (linkCode !== undefined) {
      submitCode(linkCode);
    } else if (signInError !== undefined) {
      setError({ code: "UserNotFoundException", message: "" });
    }
    // eslint-disable-next-line
  }, []);

  const history = useHistory();
  const classes = useStyles();

  const enterCode = "/enterCode" === props.location?.pathname;

  function validateEmail() {
    return email.length > 0;
  }

  function validateCode() {
    return code.length > 0;
  }

  async function handleLogin() {
    setHandlingRequest(true);
    const startLoginFunction = props.startLogin ?? startLogin;
    const { data, error } = await startLoginFunction(email);
    setHandlingRequest(false);
    setError(error);
    data && history.push("/enterCode");
  }

  async function handleCode() {
    submitCode(code);
  }

  async function submitCode(code: string) {
    setHandlingRequest(true);
    try {
      const completeLoginFunction = props.completeLogin ?? completeLogin;
      const { data: user, error } = await completeLoginFunction(amplifyConfig(), code);
      setHandlingRequest(false);

      if (user) {
        identifyHeap(user);
        props.setUser(user);
        props.setIsAuthenticated(true);
      } else {
        setError(error);
        history.push("/login");
      }
    } catch (e) {
      // TODO: auth-components/completeLogin should be updated to not throw exceptions
      setHandlingRequest(false);
      setError({ code: "NotAuthorizedException", message: "" });
      history.push("/login");
    }
  }

  function enterCodeForm() {
    return (
      <>
        <SignInTextField label="Email" value={email} />
        <div css={Css.t16.$}>We just sent you a temporary login code. Please check your inbox.</div>
        <SignInTextField
          type="number"
          data-testid="loginCode"
          label="Paste login code"
          onChange={(e) => setCode(e.target.value)}
          // TODO: remove onKeyUp func when TextField supports this natively
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              handleCode();
            }
          }}
        />
        <PrimaryButton testid="submitCode" disabled={!validateCode() || handlingRequest} onClick={() => handleCode()}>
          <span css={Css.pr1.$}>Continue</span>
          {handlingRequest && <CircularProgress size={16} />}
        </PrimaryButton>
      </>
    );
  }

  function emailForm() {
    return (
      <>
        <SignInTextField
          name="email"
          autoComplete="email"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          // TODO: remove onKeyUp func when TextField supports this natively
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              handleLogin();
            }
          }}
        />
        {signInErrorMessage()}
        <PrimaryButton
          testid="submitEmail"
          disabled={!validateEmail() || handlingRequest}
          onClick={() => handleLogin()}
        >
          <span css={Css.pr1.$}>Continue</span>
          {handlingRequest && <CircularProgress size={16} />}
        </PrimaryButton>
        <div css={Css.mt2.$}>
          <GoogleLoginButton />
        </div>
      </>
    );
  }

  function signInForm() {
    return (
      <Box display="flex" flexGrow={1} mt={{ xs: 4, sm: 0 }} alignItems={{ xs: "flex-start", sm: "center" }}>
        <Box data-testid="signInForm" pl={{ xs: 2, sm: 8 }} pr={8} display="flex" flexDirection="column" flexGrow={1}>
          <Typography size="f24" color={HomeownerPalette.Black}>
            Log in
          </Typography>
          <Box mt={1} mb={6}>
            <Typography size="f18" color={Palette.Gray600}>
              Access your home project
            </Typography>
          </Box>

          <Box display="flex" flexDirection="column" flexGrow={1} width={320} mb={10}>
            {enterCode && !error ? enterCodeForm() : emailForm()}
          </Box>
        </Box>
      </Box>
    );
  }

  function signInErrorMessage() {
    function friendlyMessage() {
      switch (error?.code) {
        case "UserNotFoundException":
          return "Uh oh! This email is not registered";
        case "NotAuthorizedException":
          return "Uh oh! We couldn't validate that code";
        default:
          return "Uh oh! Something went wrong";
      }
    }

    return (
      error && (
        <Box data-testid="error" pb={1}>
          <Typography size="f14" color={ErrorPalette.LightRed}>
            {friendlyMessage()}
          </Typography>
        </Box>
      )
    );
  }

  function signInHeader() {
    return (
      <Box pt={{ xs: 2, sm: 4 }}>
        <Box pl={{ xs: 2, sm: 4 }} pb={2}>
          <HomeboundLogo width={129} height={21} color={HomeownerPalette.Black} />
        </Box>
        <Hidden smUp>
          <Divider />
        </Hidden>
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <Box data-test-id="main" display="flex" flexGrow={1} flexDirection="row">
        <Box data-test-id="form" display="flex" flexDirection="column">
          {signInHeader()}
          {signInForm()}
        </Box>
        <Hidden xsDown>
          <div className={classes.imageBox} />
        </Hidden>
      </Box>
      <SignInFooter />
    </div>
  );
}

const useStyles = makeStyles((muiTheme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    alignContent: "space-between",
    height: "100vh",
  },
  imageBox: {
    backgroundImage: "url(/images/login/login-page-kitchen-bellino.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100%",
    flexGrow: 4,
  },
  footer: {
    [muiTheme.breakpoints.up("sm")]: {
      borderTop: `1px solid ${Palette.Gray400}`,
    },
  },
}));
