import { HomeownerProjectFragment, ProjectStageStatus, Stage } from "src/generated/graphql-types";
import { compareDates } from "src/utils";

const supportedStages = [Stage.PreConExecution, Stage.Construction];
const supportedStatuses = [ProjectStageStatus.Active, ProjectStageStatus.Completed];

// Sorts projects by earliest start date, removing projects that have not started a supported stage
export function sortHomeownerProjects(projects: HomeownerProjectFragment[] | undefined): HomeownerProjectFragment[] {
  if (!projects) {
    return [];
  }

  return projects.filter(hasActiveOrCompleteSupportedStage).sort((a, b) => compareDates(a.startDate, b.startDate));
}

function hasActiveOrCompleteSupportedStage(project: HomeownerProjectFragment): boolean {
  return (
    project.stages.findIndex((s) => {
      return supportedStatuses.includes(s.status.code) && supportedStages.includes(s.stage.code);
    }) > -1
  );
}
