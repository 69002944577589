import { CSSProperties } from "react";
import { Link } from "react-router-dom";
import { Css } from "src/Css";

export type LinkButtonProps = {
  children: React.ReactNode;
  to: string | { pathname: string; state: any };
  underline?: "none" | "hover" | "initial";
  xss?: CSSProperties;
  onClick?: () => void;
};

export function LinkButton(props: LinkButtonProps) {
  const { children, to, underline = "hover", xss = {}, onClick } = props;

  return (
    <Link
      css={{
        ...Css.noUnderline.gray700.cursorPointer.if(underline === "initial").underline.$,
        ...xss,
        "&:hover": Css.underline.cursorPointer.if(underline === "initial" || underline === "none").noUnderline.$,
      }}
      to={to}
      onClick={onClick}
    >
      {children}
    </Link>
  );
}
