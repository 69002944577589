import { Palette } from "src/Css";

export enum HomeownerPalette {
  LightTransparentGray = "rgba(53, 53, 53, 0.04)",
  TransparentGray = "rgba(53, 53, 53, 0.08)",
  DarkTransparentGray = "rgba(53, 53, 53, 0.16)",
  Black = "#353535",
  MidGray = "#888888",
  LightGray = "#cecece",
  VeryLightGray = "#e1e4e8",
  White = "#fcfcfa",
  Taupe = "#ebeae4",
  Yellow = "#f6b14e",
  LightTransparentYellow = "rgba(246, 177, 78, 0.1)",
  BrightGreen = "#aeb564",
  BlueBright = "#3a759d",
  Hollow = "rgba(0, 0, 0, 0)",
  Blue = "#526675",

  // Begin new personalization palette
  // We havent confirmed that we'll be using the full personalization palette
  OffWhite = "#F6F6F0", // "rgba(246, 246, 240, 1)",
  Green200 = "#A7F3D0",
}

export enum ErrorPalette {
  LightRed = "#e57373",
  Red = "#f44336",
  DarkRed = "#990000",
}

export type HomeownerColors = keyof typeof HomeownerPalette;

export type Colors = HomeownerPalette | ErrorPalette | Palette;
export type ColorsKeys = keyof Colors;
