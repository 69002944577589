import { SyntheticEvent, useState } from "react";
import { TextField } from "src/components";
import { useTestIds } from "src/hooks";
import { phoneMask } from "src/utils";

interface PhoneFieldProps {
  onChange?: (value: string) => void;
  placeholder?: string;
  value?: string;
  label?: string;
  disabled?: boolean;
  error?: boolean;
}

export function PhoneField(props: PhoneFieldProps) {
  const { onChange, placeholder, value = "", label, disabled, error } = props;
  const { onInput, onKeydown, mask, unMask } = phoneMask();
  const [internalInputValue, setInternalInputValue] = useState<string>(mask(value));
  const [tid] = useTestIds("phoneField");

  function onChangeInternal(e: SyntheticEvent) {
    const { newValue } = onInput(e);
    // use masked value for internal input
    setInternalInputValue(newValue);
    // provide un-masked value to caller
    onChange?.(unMask(newValue));
  }

  return (
    <TextField
      disabled={disabled}
      onChange={onChangeInternal}
      onKeyDown={onKeydown}
      placeholder={placeholder}
      type="tel"
      value={internalInputValue}
      autoComplete="tel"
      label={label}
      error={error}
      {...tid}
    />
  );
}
