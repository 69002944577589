import { useParams } from "react-router-dom";
import { SearchBy, useHomeownerRoomSelectionQuery } from "src/generated/graphql-types";
import { SpaceRouteProps } from "src/routes";

/** Returns the selections by space for the current project. */
export function useSelectionsBySpace() {
  const { projectId } = useParams<SpaceRouteProps>();

  const { data } = useHomeownerRoomSelectionQuery({
    variables: { searchBy: SearchBy.Project, id: projectId! },
  });

  return data?.homeownerProject?.selectionsByLocation;
}
