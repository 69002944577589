import { History } from "history";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Accordion, CommentFeed, CommentFeedProps, IconButton, TextButton } from "src/components";
import { Icon } from "src/components/Icons";
import { Css, increment, Margin, Only, px, Xss } from "src/Css";
import {
  Comment,
  HomeownerSelectionStatus,
  OptionDetailsFragment,
  ProductAttributeType,
  SelectionDetailsFragment,
  useHomeownerSelectionsQuery,
  useMakeHomeownerSelectionMutation,
} from "src/generated/graphql-types";
import { useTeamMembers } from "src/hooks";
import { selectionRoute, SelectionRouteProps, spacesRoute } from "src/routes";
import { findSelectionIndex, findUnfinalizedSelections, getSpaceSelections } from "src/utils";
import SwiperCore, { A11y, Keyboard, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import {
  BasicProductCard,
  BasicProductCardProps,
  NoOptionsIllustration,
  SelectedProductCard,
  SelectedProductCardProps,
} from "./components";

// Initializes Swiper functionality
SwiperCore.use([Navigation, Pagination, A11y, Keyboard]);

export function SelectionView() {
  const [makeSelection] = useMakeHomeownerSelectionMutation();
  const { Designer } = useTeamMembers();
  const { projectId, spaceId, selectionId } = useParams<SelectionRouteProps>();
  const history = useHistory();

  const { data } = useHomeownerSelectionsQuery({
    variables: { projectId },
  });

  const selectionsBySpace = data?.homeownerProject.selectionsByLocation;

  // Translates the URL selectionId to Swiper indexes
  const currentSpaceIndex = useMemo(
    () => selectionsBySpace?.findIndex(({ location }) => location.id === spaceId) ?? 0,
    [spaceId, selectionsBySpace],
  );

  const designerAvatar = useMemo(() => Designer?.user?.avatar ?? "/images/designer_avatar.png", [Designer]);

  const selections = useMemo(
    () => getSpaceSelections(selectionsBySpace, spaceId) as SelectionDetailsFragment[],
    [selectionsBySpace, spaceId],
  );

  // prettier-ignore
  useEffect(() => maybeRedirectToSpaces({ history, projectId, selectionId, selections, }), []); // eslint-disable-line
  useEffect(() => maybeSyncSwiperSlideWithUrl({ selectionId, selections }), [selectionId, selections]);
  useEffect(
    () => maybeRedirectToUnfinalizedOrFirstSelection({ projectId, spaceId: spaceId, selectionId, selections, history }),
    [selections], // eslint-disable-line
  );

  // Handle URL changes when swiping
  const handleSlideChange = useCallback(
    (index: number) => {
      // Using replace so that we don't add to the history stack
      const selectionId = selections?.[index]?.id;
      if (selectionId) history.replace(selectionRoute(projectId, spaceId, selectionId));
    },
    [history, spaceId, projectId, selections],
  );

  const handleSelection = useCallback(
    async (optionId: string, status: HomeownerSelectionStatus) => {
      await makeSelection({ variables: { optionId, status } });
    },
    [makeSelection],
  );

  const prevSpaceUrl = useMemo(() => {
    if (selectionsBySpace && currentSpaceIndex > 0) {
      const prevSpaceId = selectionsBySpace[currentSpaceIndex - 1].location.id;
      return selectionRoute(projectId, prevSpaceId);
    }
  }, [currentSpaceIndex, projectId, selectionsBySpace]);

  const nextSpaceUrl = useMemo(() => {
    if (selectionsBySpace && currentSpaceIndex !== selectionsBySpace.length - 1) {
      const nextSpaceId = selectionsBySpace[currentSpaceIndex + 1].location.id;
      return selectionRoute(projectId, nextSpaceId);
    }
  }, [currentSpaceIndex, projectId, selectionsBySpace]);

  return (
    <SelectionDataView
      onSlideChange={handleSlideChange}
      onSelection={handleSelection}
      {...{ selections, designerAvatar, prevSpaceUrl, nextSpaceUrl, projectId }}
    />
  );
}

/** Redirect to spaces when selectionId selection is not found within selections */
function maybeRedirectToSpaces({
  history,
  projectId,
  selectionId,
  selections,
}: {
  history: History;
  projectId: string;
  selectionId: string;
  selections?: SelectionDetailsFragment[];
}) {
  if (selectionId && selections?.length) {
    const selectionIndex = findSelectionIndex(selectionId, selections);

    if (selectionIndex === -1) {
      history.replace(spacesRoute(projectId));
    }
  }
}

/** Sync swiper with to selectionId in Url */
function maybeSyncSwiperSlideWithUrl({
  selectionId,
  selections,
}: {
  selectionId: string;
  selections?: SelectionDetailsFragment[];
}) {
  if (selectionId && selections?.length) {
    const selectionIndex = findSelectionIndex(selectionId, selections);
    const swiperRef = getSwiper();
    // If the slideIndex does not match swiper index, move to slideIndex slide
    if (swiperRef && swiperRef.realIndex !== selectionIndex) {
      /* Explicitly using slideTo with runCallback set to false to stop Swiper
       * from affecting the History object */
      swiperRef.slideTo(selectionIndex, 300, false);
    }
  }
}

/** When selectionId is missing in Url, redirect to either an un finalized or the first selection */
function maybeRedirectToUnfinalizedOrFirstSelection({
  history,
  spaceId,
  projectId,
  selectionId,
  selections,
}: {
  history: History;
  spaceId: string;
  projectId: string;
  selectionId?: string;
  selections?: SelectionDetailsFragment[];
}) {
  if (!selectionId && selections?.length) {
    // Update the URL with an un finalized or first selection option
    const unfinalizedSelectionId = findUnfinalizedSelections(selections)[0]?.id ?? selections[0].id;
    history.replace(selectionRoute(projectId, spaceId, unfinalizedSelectionId));
  }
}

export interface SelectionDataViewProps {
  designerAvatar?: string;
  nextSpaceUrl?: string;
  prevSpaceUrl?: string;
  /** When selecting an item or finalizing a selection */
  onSelection: ItemSelectionProps<{}>["onSelection"];
  /** Pass index details when slide changes */
  onSlideChange: (index: number) => void;
  selections?: SelectionDetailsFragment[];
  projectId: string;
}

export function SelectionDataView(props: SelectionDataViewProps) {
  const { designerAvatar, prevSpaceUrl, nextSpaceUrl, onSelection, onSlideChange, selections } = props;
  const [isStart, setIsStart] = useState(false);
  const [isEnd, setIsEnd] = useState(false);

  // Styles
  const customButtonStyles = useMemo(
    () => ({
      ...Css.absolute.top6.z3.ttn.bw1.bGray400.$,
      "&:hover, &:active": Css.bBlack.important.$,
      "&:disabled, &[aria-disabled=true]": Css.bGray400.important.$,
    }),
    [],
  );
  const leftButtonStyles = useMemo(
    () => ({ ...Css.left(px(increment(8))).$, ...customButtonStyles }),
    [customButtonStyles],
  );
  const rightButtonStyles = useMemo(
    () => ({ ...Css.right(px(increment(8))).$, ...customButtonStyles }),
    [customButtonStyles],
  );

  function handleSlideChange(swiper: SwiperCore, isInit: boolean) {
    setIsStart(swiper.isBeginning);
    setIsEnd(swiper.isEnd);

    // Since `Swiper.onSlideChangeTransitionEnd` no longer works, we've added the
    // URL syncing to the slideChange events and ignore the first init call
    if (!isInit) {
      onSlideChange(swiper.realIndex);
    }
  }

  const swiperSlides = useMemo(() => {
    return selections?.map(
      ({ selectedOption, recommendedOption, options, projectItem, id, status, canEdit, selectionPricingMode }) => {
        const selectedOptionId = selectedOption?.id ?? recommendedOption?.id ?? options[0]?.id;
        const { name: itemName, id: projectItemId } = projectItem;
        const comments: Comment[] = (projectItem.commentStreams.homeownerStream?.comments as Comment[]) || [];

        // Translating GraphQL type to SelectedProductCard type
        const selected = options.find((option) => option.id === selectedOptionId)!;
        const finalized = status?.code === HomeownerSelectionStatus.Finalized;

        const { pricingType, priceDeltaInCents } = selected;
        return (
          <SwiperSlide key={id} data-testid="selectionDataViewSlide">
            <ItemSelection
              itemName={itemName}
              onSelection={onSelection}
              commentOptions={{
                commentableId: projectItemId,
                comments: comments,
              }}
              selected={{
                ...optionToBasicProductCard(selected),
                selectionPricingMode,
                pricingType,
                priceDeltaInCents,
                designerAvatar: selected.id === recommendedOption?.id ? designerAvatar : undefined,
                finalized,
                itemName,
                showConfirmation: false,
                locked: !canEdit.allowed,
              }}
              options={options.map((option: OptionDetailsFragment) => ({
                ...optionToBasicProductCard(option),
                selectionPricingMode,
                priceDeltaInCents: option.priceDeltaInCents,
                pricingType: option.pricingType,
                designerAvatar: option.id === recommendedOption?.id ? designerAvatar : undefined,
                itemName,
                selected: option.id === selectedOptionId,
                finalized,
              }))}
            />
          </SwiperSlide>
        );
      },
    );
  }, [designerAvatar, onSelection, selections]);

  return selections ? (
    <Swiper
      // Force Swiper to re-initialize (and call init) on new selections to resolve isStart & isEnd issue
      key={selections.toString()}
      css={{
        ...Css.py3.px4.bgWhite.relative.fg1.add("overflowY", "auto").$,
        ".swiper-pagination": Css.bottom("auto").top3.z2.$,
        ".swiper-pagination-bullet": Css.w(3).br3.h(px(4)).$,
        ".swiper-pagination-bullet-active": Css.bgBlack.$,
      }}
      keyboard={{ enabled: true, onlyInViewport: true }}
      spaceBetween={increment(2)}
      slidesPerView={1}
      preventInteractionOnTransition={true}
      pagination={{ clickable: true }}
      allowTouchMove={false}
      preloadImages={false}
      onInit={(swiper) => handleSlideChange(swiper, true)}
      onSlideChange={(swiper) => handleSlideChange(swiper, false)}
    >
      {/* Swiper Prev Button */}
      <SpacesButton direction="prev" url={prevSpaceUrl} isEdge={isStart} styles={leftButtonStyles} />
      {/* Not un-mounting this component due to Swiper needing the ref */}
      <IconButton
        small
        xss={{ ...leftButtonStyles, ...Css.if(isStart).dn.$ }}
        /* Explicitly using slidePrev with runCallback set to false to stop Swiper
         * from affecting the History object */
        onClick={() => getSwiper().slidePrev(300, false)}
      >
        <Icon icon="chevronLeft" />
      </IconButton>
      {/* Swiper Next Button - All above comments are the same for this component */}
      <SpacesButton direction="next" url={nextSpaceUrl} isEdge={isEnd} styles={rightButtonStyles} />
      <IconButton
        small
        xss={{ ...rightButtonStyles, ...Css.if(isEnd).dn.$ }}
        /* Explicitly using slideNext with runCallback set to false to stop Swiper
         * from affecting the History object */
        onClick={() => getSwiper().slideNext(300, false)}
      >
        <Icon icon="chevronRight" />
      </IconButton>
      {swiperSlides}
    </Swiper>
  ) : (
    <div css={Css.w100.$}>{/* TODO: Build skeleton loaders */}</div>
  );
}

/** The buttons to move to the previous or next space */
function SpacesButton({
  direction,
  url,
  isEdge,
  styles,
}: {
  direction: "prev" | "next";
  url?: string;
  isEdge: boolean;
  styles: object;
}) {
  // Chevron left or right button
  const Button = useCallback(
    ({ xss = {}, disabled }: { xss?: object; disabled?: boolean }) => (
      <TextButton
        xss={{ ...styles, ...xss }}
        {...(direction === "prev"
          ? { startIcon: <Icon icon="chevronLeft" /> }
          : { endIcon: <Icon icon="chevronRight" /> })}
        disabled={disabled}
      >
        {`${direction === "prev" ? "Previous" : "Next"} Space`}
      </TextButton>
    ),
    [direction, styles],
  );

  // Using link outside the button so that the whole button is clickable
  const LinkComponent = useCallback(
    () =>
      url ? (
        // Keeping the component mounted as Link has a lag when creating an href
        <Link to={url} css={Css.if(!isEdge).dn.$}>
          <Button />
        </Link>
      ) : (
        // Rendering a disabled button since a Link wrapping a disabled button
        // is still clickable. A Link Button would be nice to have.
        <Button xss={Css.if(!isEdge).dn.$} disabled />
      ),
    [Button, isEdge, url],
  );

  return <LinkComponent />;
}

/** Translates selections.options to productCard props */
export function optionToBasicProductCard(option: OptionDetailsFragment) {
  const { id, name, product, unitPriceInCents, description } = option;
  const { images, attributes, msrpInCents, unitOfMeasure } = product;

  const finish = product.attributes.find((attr) => attr.type === ProductAttributeType.Finish)?.value;
  const [firstImage] = images ?? [];

  return {
    // Filtering "brand" since we do not want to show brand information a second time
    brand: attributes?.find(({ type }) => type === ProductAttributeType.Brand)?.value!,
    finish,
    id,
    image: firstImage ? firstImage.downloadUrl : "/images/image-not-available.svg",
    msrpInCents,
    name,
    productOverview: {
      ...product,
      description,
    },
    unitOfMeasure,
    unitPriceInCents,
  };
}

export interface ItemSelectionProps<X> extends Pick<BasicProductCardProps, "itemName"> {
  selected: Omit<SelectedProductCardProps<X>, "onFinalize"> & {
    // Used for mapping onClick events to the selected option
    id: string;
  };
  options: (Omit<BasicProductCardProps, "locked"> & {
    // Used for mapping onClick events to the selected option
    id: string;
  })[];
  /** When selecting an item or finalizing a selection */
  onSelection: (optionId: string, status: HomeownerSelectionStatus) => void;
  commentOptions?: CommentFeedProps;
}

/** The "swipe-able" card on the ItemSelection page
 *
 * TODO: Improvements
 * - When not active, retract accordion and potentially unmount images (depending on perf)
 */
export function ItemSelection<X extends Only<Xss<Margin>, X>>(props: ItemSelectionProps<X>) {
  const { itemName, onSelection, commentOptions, selected, options } = props;

  return (
    <div css={Css.shadowHover.br3.$}>
      <div css={Css.pt7.px3.df.aic.fdc.$}>
        <h1 css={Css.t24.mb1.ttc.$}>{itemName}</h1>
        <h2 css={Css.t16.mb(px(increment(1) / 2)).$}>Your Current Selection</h2>
        <SelectedProductCard
          xss={Css.mt2.mb4.$}
          {...{ ...selected, commentOptions }}
          // When clicking on a SelectedProductCard, set the status to "Finalized"
          onFinalize={() => onSelection(selected.id, HomeownerSelectionStatus.Finalized)}
          onSelectionClick={() => onSelection(selected.id, HomeownerSelectionStatus.Selected)}
        />
      </div>
      <OptionsAccordion {...{ options, onSelection, commentOptions }} locked={selected.locked} />
      <Accordion title="My Designer Messages" xss={Css.px3.$} expanded={true} hideCarrot={true}>
        <div css={Css.w75.$}>
          <CommentFeed {...(commentOptions as CommentFeedProps)} placeholder="Ask your designer a question" />
        </div>
      </Accordion>
    </div>
  );
}

interface OptionsProps extends Pick<ItemSelectionProps<any>, "options" | "onSelection"> {
  expanded?: boolean;
  commentOptions?: CommentFeedProps;
  locked: boolean;
}

// exported for story
export function OptionsAccordion({ options, onSelection, expanded, commentOptions, locked }: OptionsProps) {
  const titleText = locked ? "Options To Switch To" : `Options To Switch To (${options.length - 1})`;
  return (
    <Accordion
      title={titleText}
      xss={Css.px3.$}
      data-testid="optionsAccordion"
      expanded={expanded}
      defaultExpanded={locked}
    >
      {locked ? (
        <LockedSelection />
      ) : options.length > 1 ? (
        <SelectionOptions {...{ options, onSelection, commentOptions, locked }} />
      ) : (
        <NoOptions />
      )}
    </Accordion>
  );
}

function SelectionOptions({ options, onSelection, commentOptions, locked }: OptionsProps) {
  return (
    <div
      css={{
        ...Css.dg.w100.pb1.$,
        gridTemplateColumns: "repeat(auto-fit, minmax(220px,1fr))",
        columnGap: px(increment(2)),
        rowGap: px(increment(2)),
      }}
    >
      {options.map((option) => (
        <div css={Css.df.jcc.$} key={option.id}>
          {/* TODO: Add optimistic mutation responses to increase response time
          When clicking select, we should show a validate animation (something so the user knows they clicked it)
        */}
          <BasicProductCard
            {...{ ...option, commentOptions, locked }}
            // When clicking on a BasicProductCard, set the status to "Selected"
            onSelectionClick={() => onSelection(option.id, HomeownerSelectionStatus.Selected)}
            onFinalize={() => onSelection(option.id, HomeownerSelectionStatus.Finalized)}
          />
        </div>
      ))}
    </div>
  );
}

function LockedSelection() {
  return (
    <div css={Css.w50.tc.add("margin", "0 auto").$}>
      <Icon icon="locked" />
      <p css={Css.f18.black.mb1.$}>Sit back and relax, this selection is locked in</p>
      <p css={Css.f14.gray600.$}>
        We’re working hard to get you closer to home! Message your designer below if you have any questions.
      </p>
    </div>
  );
}

function NoOptions() {
  return (
    <div css={Css.df.fdc.aic.w100.mb3.$}>
      {NoOptionsIllustration}
      <p css={Css.tc.t14.gray600.maxw(px(280)).pt(1).$}>
        Your designer highly recommends the above selection for you. Still want more options? Send your designer a
        message.
      </p>
    </div>
  );
}

/** Triggers the current Swiper element to resize */
export function updateSwiper() {
  const swiper = getSwiper();
  if (swiper) {
    swiper.update();
  }
}

function getSwiper() {
  // @ts-ignore
  return document.querySelector(".swiper-container")?.swiper;
}
