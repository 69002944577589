import { useMediaQuery } from "@material-ui/core";
import { useCycle } from "framer-motion";
import { Link, useParams } from "react-router-dom";
import { Breakpoints, Css } from "src/Css";
import { overviewRoute, ProjectRouteProps } from "src/routes";
import { Menu } from ".";
import { Icon } from "../Icons";
import { HomeboundIcon } from "../Icons/HomeboundIcon";
import { NotificationIconButton } from "../Notification";
import { ProfileLink } from "../ProfileLink";

export function NavBar() {
  const [open, cycleOpen] = useCycle(false, true);
  const { projectId } = useParams<ProjectRouteProps>();
  const isMobile = useMediaQuery(Breakpoints.xs);

  return (
    <>
      <Menu open={open} onClick={cycleOpen} projectId={projectId} />
      <header css={Css.hPx(isMobile ? 64 : 72).df.jcsb.aic.fixed.w100.bgWhitePure.shadowBasic.z(1000).$}>
        <div css={Css.h100.df.aic.pl3.gapPx(isMobile ? 16 : 20).onHover.cursorPointer.$}>
          <div css={Css.bgOffWhite.hPx(isMobile ? 24 : 32).wPx(isMobile ? 24 : 32).br100.df.jcc.aic.$}>
            <Icon icon="menu" viewBox="0 0 20 20" size={isMobile ? "16" : "20"} onClick={cycleOpen} />
          </div>
          {!isMobile && (
            <div css={Css.t12.fw5.lh("20px").$} onClick={() => cycleOpen()}>
              Menu
            </div>
          )}
        </div>
        <div>
          <Link to={overviewRoute(projectId)}>
            {isMobile ? HomeboundIcon({ width: 39, height: 24 }) : <img src="/images/logo.svg" alt="homebound" />}
          </Link>
        </div>
        <div css={Css.h100.df.aic.pr3.gapPx(isMobile ? 16 : 20).$}>
          <NotificationIconButton />
          <ProfileLink />
        </div>
      </header>
    </>
  );
}
