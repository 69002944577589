import { AnimatePresence, motion, useCycle } from "framer-motion";
import { useParams } from "react-router-dom";
import { AssetGallery, Card, CommentFeed, Icon, PageContent, PageTitle, QueryResultHandler } from "src/components";
import { Css } from "src/Css";
import {
  Comment,
  TimeOfDay,
  useWarrantyTicketQuery,
  WarrantyTicketPageFragment,
  WarrantyTicketQuery,
} from "src/generated/graphql-types";
import { WarrantyTicketRouteProps } from "src/routes";
import { groupBy } from "src/utils";
import { AssetPreview } from "src/utils/AssetPreview";
import { daysOfWeek } from "./components/createWarrantySection/Availability.section";
import { completedText, getStatusTag } from "./components/WarrantyList";

export function WarrantyTicketPage() {
  const { warrantyTicketId } = useParams<WarrantyTicketRouteProps>();
  const result = useWarrantyTicketQuery({ variables: { id: warrantyTicketId! } });
  return QueryResultHandler<WarrantyTicketQuery>({
    result: result,
    render: (data) => <WarrantyTicketDataView warrantyTicket={data.warrantyTicket} />,
  });
}

type WarrantyTicketDataViewProps = {
  warrantyTicket: WarrantyTicketPageFragment;
};

export function WarrantyTicketDataView({ warrantyTicket }: WarrantyTicketDataViewProps) {
  const { commentStreams, items } = warrantyTicket;

  const comments = (commentStreams.homeownerStream?.comments as Comment[]) || [];
  return (
    <PageContent>
      <PageTitle title={warrantyTicket.title} />
      <div css={Css.df.mbPx(232).ifXs.fdc.$}>
        <div css={Css.w("50%").mrPx(113).ifXs.w100.mr0.$}>
          <div css={Css.mb3.df.$}>
            <div css={Css.f16.lh("25px").add("letterSpacing", "0.32px").gray800.$}>Summary</div>
            {items.length > 1 && (
              <div css={Css.mla.f14.fw3.lh("22px").add("letterSpacing", "unset").$}>{completedText(items)}</div>
            )}
          </div>
          <div css={Css.df.fdc.gap4.$}>
            <DetailsCard items={items} />
            <AvailabilityCard availabilities={warrantyTicket.availabilities} />
            <ContactInfoCard projectContacts={warrantyTicket.projectContacts} />
          </div>
        </div>
        <div css={Css.flexGrow(1).ifXs.mt6.$}>
          <div css={Css.f16.lh("25px").add("letterSpacing", "0.32px").gray800.mb3.$}>Comments</div>
          <CommentFeed
            placeholder="Add a question or message"
            comments={comments}
            commentableId={warrantyTicket.id}
            css={Css.mt2.$}
          />
        </div>
      </div>
    </PageContent>
  );
}

type DetailsCardProps = {
  items: WarrantyTicketPageFragment["items"];
};

function DetailsCard({ items }: DetailsCardProps) {
  const [showCard, cycleOpen] = useCycle(true, false);
  return (
    <Card xss={{ ...Css.p3.bgWhitePure.$, ...Css.ifXs.p2.$ }} isClickable={false}>
      <div css={Css.df.jcsb.$}>
        <div css={Css.f18.fw5.lh("28px").add("letterSpacing", "0.36px").$}>Request Details</div>
        <div css={Css.cursorPointer.$}>
          <Icon icon="close" onClick={cycleOpen} xss={Css.if(!showCard).add("transform", "rotate(45deg)").$} />
        </div>
      </div>
      <AnimatePresence>
        {showCard && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{
              opacity: 1,
              height: "auto",
              transition: {
                height: { duration: 0.4 },
                opacity: { duration: 0.25, delay: 0.15 },
              },
            }}
            exit={{
              opacity: 0,
              height: 0,
              transition: {
                height: { duration: 0.4 },
                opacity: { duration: 0.25 },
              },
            }}
            css={Css.f14.fw3.lh("22px").gray700.$}
          >
            {items.map((item) => (
              <div key={item.id}>
                <div css={Css.my3.h("1px").bgGray200.$} />
                <div css={Css.df.mb3.$}>
                  <div>
                    <div css={Css.f14.lh("24px").add("letterSpacing", "unset").gray800.mbPx(4).$}>Request Type</div>
                    <div css={Css.f14.lh("22px").fw3.add("letterSpacing", "unset").gray600.$}>{item.type.name}</div>
                  </div>
                  <div css={Css.mla.$}>{getStatusTag(item.status)}</div>
                </div>
                <div css={Css.mb3.$}>
                  <div css={Css.f14.lh("24px").add("letterSpacing", "unset").gray800.mbPx(4).$}>Description</div>
                  <div css={Css.f14.lh("22px").fw3.add("letterSpacing", "unset").gray600.$}>{item.description}</div>
                </div>
                <div>
                  <div css={Css.f14.lh("24px").add("letterSpacing", "unset").gray800.mbPx(4).$}>Documentation</div>
                  <AssetGallery assets={item.attachments.map((a) => a.asset)} showZoomInOutIcons download zoom wrap>
                    {(openGallery) => (
                      <div css={Css.df.gap1.overflowVisible.w100.add("flexWrap", "wrap").$}>
                        {item.attachments.map((a) => (
                          <div onClick={() => openGallery(a.asset)} css={Css.wPx(100).hPx(100).df.aic.jcc.$}>
                            <AssetPreview asset={a.asset} />
                          </div>
                        ))}
                      </div>
                    )}
                  </AssetGallery>
                </div>
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </Card>
  );
}

type AvailabilityCardProps = {
  availabilities: WarrantyTicketPageFragment["availabilities"];
};

function AvailabilityCard({ availabilities }: AvailabilityCardProps) {
  const [showCard, cycleOpen] = useCycle(true, false);
  const groupedAvailabilities = groupBy(availabilities, (a) => a.dayOfWeek.code);
  return (
    <Card xss={{ ...Css.p3.bgWhitePure.$, ...Css.ifXs.p2.$ }} isClickable={false}>
      <div css={Css.df.jcsb.$}>
        <div css={Css.f18.fw5.lh("28px").add("letterSpacing", "0.36px").$}>Availability</div>
        <div css={Css.cursorPointer.$}>
          <Icon icon="close" onClick={cycleOpen} xss={Css.if(!showCard).add("transform", "rotate(45deg)").$} />
        </div>
      </div>
      <AnimatePresence>
        {showCard && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{
              opacity: 1,
              height: "auto",
              transition: {
                height: { duration: 0.4 },
                opacity: { duration: 0.25, delay: 0.15 },
              },
            }}
            exit={{
              opacity: 0,
              height: 0,
              transition: {
                height: { duration: 0.4 },
                opacity: { duration: 0.25 },
              },
            }}
            css={Css.f14.fw3.lh("22px").gray700.$}
          >
            <div css={Css.my3.h("1px").bgGray200.$} />
            <div css={Css.mbPx(4).f14.lh("24px").gray800.$}>Preferred</div>
            <div css={Css.df.fdc.gap1.$}>
              {daysOfWeek.map((day) => {
                const group = groupedAvailabilities[day.value];
                if (!group?.length) {
                  return null;
                }

                const times = [];

                if (group.find((a) => a.timeOfDay.code === TimeOfDay.Morning)) {
                  times.push("Morning");
                }
                if (group.find((a) => a.timeOfDay.code === TimeOfDay.Afternoon)) {
                  times.push("Afternoon");
                }
                if (group.find((a) => a.timeOfDay.code === TimeOfDay.Evening)) {
                  times.push("Evening");
                }

                return <div css={Css.f14.fw3.lh("22px").gray500.$}>{`${day.label} ${times.join(", ")}`}</div>;
              })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Card>
  );
}

type ContactInfoCardProps = {
  projectContacts: WarrantyTicketPageFragment["projectContacts"];
};

function ContactInfoCard({ projectContacts }: ContactInfoCardProps) {
  const [showCard, cycleOpen] = useCycle(true, false);
  return (
    <Card xss={{ ...Css.p3.bgWhitePure.$, ...Css.ifXs.p2.$ }} isClickable={false}>
      <div css={Css.df.jcsb.$}>
        <div css={Css.f18.fw5.lh("28px").add("letterSpacing", "0.36px").$}>Contact Info</div>
        <div css={Css.cursorPointer.$}>
          <Icon icon="close" onClick={cycleOpen} xss={Css.if(!showCard).add("transform", "rotate(45deg)").$} />
        </div>
      </div>
      <AnimatePresence>
        {showCard && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{
              opacity: 1,
              height: "auto",
              transition: {
                height: { duration: 0.4 },
                opacity: { duration: 0.25, delay: 0.15 },
              },
            }}
            exit={{
              opacity: 0,
              height: 0,
              transition: {
                height: { duration: 0.4 },
                opacity: { duration: 0.25 },
              },
            }}
            css={Css.f14.fw3.lh("22px").gray700.$}
          >
            {projectContacts.map((pc) => (
              <div key={pc.id}>
                <div css={Css.my3.h("1px").bgGray200.$} />
                <div css={Css.mb3.$}>
                  <div css={Css.f14.lh("24px").add("letterSpacing", "unset").gray800.mbPx(4).$}>Full Name</div>
                  <div css={Css.f14.lh("22px").fw3.add("letterSpacing", "unset").gray600.$}>{pc.fullName}</div>
                </div>
                <div css={Css.mb3.$}>
                  <div css={Css.f14.lh("24px").add("letterSpacing", "unset").gray800.mbPx(4).$}>Email</div>
                  <div css={Css.f14.lh("22px").fw3.add("letterSpacing", "unset").gray600.$}>{pc.email}</div>
                </div>
                <div>
                  <div css={Css.f14.lh("24px").add("letterSpacing", "unset").gray800.mbPx(4).$}>Phone Number</div>
                  <div css={Css.f14.lh("22px").fw3.add("letterSpacing", "unset").gray600.$}>{pc.phoneNumber}</div>
                </div>
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </Card>
  );
}
