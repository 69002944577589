import { useParams } from "react-router-dom";
import { SelectionsByLocationDetailsFragment, useHomeownerSelectionsQuery } from "src/generated/graphql-types";
import { SelectionRouteProps } from "src/routes";

/** Returns selections data for the projectId */
export function useSelections(): SelectionsByLocationDetailsFragment[] | undefined {
  const { projectId } = useParams<SelectionRouteProps>();

  const { data } = useHomeownerSelectionsQuery({
    variables: { projectId },
  });

  return data?.homeownerProject?.selectionsByLocation;
}
