import { useEffect } from "react";
import { CurrentUserQuery } from "src/generated/graphql-types";

export function useFullStoryIdentify(user?: CurrentUserQuery) {
  const { id, email, userType } = user?.currentUser || {};

  // Attach user id and type to FullStory; should be run 1x on every full pageload, per:
  // https://help.fullstory.com/hc/en-us/articles/360020828113-FS-identify-Identifying-users
  const { FS } = window as any;
  useEffect(() => {
    // does not log session for homebound users
    const isHomebounder = email?.endsWith("@homebound.com");
    if (FS && id && email && userType && !isHomebounder) {
      FS.identify(`${userType}::${id}`, { email });
    }
  }, [FS, id, email, userType]);
}
