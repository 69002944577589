import { Divider, Menu, MenuItem } from "@material-ui/core";
import { Css, px } from "src/Css";
import { openUrl } from "src/utils";

export interface DocumentMenuProps {
  anchorEl: HTMLElement;
  disableView?: boolean;
  closeMenu: Function;
  isMenuOpen: boolean;
  onModalOpen: Function;
  url: string;
}

/**
 * Displays a menu for the DocumentBlock component with two menu items: View and Download
 */
export function DocumentMenu({
  anchorEl,
  disableView = false,
  closeMenu,
  isMenuOpen,
  onModalOpen,
  url,
}: DocumentMenuProps) {
  function handleViewClick() {
    onModalOpen(document);
    closeMenu();
  }

  function handleDownloadClick() {
    openUrl(url);
    closeMenu();
  }

  return (
    <Menu
      {...{ anchorEl }}
      id="document-menu"
      keepMounted
      disableRestoreFocus={true}
      open={isMenuOpen}
      getContentAnchorEl={null}
      onClose={() => closeMenu()}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{ style: { ...Css.br3.shadowHover.w(px(114)).if(disableView).add("cursor", "not-allowed").$ } }}
    >
      <MenuItem onClick={handleViewClick} css={menuItemStyles} disabled={disableView}>
        View
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleDownloadClick} css={menuItemStyles}>
        Download
      </MenuItem>
    </Menu>
  );
}

const menuItemStyles = { ...Css.f14.h(px(62)).pl3.$, "&:hover": Css.bgTransparentGray.$ };
