import { Tooltip as MuiTooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Css, px } from "src/Css";

export const Tooltip = withStyles({
  tooltip: Css.bgBlueBright.maxw(px(200)).p2.shadowHover.br3.t14.white.$,
})(MuiTooltip);

export const TooltipV2 = withStyles({
  tooltip: Css.bgBlack.maxw(px(450)).px1.pyPx(4).shadowHover.br3.f12.whitePure.add("letterSpacing", "unset").$,
})(MuiTooltip);
