import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import { DesignerAvatar, Icon, PrimaryButton, TextButton, Tooltip } from "src/components";
import { Css, Margin, Only, Palette, px, Xss } from "src/Css";
import { BasicProductCardProps } from "./BasicProductCard";
import { ItemDetailsModal, ProductOverview } from "./ItemDetailsModal";
import { ProductDetails } from "./ProductDetails";

export interface SelectedProductCardProps<X>
  extends FinalizedElementProps,
    SelectionConfirmationProps,
    Omit<BasicProductCardProps, "selected" | "onFinalize"> {
  showConfirmation: boolean;
  xss?: X;
}

export function SelectedProductCard<X extends Only<Xss<Margin>, X>>({
  brand,
  name,
  designerAvatar,
  finalized,
  finalizedDate,
  image,
  itemName,
  nextItemName,
  onFinalize,
  unitPriceInCents,
  msrpInCents,
  unitOfMeasure,
  showConfirmation: initialShowConfirmation,
  xss,
  commentOptions,
  productOverview,
  finish,
  onSelectionClick,
  locked,
  priceDeltaInCents,
  pricingType,
  selectionPricingMode,
}: SelectedProductCardProps<X>) {
  const [showConfirmation, setShowConfirmation] = useState(initialShowConfirmation);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  // Handle when showConfirmation prop changes after initial render
  useEffect(() => {
    setShowConfirmation(initialShowConfirmation);
  }, [initialShowConfirmation, finalized]);

  function handleOnFinalize() {
    // Show confirmation
    setShowConfirmation(true);

    // After 1.5 seconds, trigger onFinalize
    setTimeout(() => {
      onFinalize();
    }, 1500);
  }

  const SelectionConfirmationComponent = useCallback(
    () => <SelectionConfirmation {...{ itemName, nextItemName }} />,
    [itemName, nextItemName],
  );

  const handleSelectionChange = () => {
    if (!finalized) {
      onFinalize();
    } else {
      onSelectionClick && onSelectionClick();
    }
  };

  return (
    <div
      css={{
        ...Css.maxw(px(474)).w100.ba.bw2.br3.if(finalized || showConfirmation).bBrightGreen.else.bYellowTransparent3.$,
        ...xss,
      }}
    >
      <AnimatePresence>
        {showConfirmation ? (
          <SelectionConfirmationComponent />
        ) : (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{
              duration: 0.5,
            }}
          >
            <div css={Css.cursorPointer.$} onClick={() => setIsDetailsModalOpen(true)}>
              <div css={Css.df.h(px(178)).bgWhitePure.jcc.relative.$}>
                {designerAvatar && <DesignerAvatar avatarUrl={designerAvatar} />}
                <img
                  css={Css.h(px(148)).m2.w(px(221)).add("objectFit", "contain").$}
                  src={image}
                  alt={`${name}/${brand}`}
                />
                <FinalizedElement {...{ finalized, finalizedDate, locked }} onFinalize={handleOnFinalize} />
              </div>
              <div css={Css.df.jcsb.aic.p2.add("boxSizing", "border-box").$}>
                <ProductDetails
                  brand={brand}
                  finish={finish}
                  msrpInCents={msrpInCents}
                  name={name}
                  priceDeltaInCents={priceDeltaInCents}
                  pricingType={pricingType}
                  selectionPricingMode={selectionPricingMode}
                  unitOfMeasure={unitOfMeasure}
                  unitPriceInCents={unitPriceInCents}
                />
                <TextButton onClick={() => setIsDetailsModalOpen(true)}>Details</TextButton>
              </div>
            </div>
            <ItemDetailsModal
              {...{
                name,
                brand,
                image,
                designerAvatar,
                commentOptions,
                finalized,
                locked,
                unitPriceInCents,
                msrpInCents,
                unitOfMeasure,
                priceDeltaInCents,
                pricingType,
                selectionPricingMode,
              }}
              selected={true}
              open={isDetailsModalOpen}
              title={itemName}
              onClose={() => setIsDetailsModalOpen(false)}
              overview={<ProductOverview {...{ productOverview }} />}
              onSelectionChange={handleSelectionChange}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

interface FinalizedElementProps {
  finalized: boolean;
  finalizedDate?: Date;
  /** When the "finalize" button is clicked */
  onFinalize: () => void;
  locked: boolean;
}

function FinalizedElement({ finalized, onFinalize, locked }: FinalizedElementProps) {
  if (locked) {
    return (
      <div css={Css.absolute.right2.top2.mr1.mb1.aife.$}>
        <Tooltip title="Your selection has been locked in" css={Css.cursorPointer.$}>
          <div>
            <Icon icon="locked" />
          </div>
        </Tooltip>
      </div>
    );
  }

  if (finalized) {
    return (
      <div css={Css.absolute.right2.top2.df.fdc.aife.$}>
        <Tooltip title="You finalized your selection" placement="bottom" css={Css.cursorPointer.$}>
          <div>
            <Icon icon="outlineCheckmark" color={Palette.BrightGreen} />
          </div>
        </Tooltip>
        {/* FIXME: Turn this back on when finalize data info is available */}
        {/* <div css={Css.t12.gray600.$}>Finalized on</div>
        <div css={Css.t12.gray600.$}>{moment(finalizedDate).format("MMM D, YYYY")}</div> */}
      </div>
    );
  }

  return (
    <PrimaryButton
      xss={Css.absolute.right2.top2.px3.h(px(27)).important.bgYellow.$}
      onClick={(e) => {
        e.stopPropagation();
        onFinalize();
      }}
      data-testid="selectedProductCardFinalizeButton"
    >
      Finalize
    </PrimaryButton>
  );
}

interface SelectionConfirmationProps extends Pick<BasicProductCardProps, "itemName"> {
  /** Next item name to be selected */
  nextItemName?: string;
}

function SelectionConfirmation({ itemName, nextItemName }: SelectionConfirmationProps) {
  return (
    <motion.div
      css={Css.df.fdc.h(px(261)).jcc.aic.$}
      initial={{
        x: 50,
        opacity: 0,
      }}
      animate={{
        x: 0,
        opacity: 1,
      }}
      exit={{
        x: 50,
        opacity: 0,
      }}
      transition={{
        duration: 0.5,
      }}
      data-testid="selectionConfirmation"
    >
      <Icon icon="outlineCheckmark" color={Palette.BrightGreen} xss={Css.w("55px").h("55px").$} />
      <div css={Css.t18.aic.my1.$}>Great choice!</div>
      <div css={Css.t14.gray600.$}>We love that {itemName.toLowerCase()} too.</div>
      {nextItemName && <div css={Css.t14.gray600.$}>Now let's choose your {nextItemName.toLowerCase()}!</div>}
    </motion.div>
  );
}
