import { Card, Icon, Tooltip } from "src/components";
import { Css, px } from "src/Css";

export interface SpaceCardProps {
  finalizedNum: number;
  imageSrc?: string;
  title: string;
  totalNum: number;
  locked: boolean;
}

export function SpaceCard({ finalizedNum, imageSrc, title, totalNum, locked }: SpaceCardProps) {
  const src = imageSrc || "/images/location-without-image.png";

  return (
    <Card xss={Css.df.fdc.$}>
      <img src={src} alt={title} css={Css.h(px(229)).w100.add("objectFit", "cover").$} />
      <div css={Css.df.jcsb.aic.h(px(60)).p2.$}>
        <div css={Css.t16.pr1.$}>{title}</div>
        <div css={Css.t14.gray600.$}>{locked ? <LockedSpace /> : `${finalizedNum} of ${totalNum} Finalized`}</div>
      </div>
    </Card>
  );
}

function LockedSpace() {
  return (
    <Tooltip title="Your selection has been locked in" placement="left">
      <div>
        <Icon icon="locked" />
      </div>
    </Tooltip>
  );
}
