import { HTMLInputTypeAttribute, InputHTMLAttributes, ReactNode, SyntheticEvent, TextareaHTMLAttributes } from "react";
import { Icon } from "src/components";
import { Css, Palette } from "src/Css";
import { useTestIds } from "src/hooks";
// Input types that are supported via a text input.
type TextInputType = Extract<HTMLInputTypeAttribute, "email" | "password" | "search" | "tel" | "text" | "number">;

// A TextField can either be disabled, readOnly, or neither.
type DisabledTextField = {
  disabled?: boolean;
};
type ReadOnlyTextField = {
  readOnly?: boolean;
};
export type TextFieldProps = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  "autoComplete" | "placeholder" | "required"
> & {
  label?: ReactNode;
  onFocus?: (e: SyntheticEvent) => void;
  onBlur?: (e: SyntheticEvent) => void;
  onChange?: (e: SyntheticEvent, value: string) => void;
  onKeyDown?: (e: SyntheticEvent, key: string) => void;
  /** @default "text" */
  type?: TextInputType;
  value?: string | number | null;
  inputId?: string;
  error?: boolean;
  textArea?: boolean;
  rows?: number;
  helperText?: string;
} & (DisabledTextField | ReadOnlyTextField);

export function TextField(props: TextFieldProps) {
  const {
    autoComplete,
    label,
    onBlur,
    onChange,
    onKeyDown,
    placeholder,
    required,
    type = "text",
    inputId = "text-input",
    value,
    textArea = false,
    helperText,
    ...inputOthers
  } = props;
  const disabled = "disabled" in props ? props.disabled ?? false : false;
  const readOnly = "readOnly" in props ? props.readOnly ?? false : false;
  const error = "error" in props ? props.error ?? false : false;
  const [testId, labelTestId, inputTestId] = useTestIds("textField", ["label", "input"]);

  const customLabel =
    label && typeof label === "string" ? (
      <div
        {...labelTestId}
        css={
          Css.f14.gray800.fw7
            .lh("16px")
            .add("letterSpacing", "0.32px")
            .mbPx(12)
            .df.if(disabled)
            .gray700.else.if(error)
            .bgRed600.whitePure.p1.wfc.else.if(!!helperText).mb0.$
        }
      >
        {error && (
          <div css={Css.mrPx(4).df.aic.$}>
            <Icon icon="errorExclamation" size="16" viewBox="0 0 16 16" />
          </div>
        )}
        {label}
        {error && " Is required"}
      </div>
    ) : (
      label
    );

  const Tag = textArea
    ? ("textarea" as React.ElementType<TextareaHTMLAttributes<HTMLAnchorElement>>)
    : ("input" as React.ElementType<InputHTMLAttributes<HTMLButtonElement>>);

  return (
    <label>
      {/* Label */}
      {label && customLabel}
      {helperText && (
        <div css={Css.f14.gray700.fw3.lh("22px").add("letterSpacing", "unset").mbPx(12).$}>{helperText}</div>
      )}

      {/* Input */}
      <Tag
        id={inputId}
        css={{
          ...Css.bgWhitePure.br4.px2
            .pyPx(15)
            .f14.w100.ba.bGray600.bsSolid.gray800.fw3.lh("18px")
            .add("letterSpacing", "0.16px")
            .add("resize", "none")
            .if(disabled)
            .gray700.cursorNotAllowed.else.if(readOnly)
            .bgTransparent.shadowNone.pl0.else.if(error)
            .outline("2px solid")
            .add("outlineColor", Palette.Red600)
            .add("outlineOffset", "2px").$,
          "&:focus": Css.outline("2px solid").add("outlineColor", Palette.Blue600).add("outlineOffset", "2px").$,
        }}
        disabled={disabled}
        value={value ?? ""}
        onBlur={(e) => onBlur?.(e)}
        onChange={(e) => onChange?.(e, (e.target as any).value)}
        onKeyDown={(e) => onKeyDown?.(e, e.key)}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        type={type}
        autoComplete={autoComplete}
        rows={textArea ? props.rows : undefined}
        {...inputOthers}
        {...inputTestId}
      />
    </label>
  );
}
