import { HomeboundUser } from "@homebound/auth-components";
import { useState } from "react";
import { Button, PhoneField, TextField } from "src/components";
import { Checkbox } from "src/components/Checkbox";
import { Css } from "src/Css";
import {
  CreateWarrantyProjectContactFragment,
  CreateWarrantyProjectContactFragmentDoc,
  SaveProjectContactInput,
  useCreateProjectContactMutation,
} from "src/generated/graphql-types";
import { useTestIds } from "src/hooks";
import { SectionProps } from "../../CreateWarrantyPage";

export type ContactInfoProps = {
  contacts: CreateWarrantyProjectContactFragment[];
  projectId: string;
  user: HomeboundUser;
} & SectionProps;

export function ContactInfo({ contacts, projectId, form, setForm, user }: ContactInfoProps) {
  const [newContactOpen, setNewContactOpen] = useState<boolean>(contacts.length === 0);

  function onChange(v: string) {
    const currentContacts = form.projectContactIds;
    const newContacts = currentContacts.filter((s) => {
      return s !== v;
    });
    // If the value wasn't removed then add it
    if (newContacts.length === currentContacts.length) {
      newContacts.push(v);
    }
    setForm({
      ...form,
      projectContactIds: newContacts,
    });
  }

  return (
    <div css={Css.mb6.$}>
      {contacts.length !== 0 && (
        <div css={Css.df.fdc.gap2.mb3.$}>
          {contacts.map((contact) => (
            <ContactCard
              contact={contact}
              onClick={onChange}
              selected={!!form.projectContactIds.find((pc) => pc === contact.id)}
              key={contact.id}
            />
          ))}
        </div>
      )}
      {newContactOpen ? (
        <NewContact
          onCancel={() => setNewContactOpen(false)}
          hasProjectContacts={contacts.length > 0}
          projectId={projectId}
          user={user}
          onCreate={onChange}
        />
      ) : (
        <Button size="small" variant="ghost" onClick={() => setNewContactOpen(true)} width="none" iconLeft="plus">
          Add Another Contact
        </Button>
      )}
    </div>
  );
}

type ContactCardProps = {
  contact: CreateWarrantyProjectContactFragment;
  onClick: (v: string) => void;
  selected: boolean;
};

function ContactCard({ contact, onClick, selected }: ContactCardProps) {
  return (
    <div css={Css.bgWhitePure.br8.shadowBasic.pl6.prPx(35).py3.df.aic.ifXs.fdc.aib.px4.$}>
      <div>
        <div css={Css.f16.gray700.fw3.lh("24px").add("letterSpacing", "0px").$}>{contact.fullName}</div>
        <div css={Css.f16.gray700.fw3.lh("24px").add("letterSpacing", "0px").$}>{contact.phoneNumber}</div>
        <div css={Css.f16.gray700.fw3.lh("24px").add("letterSpacing", "0px").$}>{contact.email}</div>
      </div>
      <div css={Css.mla.ifXs.ml0.mt2.$}>
        <Checkbox checked={selected} onChange={onClick} value={contact.id}>
          <div css={Css.f16.gray800.fw7.lh("24px").add("letterSpacing", "0px").$}>Warranty Contact</div>
        </Checkbox>
      </div>
    </div>
  );
}

type FormState = Pick<SaveProjectContactInput, "fullName" | "email" | "phoneNumber">;

type NewContactProps = {
  hasProjectContacts: boolean;
  projectId: string;
  onCancel: () => void;
  user: HomeboundUser;
  onCreate: (v: string) => void;
};

function NewContact({ onCancel, hasProjectContacts, projectId, user, onCreate }: NewContactProps) {
  const [form, setForm] = useState<FormState>(
    hasProjectContacts
      ? { fullName: "", email: "", phoneNumber: "" }
      : {
          fullName: user.name,
          email: user.email,
          phoneNumber: (user.phone || "").replace(/\D/g, ""),
        },
  );
  const [erroredFields, setErroredFields] = useState<string[]>([]);
  const [createProjectContact, { loading: saving }] = useCreateProjectContactMutation();
  const [testId, nameTestId, emailTestId, phoneNumberTestId] = useTestIds("newContact", [
    "name",
    "email",
    "phoneNumber",
  ]);

  const onSubmit = async () => {
    const newErroredFields = [];
    if (!form.fullName) {
      newErroredFields.push("fullName");
    }
    if (!form.email) {
      newErroredFields.push("email");
    }
    if (!form.phoneNumber) {
      newErroredFields.push("phoneNumber");
    }
    if (newErroredFields.length > 0) {
      setErroredFields(newErroredFields);
      return;
    }

    await createProjectContact({
      variables: {
        input: {
          id: null,
          projectId,
          ...form,
        },
      },
      update(cache, { data }) {
        const addContact = data?.saveProjectContact?.projectContact;
        cache.modify({
          id: cache.identify({ id: projectId, __typename: "Project" }),
          fields: {
            contacts(existingContactRefs = [], { readField }) {
              const newContactRef = cache.writeFragment({
                data: addContact,
                fragment: CreateWarrantyProjectContactFragmentDoc,
              });
              return [...existingContactRefs, newContactRef];
            },
          },
        });
        onCreate(addContact!.id);
      },
    });
  };

  return (
    <div css={Css.bgWhitePure.br8.shadowBasic.pxPx(99).py4.ifXs.py4.px3.$}>
      <div css={Css.mb2.f18.gray800.lh("28px").add("letterSpacing", "0.36px").$}>Add a new contact</div>
      <div css={Css.mb3.w100.$}>
        <TextField
          required
          value={form.fullName}
          onChange={(_, fullName) => setForm((prevForm) => ({ ...prevForm, fullName }))}
          autoComplete="name"
          label="Full Name"
          error={erroredFields.includes("fullName")}
          {...nameTestId}
        />
      </div>

      <div css={Css.mb3.$}>
        <TextField
          required
          type="email"
          value={form.email}
          onChange={(_, email) => setForm((prevForm) => ({ ...prevForm, email }))}
          autoComplete="email"
          label="Email"
          error={erroredFields.includes("email")}
          {...emailTestId}
        />
      </div>
      <div css={Css.mb3.$}>
        <PhoneField
          label="Phone number"
          onChange={(phoneNumber) => setForm((prevForm) => ({ ...prevForm, phoneNumber }))}
          value={form.phoneNumber || undefined}
          error={erroredFields.includes("phoneNumber")}
          {...phoneNumberTestId}
        />
      </div>
      <div css={Css.df.gap1.$}>
        <Button size="large" width="none" onClick={onSubmit} disabled={saving}>
          Save
        </Button>
        {hasProjectContacts && (
          <Button size="large" variant="tertiary" width="none" onClick={onCancel} disabled={saving}>
            Cancel
          </Button>
        )}
      </div>
    </div>
  );
}
