import { useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { CommentFeed, Data, DocumentModal, DocumentPreviewCard, QueryResultHandler } from "src/components";
import { Css, px } from "src/Css";
import { Comment, HomeownerInvoiceQuery, SearchBy, useHomeownerInvoiceQuery } from "src/generated/graphql-types";
import { financesRoute, InvoiceRouteProps } from "src/routes";
import { centsToDollars, shortMonthDateFormat } from "src/utils";
import { InvoiceStatusBar } from "./components";

export function Invoice() {
  const { invoiceId, projectId } = useParams<InvoiceRouteProps>();
  const result = useHomeownerInvoiceQuery({
    variables: { invoiceId, projectId, searchBy: SearchBy.Project },
  });

  return QueryResultHandler<HomeownerInvoiceQuery>({
    result,
    render: (data) => <InvoiceDataView {...{ data }} />,
  });
}

export function InvoiceDataView({ data }: Data<HomeownerInvoiceQuery>) {
  const { projectId } = useParams<InvoiceRouteProps>();

  const [isModalOpen, setModalOpen] = useState(false);

  const invoices = data?.homeownerProject?.stages?.flatMap((stage) => stage?.invoices).filter((i) => i !== undefined);
  if (invoices.length === 0) return <Redirect to={financesRoute(projectId)} />;

  // invoices are filtered on the invoiceId and projectId and will return an array with one invoice.
  const { amountInCents, amountPaidInCents, balanceInCents, document, dueDate, invoiceNumber, commentStreams, id } =
    invoices[0];
  const comments = (commentStreams.homeownerStream?.comments as Comment[]) || [];

  function DocumentPreviewSection({ url, contentType }: { url: string; contentType: string }) {
    const title = "Invoice";
    return (
      <>
        <DocumentPreviewCard document={{ title, url, contentType }} onModalOpen={() => setModalOpen(true)} />
        <DocumentModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          document={{ title, url, contentType }}
        />
      </>
    );
  }

  const formattedAmount = centsToDollars(amountInCents, 2);
  const formattedDueDate = shortMonthDateFormat(dueDate, true);

  return (
    <div css={Css.h("calc(100vh - 64px)").ifXs.h("calc(100vh - 72px)").$}>
      <div css={Css.df.w100.overflowHidden.$}>
        <div css={Css.df.w100.mb2.overflowAuto.$}>
          <div css={Css.p4.w(px(550)).$}>
            <h2 css={Css.t18.mb3.$}>Summary</h2>
            <div css={Css.mb3.$}>
              <div css={Css.df.mb2.jcsb.$}>
                <h3 css={Css.t14.$}>Invoice Amount</h3>
                <h3 css={Css.t14.gray600.$}>{formattedAmount}</h3>
              </div>
              <div css={Css.df.mb2.jcsb.$}>
                <h3 css={Css.t14.$}>Due Date</h3>
                <h3 css={Css.t14.gray600.$}>{formattedDueDate}</h3>
              </div>
            </div>
            {document?.asset.downloadUrl && (
              <DocumentPreviewSection url={document.asset.downloadUrl} contentType={document.asset.contentType} />
            )}
            <>
              <h2 css={Css.t18.my3.$}>Messages</h2>
              <CommentFeed
                placeholder="Ask your PM a question"
                comments={comments}
                commentableId={id}
                css={Css.mt2.$}
              />
            </>
          </div>
        </div>
        <InvoiceStatusBar {...{ amountPaidInCents, balanceInCents }} />
      </div>
    </div>
  );
}
