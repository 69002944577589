import { Icon } from "src/components";
import { Css } from "src/Css";
import { AssetInfoFragment } from "src/generated/graphql-types";
import { openUrl } from "src/utils";
import { AssetPreview } from "src/utils/AssetPreview";

export type TileCardProps = {
  name: string;
  createdAt: string;
  asset: AssetInfoFragment;
  onClick: () => void;
};

export function TileCard(props: TileCardProps) {
  const { name, createdAt, asset, onClick } = props;

  // todo if preview url is undefined, show a placeholder image
  return (
    <div
      data-testid="tileCard"
      onClick={onClick}
      css={
        Css.hPx(260).bgWhitePure.br4.relative.df.fdc.boxShadow(
          "0px 2px 16px 0px rgba(53, 53, 53, 0.03), 0px 4px 8px 0px rgba(53, 53, 53, 0.08)",
        ).cursorPointer.$
      }
    >
      {/* HACK: overwriting canvas to force it to fill full width */}
      <div css={Css.hPx(178).relative.overflowHidden.addIn("canvas", Css.important.w100.h("auto").$).$}>
        <AssetPreview asset={asset} dimensions={{ height: 450 }} />
      </div>
      <div css={Css.pt2.px2.df.jcsb.$}>
        <div>
          <div css={Css.f16.wPx(200).lh("24px").add("letterSpacing", "-0.32px").fw5.truncate.$}>{name}</div>
          <div css={Css.f12.lh("20px").fw3.gray400.$}>Added {createdAt}</div>
        </div>
        <div css={Css.hPx(32).wPx(32).bgTaupe.br100.relative.asc.$}>
          <div
            onClick={(e) => {
              openUrl(asset.attachmentUrl);
              e.stopPropagation();
            }}
            css={Css.absolute.top("25%").left("31%").$}
          >
            <Icon icon="download" />
          </div>
        </div>
      </div>
    </div>
  );
}
