import { Button } from "src/components";
import { Css } from "src/Css";
import { DayOfWeek, TimeOfDay } from "src/generated/graphql-types";
import { SectionProps } from "../../CreateWarrantyPage";

// not pulling from the BE or using is_business_day because this probably isn't going to change
export const daysOfWeek = [
  {
    label: "Monday",
    value: DayOfWeek.Monday,
  },
  {
    label: "Tuesday",
    value: DayOfWeek.Tuesday,
  },
  {
    label: "Wednesday",
    value: DayOfWeek.Wednesday,
  },
  {
    label: "Thursday",
    value: DayOfWeek.Thursday,
  },
  {
    label: "Friday",
    value: DayOfWeek.Friday,
  },
];

// Omitting "Evening" because clients assume it is after work hours
const timeOfDay = [
  {
    label: "Morning",
    value: TimeOfDay.Morning,
  },
  {
    label: "Afternoon",
    value: TimeOfDay.Afternoon,
  },
];

export function Availability({ form, setForm }: SectionProps) {
  return (
    <div css={Css.bgWhitePure.br8.shadowBasic.pxPx(99).pt6.pb4.mbPx(61).ifXs.px3.pt4.pb1.$}>
      <div css={Css.f16.fw3.lh("24px").gray700.add("letterSpacing", "unset").mb3.$}>
        We'll try to coordinate and schedule trades during your preferred time slots.
      </div>
      {daysOfWeek.map((day) => (
        <div css={Css.mb3.df.aic.$} key={day.value}>
          <div css={Css.f14.fw7.lh("24px").gray800.add("letterSpacing", "0.28px").$}>{day.label}</div>
          <div css={Css.df.gap1.mla.mw("50%").$}>
            {timeOfDay.map((time) => {
              const selected =
                form.availabilities.filter((s) => s.timeOfDay === time.value && s.dayOfWeek === day.value).length > 0;
              return (
                <Button
                  key={`${day.value}${time.label}`}
                  type="button"
                  variant="tertiary"
                  state={selected ? "active" : undefined}
                  onClick={() => {
                    const currentAvailabilities = form.availabilities;
                    const newAvailabilities = currentAvailabilities.filter(
                      (s) => !(s.timeOfDay === time.value && s.dayOfWeek === day.value),
                    );
                    // If the value wasn't removed then add it
                    if (currentAvailabilities.length === newAvailabilities.length) {
                      newAvailabilities.push({
                        dayOfWeek: day.value,
                        timeOfDay: time.value,
                        id: undefined,
                        warrantyTicket: undefined,
                      });
                    }
                    setForm({ ...form, availabilities: newAvailabilities });
                  }}
                >
                  {time.label}
                </Button>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
}
