import { withStyles } from "@material-ui/core";
import MuiAccordion, { AccordionProps as MuiAccordionProps } from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { ExpandMore } from "@material-ui/icons";
import React from "react";
import { Css, Margin, Only, Padding, Xss } from "src/Css";

export type AccordionXss = Xss<Margin | Padding>;
export interface AccordionProps<X extends Only<AccordionXss, X>>
  extends Pick<MuiAccordionProps, "expanded" | "defaultExpanded"> {
  title: string;
  children: React.ReactNode;
  xss?: X;
  className?: string;
  hideCarrot?: boolean;
}

/** Accordion component which animates height when opened
 * TODO:
 * - Use Framer Motion to remove Mui dependency
 * - Move icon into HOP library
 */
export function Accordion<X extends Only<AccordionXss, X>>(props: AccordionProps<X>) {
  const { title, children, className, hideCarrot = false, ...otherProps } = props;
  return (
    <StyledAccordion className={className} {...otherProps}>
      <AccordionSummary className={className} expandIcon={<ExpandMore css={Css.if(hideCarrot).dn.$} />}>
        {title}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
  );
}

const RawAccordion = React.forwardRef<HTMLButtonElement, any>((props, ref) => {
  const { xss, className, ...otherProps } = props;
  return <MuiAccordion square ref={ref} css={xss} className={className} {...otherProps} />;
});

const StyledAccordion = withStyles(accordionStyles)(RawAccordion);
function accordionStyles() {
  return {
    root: {
      ...Css.p2.m0.bgTransparent.shadowNone.bt.bDarkTransparentGray.$,
      "&:last-child": Css.bb.bDarkTransparentGray.$,
      "&:before": {
        backgroundColor: "unset", // removes MUI accordion borders that are replaced with our styles
      },
    },
    expanded: Css.m0.important.bt.bDarkTransparentGray.$,
  };
}

const AccordionSummary = withStyles(accordionSummaryStyles)(MuiAccordionSummary);
function accordionSummaryStyles() {
  return {
    root: Css.p0.mh0.$,
    content: {
      ...Css.m0.t16.$,
      ".list > &": Css.t12up.gray600.$,
    },
    expandIcon: Css.p0.mr0.$,
    expanded: Css.mh0.important.m0.$,
  };
}

const AccordionDetails = withStyles(accordionDetailsStyles)(MuiAccordionDetails);
function accordionDetailsStyles() {
  return {
    root: Css.mt3.p0.$,
  };
}
