// DEPRECATED
export enum FontSizesInPixels {
  F1 = 108,
  F2 = 96,
  F3 = 72,
  F4 = 48,
  F5 = 32,
  F6 = 24,
  F7 = 18,
  F8 = 16,
  F9 = 14,
  F10 = 12,
  F11 = 10,
}

export enum FontSize {
  PX108 = 108,
  PX96 = 96,
  PX72 = 72,
  PX48 = 48,
  PX32 = 32,
  PX24 = 24,
  PX18 = 18,
  PX16 = 16,
  PX14 = 14,
  PX12 = 12,
  PX10 = 10,
}

// TEMP: Replace this block with the line below when we're ready to kill the old F-key notation
// export type FontSizes = keyof typeof FontSize;
enum FontSizesAll {
  F1 = 108,
  F2 = 96,
  F3 = 72,
  F4 = 48,
  F5 = 32,
  F6 = 24,
  F7 = 18,
  F8 = 16,
  F9 = 14,
  F10 = 12,
  F11 = 10,
  PX108 = 108,
  PX96 = 96,
  PX72 = 72,
  PX48 = 48,
  PX32 = 32,
  PX24 = 24,
  PX18 = 18,
  PX16 = 16,
  PX14 = 14,
  PX12 = 12,
  PX10 = 10,
}
export type FontSizes = keyof typeof FontSizesAll;
// END TEMP

// Generally LineHeight of FN == the FontSizesInPixels.F(N - 1)
export const LineHeight: Record<FontSizes, number> = {
  PX108: 108,
  PX96: FontSize.PX108,
  PX72: FontSize.PX96,
  PX48: 26,
  PX32: 35,
  PX24: FontSize.PX32,
  PX18: 20,
  PX16: FontSize.PX18,
  PX14: FontSize.PX16,
  PX12: FontSize.PX14,
  PX10: FontSize.PX12,
  // DEPRECATED:
  F1: 108,
  F2: FontSizesInPixels.F1,
  F3: FontSizesInPixels.F2,
  F4: 26,
  F5: 35,
  F6: FontSizesInPixels.F5,
  F7: 20,
  F8: FontSizesInPixels.F7,
  F9: FontSizesInPixels.F8,
  F10: FontSizesInPixels.F9,
  F11: FontSizesInPixels.F10,
};

export const LetterSpacing: Record<FontSizes, number> = {
  PX108: -1.6,
  PX96: -1.6,
  PX72: -1.6,
  PX48: -1.6,
  PX32: -1.6,
  PX24: -1.6,
  PX18: -0.8,
  PX16: -0.8,
  PX14: -0.8,
  PX12: -0.8,
  PX10: -0.8,
  // DEPRECATED
  F1: -1.6,
  F2: -1.6,
  F3: -1.6,
  F4: -1.6,
  F5: -1.6,
  F6: -1.6,
  F7: -0.8,
  F8: -0.8,
  F9: -0.8,
  F10: -0.8,
  F11: -0.8,
};

export const FontSansSerif = ["Good Sans", "Helvetica Neue", "Helvetica", "Arial", "sans-serif"].join(",");
