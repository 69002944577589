import { Dialog, DialogProps, useMediaQuery, withStyles } from "@material-ui/core";
import { IconButton, TextButton } from "src/components/Button";
import { Icon } from "src/components/Icons";
import { Breakpoints, Css, increment, px } from "src/Css";

export interface ModalProps extends Pick<DialogProps, "children" | "open" | "fullScreen" | "fullWidth" | "onClose"> {
  title?: string;
  className?: string;
}

/** Modal component with HOP specific defaults */
export function Modal(props: ModalProps) {
  const { title, children, ...otherProps } = props;
  const isMobile = useMediaQuery(Breakpoints.xs);

  return (
    <StyledModal fullScreen={isMobile} disableRestoreFocus keepMounted {...otherProps}>
      <div css={Css.df.fdc.h100.$}>
        <Header onClick={props.onClose} isMobile={isMobile}>
          {title}
        </Header>
        <div
          css={{
            ...Css.fg1.$,
            overflow: "auto",
          }}
        >
          {children}
        </div>
      </div>
    </StyledModal>
  );
}

/** Modal header component which renders only when parent title prop is present. */
function Header(props: any) {
  const { onClick, isMobile, children } = props;
  return children ? (
    <div css={Css.df.aic.jcsb.mb3.h(7).$}>
      <h3 css={Css.t18.$}>{children}</h3>
      {!isMobile ? (
        <IconButton onClick={onClick}>
          <Icon icon="close" />
        </IconButton>
      ) : (
        <TextButton onClick={onClick}>Close</TextButton>
      )}
    </div>
  ) : null;
}

const StyledModal = withStyles(modalStyles)(BaseModal);

function BaseModal(props: DialogProps) {
  return <Dialog {...props} />;
}

function modalStyles() {
  return {
    paper: Css.br3.shadowNone.m0.bgWhite.$,
    paperScrollPaper: {
      // 100% height - (marginTop 48px + marginBottom 48px)
      maxHeight: `calc(100% - ${px(increment(6 * 2))})`,
      // Min of 1024px or 100% width - (marginTop 48px + marginBottom 48px)
      maxWidth: `min(${px(increment(128))}, calc(100% - ${px(increment(6 * 2))}))`,
      // Set the min width and height for the modal to be 50% of the associated height and width of the page
      ...Css.mh("50%").mw("50%").$,
      ...Css.p4.$,
    },
    paperFullScreen: Css.mh100.mw100.p2.br0.$,
  };
}
