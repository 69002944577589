import { ReactNode } from "react";
import { usePreventScroll } from "react-aria";
import { Css, Palette } from "src/Css";
import { useTestIds } from "src/hooks/useTestIds";

type ModalProps = {
  bg?: Palette;
  children?: ReactNode;
};

export function ModalWrapper(props: ModalProps) {
  const { bg = Palette.WhitePure, children } = props;
  const [tid] = useTestIds("modal-wrapper");
  usePreventScroll();

  return (
    <div css={Css.fixed.z9999.top0.left0.vh100.w("100vw").bgDarkTransparentGray.df.$} {...tid}>
      <div css={Css.bgColor(bg).ma.overflowYAuto.br8.ifXs.w100.h100.br0.$}>{children}</div>
    </div>
  );
}
