import { PieDatum, PieTooltip, ResponsivePie } from "@nivo/pie";
import { ReactElement } from "react";
import { Css, px } from "src/Css";
import { centsToDollars, generatedColors } from "src/utils";
import tinycolor from "tinycolor2";

const tooltipHeight = 50;
const tooltipBr = 50 / 2;

const theme = {
  tooltip: {
    // removes default white box around tooltip
    container: {
      padding: 0,
      borderRadius: tooltipBr,
    },
  },
};

export interface DonutProps {
  data: PieDatum[];
  innerContent?: ReactElement;
  size: number;
}

export function Donut({ data, innerContent, size }: DonutProps) {
  return (
    <div css={Css.h(px(size)).relative.$}>
      <ResponsivePie
        {...{ data, theme }}
        colors={generatedColors}
        enableRadialLabels={false}
        enableSlicesLabels={false}
        innerRadius={0.85}
        padAngle={0.5}
        tooltip={DonutTooltip}
      />
      {/* TODO: constrain inner content to stay within the donut */}
      <div css={Css.absolute.top0.right0.bottom0.left0.df.fdc.jcc.tc.add("pointerEvents", "none").$}>
        {innerContent}
      </div>
    </div>
  );
}

export function DonutTooltip({ color, label, value }: PieTooltip) {
  // lightenAmount: more lightening for darker colors, less for lighter colors
  const lightenAmount = tinycolor(color).getBrightness() > 120 ? 30 : 45;

  return (
    <div
      css={
        Css.df.aic.jcc.pl1.pr3
          .h(px(tooltipHeight))
          .add("borderRadius", px(tooltipBr))
          .add("backgroundColor", tinycolor(color).lighten(lightenAmount).toString()).$
      }
    >
      <div css={Css.h1.w1.br100.bgColor(color).$} aria-hidden="true"></div>
      <div css={Css.pl1.t14.tl.$}>
        <div>{label}</div>
        <div css={Css.gray600.$}>{centsToDollars(value, 0)}</div>
      </div>
    </div>
  );
}
