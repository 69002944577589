import {
  HomeownerSelectionStatus,
  ListSelectionDetailsFragment,
  SelectionDetailsFragment,
  SelectionsByLocationDetailsFragment,
  SelectionsByLocationFragment,
} from "src/generated/graphql-types";

// https://github.com/homebound-team/graphql-service/blob/main/src/utils.ts#L88-L102
export function groupBy<T, Y = T>(
  list: readonly T[],
  fn: (x: T) => string,
  valueFn?: (x: T) => Y,
): Record<string, Y[]> {
  const result: Record<string, Y[]> = {};
  list.forEach((o) => {
    const group = fn(o);
    if (result[group] === undefined) {
      result[group] = [];
    }
    result[group].push(valueFn === undefined ? (o as any as Y) : valueFn(o));
  });
  return result;
}

/**
 * Maps the values of an object to a new object.
 * https://github.com/homebound-team/graphql-service/blob/main/src/utils.ts#L117-L119
 * */
export function mapValues<T, U>(record: Record<string, T>, fn: (t: T) => U): Record<string, U> {
  return Object.fromEntries(Object.entries(record).map(([key, value]) => [key, fn(value)]));
}

/** Turns a user-friendly label in an id appropriate for HTML inputs/labels. */
export function makeId(label: string): string {
  return label.replace(" ", "").toLowerCase().replace(/\W/g, "");
}

export function sortText(a: string, b: string) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

/** Given homeowner selections, return the un finalized selections */
export function findUnfinalizedSelections(selections: ListSelectionDetailsFragment[]) {
  return selections.filter(
    ({ status }) =>
      status.code === HomeownerSelectionStatus.Undecided || status.code === HomeownerSelectionStatus.Selected,
  );
}

/** Given an id and selections, returns the index of the selection that matched the id */
export function findSelectionIndex(id: string, selections: SelectionDetailsFragment[]) {
  return selections.findIndex((selection) => selection.id === id);
}

type MaybeWithSpace = { location?: { id: string } };

export function getSpace<T extends MaybeWithSpace>(selectionsBySpace?: T[], spaceId?: string) {
  return selectionsBySpace?.find(({ location }) => location?.id === spaceId);
}

export function getSpaceSelections(
  selectionsByLocation?: (SelectionsByLocationDetailsFragment | SelectionsByLocationFragment)[],
  spaceId?: string,
) {
  return (
    getSpace<SelectionsByLocationDetailsFragment | SelectionsByLocationFragment>(selectionsByLocation, spaceId)
      ?.selections ?? []
  );
}
