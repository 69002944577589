import { Redirect, useParams } from "react-router-dom";
import { PageContent, QueryResultHandler } from "src/components";
import { Css } from "src/Css";
import { JourneyPageProjectFragment, JourneyPageQuery, useJourneyPageQuery } from "src/generated/graphql-types";
import { homeownerNoteRoute, journeyRoute, milestoneRoute } from "src/routes";

type TaskRouteProps = {
  projectId: string;
  homeownerNoteId?: string;
  unitId?: string;
  taskId: string;
};

export function TaskPage() {
  const { projectId } = useParams<TaskRouteProps>();
  const result = useJourneyPageQuery({
    variables: {
      projectId,
    },
  });

  return QueryResultHandler<JourneyPageQuery>({
    result,
    render: (data) => <TaskPageView homeownerProject={data.homeownerProject} />,
  });
}

type TaskPageViewProps = {
  homeownerProject: JourneyPageProjectFragment;
};

export function TaskPageView({ homeownerProject }: TaskPageViewProps) {
  const { homeownerNoteId, unitId: schedulePhaseId, projectId, taskId } = useParams<TaskRouteProps>();
  let task;
  if (schedulePhaseId) {
    const schedulePhase = homeownerProject.stage.schedulePhases.find((sp) => sp.id === schedulePhaseId);
    if (!schedulePhase) return <Redirect to={journeyRoute(projectId)} />;
    task = schedulePhase.scheduleSubPhases.flatMap((ssp) => ssp.scheduleTasks).find((t) => t.id === taskId);
    if (!task) return <Redirect to={milestoneRoute(projectId, schedulePhase.id)} />;
  } else {
    const homeownerNote = homeownerProject.homeownerNotes.find((hn) => hn.id === homeownerNoteId);
    if (!homeownerNote) return <Redirect to={journeyRoute(projectId)} />;
    task = homeownerNote.scheduleTasks.find((t) => t.id === taskId);
    if (!task) return <Redirect to={homeownerNoteRoute(projectId, homeownerNote.id)} />;
  }

  return (
    <PageContent>
      <div css={Css.mt7.mxa.maxwPx(500).$}>
        <h5 css={Css.f24.$}>{task.name}</h5>
        <div css={Css.mt3.$} dangerouslySetInnerHTML={{ __html: task.defaultedHomeownerDescription }}></div>
      </div>
    </PageContent>
  );
}
