import { Link } from "@material-ui/core";
import { Icon } from "src/components";
import { Css } from "src/Css";
import { DimensionDetailFragment, ProductAttribute, ProductAttributeType } from "src/generated/graphql-types";
import { useTestIds } from "src/hooks";
import { sortText } from "src/utils";

/** ItemDetailsModal overview tab content */
export interface ProductOverviewProp {
  attributes?: ProductAttribute[];
  sku?: string | null;
  dimensions?: DimensionDetailFragment[];
  manufacturerUrl?: string | null;
  description?: string | null;
}

export interface ProductOverviewProps {
  productOverview: ProductOverviewProp;
}

export function ProductOverview({ productOverview }: ProductOverviewProps) {
  const { attributes = [], sku, dimensions, manufacturerUrl, description } = productOverview;
  const [productOverviewId, finishId] = useTestIds("productOverview", ["finish"]);

  const dimension = dimensions
    ?.slice()
    .sort((a, b) => a.dimensionType.sortOrder - b.dimensionType.sortOrder)
    .map((curr) => `${curr.rawValue} ${curr.dimensionType.abbreviation}`)
    .join(" x ");

  // Extract product finish information
  const productFinishes = attributes
    .filter((a) => a.type === ProductAttributeType.Finish)
    .map((a) => a.value)
    .sort((a: any, b: any) => sortText(a, b))
    .join(", ");
  return (
    <div css={Css.t14.gray600.$} {...productOverviewId}>
      <div css={Css.add("whiteSpace", "pre-line").$}>{description}</div>
      <ul css={Css.pl2.$}>
        {sku && <li css={Css.ttc.pb1.$}>SKU: {sku}</li>}
        {dimension && <li css={Css.ttc.pb1.$}>Dimension: {dimension}</li>}
        {productFinishes !== "" && (
          <li css={Css.ttc.pb1.$} {...finishId}>
            Finish: {productFinishes}
          </li>
        )}
        {manufacturerUrl && (
          <li css={Css.pb1.$}>
            Manufacturer URL:
            <Link href={manufacturerUrl} target="_blank">
              <Icon icon="openInNew" xss={Css.h("14px").pl1.$} />
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
}
