import { Css, px } from "../Css";
import { Tooltip } from "./index";

export function DesignerAvatar({ avatarUrl }: { avatarUrl: string }) {
  const designerMessage =
    "Your designer has curated and recommended this specific piece for you based on your style and budget.";

  return (
    <Tooltip title={<div>{designerMessage}</div>} placement="left">
      <img
        css={Css.h(px(32)).w(px(32)).absolute.left2.top2.brPill.$}
        src={avatarUrl}
        alt="design avatar indicating this is a designer-recommended product"
      />
    </Tooltip>
  );
}
