import { ReactNode, useRef } from "react";
import { Icon } from "src/components";
import { Css, Palette } from "src/Css";
import { useTestIds } from "src/hooks";

export interface CheckboxProps<T> {
  children: ReactNode;
  onChange: (value: T) => void;
  value: string;
  checked?: boolean;
}

/** Checkbox component with keyboard accessibility support */
export function Checkbox<T extends string = string>(props: CheckboxProps<T>) {
  const { children, checked, onChange, value } = props;
  const [, checkboxId] = useTestIds("checkbox", [value]);
  const wrapperRef = useRef<HTMLDivElement>(null);

  function handleOnChange() {
    // Remove input outline styles when clicking on the checkmark
    wrapperRef?.current?.blur();
    onChange(value as T);
  }

  /** Triggers onChange event when pressing the space key */
  function handleKeyPress(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === " ") onChange(value as T);
  }

  return (
    <div ref={wrapperRef} css={{ ":active": Css.outline0.$ }} tabIndex={0} onKeyPress={handleKeyPress}>
      <input
        css={Css.dn.$}
        type="checkbox"
        onChange={handleOnChange}
        id={value}
        value={value}
        checked={checked}
        tabIndex={-1}
      />
      <label
        htmlFor={value}
        css={{ svg: Css.mr1.hPx(18).wPx(18).mwPx(18).$, ...Css.t14.blue.df.aic.cursorPointer.$ }}
        {...checkboxId}
      >
        <>
          {checked ? (
            <Icon icon="checkboxOutlineFill" color={Palette.Blue600} />
          ) : (
            <Icon icon="checkboxOutline" color={Palette.Black} />
          )}
          {children}
        </>
      </label>
    </div>
  );
}
