import { useMediaQuery } from "@material-ui/core";
import { AnimatePresence, motion } from "framer-motion";
import { Breakpoints, Css } from "src/Css";
import {
  HomeownerNavFragment,
  LotType,
  ProjectFeature,
  ProjectStatus,
  SearchBy,
  useHomeownerProjectNavQuery,
  useHomeownerProjectsQuery,
} from "src/generated/graphql-types";
import {
  documentsRoute,
  financesRoute,
  inboxRoute,
  journeyRoute,
  overviewRoute,
  profileRoute,
  resourcesRoute,
  spacesRoute,
  v2SelectionsRoute,
} from "src/routes";
import { Icon } from "../Icons";

type MenuProps = {
  open: boolean;
  onClick: () => void;
  projectId: string;
};

export function Menu(props: MenuProps) {
  const { open, onClick, projectId } = props;
  const { data } = useHomeownerProjectsQuery();

  const paramsProjectData = data?.homeownerProjects?.find((proj) => proj.id === projectId);

  // nav query gets data for a project that is not tied to current user (for internal users)
  const navQueryData = useHomeownerProjectNavQuery({
    variables: { searchBy: SearchBy.Project, id: projectId! },
    skip: paramsProjectData !== undefined,
  });

  const homeownerProject = navQueryData?.data?.homeownerProject || paramsProjectData;
  const { status, checkoutCustomerConfigId } = homeownerProject || {};
  const showSelections = shouldShowSelections(homeownerProject);
  const showFinances = shouldShowFinances(homeownerProject);
  const showV2SelectionsPageFlag = !!checkoutCustomerConfigId;
  const warrantyEnabled = status?.code === ProjectStatus.UnderWarranty;

  return (
    <MenuDataView
      {...{ open, onClick, projectId, showSelections, showV2SelectionsPageFlag, showFinances, warrantyEnabled }}
    />
  );
}

const itemVariants = {
  closed: {
    opacity: 0,
  },
  open: { opacity: 1 },
};

const sideVariants = {
  closed: {
    transition: {
      staggerChildren: 0.03,
      staggerDirection: 1,
    },
  },
  open: {
    transition: {
      staggerChildren: 0.17,
      staggerDirection: 1,
    },
  },
};

type MenuDataViewProps = {
  open: boolean;
  onClick: () => void;
  projectId: string;
  showSelections: boolean;
  showV2SelectionsPageFlag: boolean;
  showFinances: boolean;
  warrantyEnabled: boolean;
};

export function MenuDataView(menuProps: MenuDataViewProps) {
  const { open, onClick, projectId, showSelections, showV2SelectionsPageFlag, showFinances, warrantyEnabled } =
    menuProps;
  const height = window.innerHeight;
  const width = window.innerWidth;
  const isMobile = useMediaQuery(Breakpoints.xs);

  return (
    <AnimatePresence>
      {open && (
        <motion.aside
          css={Css.bgWhitePure.fixed.shadowBasic.hPx(height).z(1001).$}
          initial={{ width: 0 }}
          animate={{ width: isMobile ? width : 320, transition: { duration: 0.3 } }}
          exit={{
            width: 0,
            transition: { delay: 0.2, duration: 0.3 },
          }}
        >
          <motion.div css={Css.df.aic.jcsb.px3.pt3.onHover.cursorPointer.$} variants={itemVariants}>
            <Icon size={isMobile ? "18" : "24"} icon="menu" onClick={onClick} />
            <Icon icon="close" onClick={onClick} />
          </motion.div>
          <motion.div
            id="container"
            css={Css.df.fdc.jcsb.hPx(height - 48).pt3.mx6.pb5.$}
            initial="closed"
            animate="open"
            exit="closed"
          >
            {/* todo add show logic */}
            <motion.div css={Css.df.fdc.gap2.$} initial="closed" animate="open" exit="closed" variants={sideVariants}>
              <motion.a css={itemStyle} href={overviewRoute(projectId)} variants={itemVariants}>
                Overview
              </motion.a>
              <motion.a css={itemStyle} href={journeyRoute(projectId)} variants={itemVariants}>
                Journey
              </motion.a>
              {(showSelections || showV2SelectionsPageFlag) && (
                <motion.a
                  css={itemStyle}
                  href={showV2SelectionsPageFlag ? v2SelectionsRoute(projectId) : spacesRoute(projectId)}
                  variants={itemVariants}
                >
                  Selections
                </motion.a>
              )}
              {showFinances && (
                <motion.a css={itemStyle} href={financesRoute(projectId)} variants={itemVariants}>
                  Finances
                </motion.a>
              )}
              <motion.a css={itemStyle} href={documentsRoute(projectId)} variants={itemVariants}>
                Documents
              </motion.a>
              <motion.a
                css={{ ...itemStyle, ...Css.bb.pbPx(20).$ }}
                href={resourcesRoute(projectId)}
                variants={itemVariants}
              >
                Resources
              </motion.a>
            </motion.div>
            <motion.div css={Css.df.fdc.gap1.$} initial="closed" animate="open" exit="closed" variants={itemVariants}>
              <motion.a css={bottomItemStyle} href={inboxRoute(projectId)}>
                Inbox
              </motion.a>
              <motion.a css={{ ...bottomItemStyle, ...Css.pt2.bt.bTaupe.$ }} href={profileRoute(projectId)}>
                Profile
              </motion.a>
            </motion.div>
          </motion.div>
        </motion.aside>
      )}
    </AnimatePresence>
  );
}

const itemStyle = Css.header18.fw5.lh("28px").noUnderline.bt.bTaupe.ptPx(20).onHover.cursorPointer.underline.$;
const bottomItemStyle = Css.t14.fw3.lh("32px").noUnderline.onHover.cursorPointer.underline.$;

// Exported for testing
export function shouldShowSelections(homeownerProject: HomeownerNavFragment | undefined): boolean {
  const { features, hasSelections } = homeownerProject || {};
  return features ? features.includes(ProjectFeature.SelectionsV1) && !!hasSelections : false;
}

export function shouldShowFinances(homeownerProject: HomeownerNavFragment | undefined): boolean {
  const stageWithSignedContract = !!homeownerProject?.stages?.some((stage) => stage?.hasSignedContract);
  return (
    !homeownerProject?.features?.includes(ProjectFeature.HopHideFinancials) &&
    stageWithSignedContract &&
    homeownerProject?.lotType?.code !== LotType.Bool
  );
}
