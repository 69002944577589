import { useState } from "react";
import { DesignerAvatar, TextButton } from "src/components";
import { Css, px } from "src/Css";
import { ChangeSelectionModal } from "./ChangeSelectionModal";
import { ItemDetailsModal, ItemDetailsModalProps, ProductOverview, ProductOverviewProps } from "./ItemDetailsModal";
import { ProductDetails, ProductDetailsProps } from "./ProductDetails";

export interface BasicProductCardProps
  extends ProductDetailsProps,
    Pick<ItemDetailsModalProps, "commentOptions" | "designerAvatar" | "finalized" | "image" | "selected" | "locked">,
    ProductOverviewProps {
  itemName: string;
  onSelectionClick?: () => void;
  onFinalize?: () => void;
}

export function BasicProductCard({
  brand,
  commentOptions,
  designerAvatar,
  finalized,
  finish,
  image,
  itemName,
  locked,
  msrpInCents,
  name,
  onFinalize,
  onSelectionClick,
  productOverview,
  selected,
  unitOfMeasure,
  unitPriceInCents,
  priceDeltaInCents,
  pricingType,
  selectionPricingMode,
}: BasicProductCardProps) {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [openChangeSelectionModal, setOpenChangeSelectionModal] = useState(false);

  // Check that the homeowner has a finalized status; the selected option is the finalized option
  const finalizedOption = selected && finalized;

  return (
    <>
      <div
        css={
          Css.ba.bTaupe.br3.bw2.cursorPointer
            .mw(px(220))
            .maxw(px(474))
            .df.fdc.if(selected)
            .bYellowTransparent3.if(finalizedOption).bBrightGreen.$
        }
        data-testid="basicProductCard"
      >
        <div
          onClick={() => setIsDetailsModalOpen(true)}
          css={Css.fg1.df.fdc.$}
          data-testid="basicProductCardModalTarget"
        >
          <div css={Css.p2.bb.bTaupe.df.h(px(178)).bgWhitePure.jcc.relative.$}>
            {designerAvatar && <DesignerAvatar avatarUrl={designerAvatar} />}
            <img css={{ ...Css.w100.$, objectFit: "contain" }} src={image} alt={`${name}/${brand}`} />
          </div>
          <div css={Css.bb.bTaupe.df.aic.jcsb.p2.fg1.$}>
            <ProductDetails
              brand={brand}
              finish={finish}
              msrpInCents={msrpInCents}
              name={name}
              priceDeltaInCents={priceDeltaInCents}
              pricingType={pricingType}
              selectionPricingMode={selectionPricingMode}
              unitOfMeasure={unitOfMeasure}
              unitPriceInCents={unitPriceInCents}
            />
          </div>
        </div>
        <div
          css={{
            ...Css.df.h4.aic.jcc.t14.gray600.$,
            // Button styles overrides
            button: Css.w100.br0.$,
          }}
        >
          {selected && finalized && "Finalized"}
          {selected && !finalized && "Currently selected"}
          {!selected && (
            <TextButton
              data-testid="basicProductCardSelectionButton"
              onClick={() => {
                // Show confirmation modal if the option was already finalized
                finalized ? setOpenChangeSelectionModal(true) : onSelectionClick && onSelectionClick();
              }}
            >
              Select
            </TextButton>
          )}
        </div>
      </div>
      <ItemDetailsModal
        {...{
          brand,
          commentOptions,
          designerAvatar,
          finalized,
          image,
          locked,
          msrpInCents,
          name,
          selected,
          unitOfMeasure,
          unitPriceInCents,
          priceDeltaInCents,
          pricingType,
          selectionPricingMode,
        }}
        title={itemName}
        open={isDetailsModalOpen}
        onClose={() => setIsDetailsModalOpen(false)}
        overview={<ProductOverview productOverview={productOverview} />}
        onSelectionChange={() => {
          if (!finalized) {
            onFinalize && onFinalize();
          } else {
            onSelectionClick && onSelectionClick();
          }
        }}
      />
      <ChangeSelectionModal
        open={openChangeSelectionModal}
        onClose={() => setOpenChangeSelectionModal(false)}
        onConfirmSelection={() => {
          onSelectionClick && onSelectionClick();
          setOpenChangeSelectionModal(false);
        }}
      />
    </>
  );
}
