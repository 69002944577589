import { Redirect, useParams, useRouteMatch } from "react-router-dom";
import { QueryResultHandler } from "src/components";
import { HomeownerProjectsQuery, useHomeownerProjectsQuery } from "src/generated/graphql-types";
import { ProjectRouteProps } from "src/routes";
import { sortHomeownerProjects } from "src/utils";
import { REDIRECT_URL_KEY } from "../routes/Routes";

export function RedirectComponent() {
  const result = useHomeownerProjectsQuery({ fetchPolicy: "cache-first" });
  const { projectId } = useParams<ProjectRouteProps>();
  const { isExact, path } = useRouteMatch();

  function getPath(isProjectIdParamValid: boolean, defaultProjectId: string) {
    const id = isProjectIdParamValid ? projectId : defaultProjectId;

    // If we're mounted on an exact path (i.e. /team), use that as the prefix, otherwise default to /overview
    const suffix = !isExact || path === "/" ? "/overview" : path;

    return `/project/${id}${suffix}`;
  }

  return QueryResultHandler<HomeownerProjectsQuery>({
    result,
    render: (data) => {
      const defaultProject = findDefaultProject(data);

      const isProjectIdParamValid = checkProjectIdValidity(projectId);

      if (!defaultProject && !isProjectIdParamValid) {
        return <div>Sorry, you currently don't have any projects.</div>;
      }

      let redirectPath = getPath(isProjectIdParamValid, defaultProject?.id);

      const redirectUrl = localStorage.getItem(REDIRECT_URL_KEY);

      if (redirectUrl) {
        redirectPath = redirectUrl;
        localStorage.setItem(REDIRECT_URL_KEY, ""); // unset
      }

      return <Redirect to={redirectPath} />;
    },
  });
}

// default is project with the earliest start date
export function findDefaultProject(data: HomeownerProjectsQuery) {
  const [defaultProject] = sortHomeownerProjects(data.homeownerProjects);
  return defaultProject;
}

// projectId param must be a number or in the format "p:{number}"
export function checkProjectIdValidity(projectId: string | undefined) {
  // @ts-ignore
  return /(^p:\d+$)|(^\d+$)/.test(projectId);
}
