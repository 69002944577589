import { Card, Tooltip } from "src/components";
import { Icon } from "src/components/Icons";
import { Css, Palette, px } from "src/Css";
import { Stage } from "src/generated/graphql-types";
import { centsToDollars, HomeownerStages } from "src/utils";

export interface FinanceSummaryCardProps {
  homeownerPriceInCents: number;
  amountPaidInCents: number;
  stage: Stage;
}

export function FinanceSummaryCard({ homeownerPriceInCents, amountPaidInCents, stage }: FinanceSummaryCardProps) {
  const remaining = homeownerPriceInCents - amountPaidInCents;
  const hopStage = HomeownerStages[stage];

  return (
    <Card isClickable={false} xss={Css.df.p3.jcsb.aic.w100.bgWhitePure.$}>
      <SummarySection
        title="Contract Price"
        valueInCents={homeownerPriceInCents}
        tooltipText={`Contract Price is the total cost that you have committed to in all contracts and change orders for ${hopStage}.`}
      />
      <SummarySection
        title="Paid to date"
        valueInCents={amountPaidInCents}
        tooltipText={`Paid to Date is the total amount that you have already paid towards ${hopStage}.`}
      />
      <SummarySection
        title="Remaining"
        valueInCents={remaining}
        tooltipText={`Remaining is the total amount left to pay for ${hopStage}. Remaining equals Contract Price minus Paid to Date. `}
      />
    </Card>
  );
}

interface SummarySectionProps {
  title: string;
  valueInCents: number;
  tooltipText: string;
}

function SummarySection({ title, valueInCents, tooltipText }: SummarySectionProps) {
  return (
    <div>
      <div css={Css.df.$}>
        <div css={Css.pr1.pt(px(4)).t18.$}>{centsToDollars(valueInCents, 2)}</div>
        <Tooltip title={tooltipText} placement="bottom">
          <div css={Css.w(px(16)).h(px(28)).$}>
            <Icon icon="circleQuestionMark" xss={Css.cursorPointer.$} tabIndex={0} color={Palette.Gray600} />
          </div>
        </Tooltip>
      </div>
      <div css={Css.t12up.gray600.$}>{title}</div>
    </div>
  );
}
