import DOMPurify from "dompurify";

// hook to safely open links in new window
DOMPurify.addHook("afterSanitizeAttributes", function (node: any) {
  // set all elements owning target to target=_blank and add rel="noopener noreferrer"
  if ("target" in node) {
    node.setAttribute("target", "_blank");
    node.setAttribute("rel", "noopener noreferrer");
  }
});

export function sanitizeHtml(text: string) {
  return DOMPurify.sanitize(text);
}
