import { Redirect, useParams } from "react-router-dom";
import { PageContent, QueryResultHandler } from "src/components";
import { Css } from "src/Css";
import {
  HomeownerFinanceDetailsQuery,
  HomeownerProjectItemsFragment,
  SearchBy,
  Stage,
  useHomeownerFinanceDetailsQuery,
} from "src/generated/graphql-types";
import { financesRoute, FinancesRouteProps } from "src/routes";
import { DetailTableGroup, FinanceDetailsTable, FinanceSummaryCard } from "./components";
import { supportedSwitcherOptions } from "./Finances";

export function FinanceDetails() {
  const { projectId, selectedStage } = useParams<FinancesRouteProps>();
  const stageCode =
    supportedSwitcherOptions.find((option) => option.stageParam === selectedStage)?.code || Stage.PreConExecution;

  const result = useHomeownerFinanceDetailsQuery({
    variables: { searchBy: SearchBy.Project, projectId, stage: stageCode },
  });

  return QueryResultHandler<HomeownerFinanceDetailsQuery>({
    result,
    render: (data) => <FinanceDetailsDataView {...{ data }} />,
  });
}

export interface FinanceDetailsDataViewProps {
  data: HomeownerFinanceDetailsQuery;
}

export function FinanceDetailsDataView({ data }: FinanceDetailsDataViewProps) {
  const { id: projectId, stages } = data.homeownerProject;

  if (!stages[0]) return <Redirect to={financesRoute(projectId)} />;

  const {
    homeownerFinancials: { homeownerPriceInCents, amountPaidInCents },
    stage,
    projectItems,
  } = stages[0];

  const groups = groupByDivision(projectItems);

  return (
    <PageContent>
      <div data-testid="financeDetailsPage">
        <div css={Css.mb4.mt2.$}>
          <FinanceSummaryCard {...{ amountPaidInCents, homeownerPriceInCents, stage: stage.code }} />
        </div>
        <div css={Css.fg1.add("overflowY", "auto").$}>
          <FinanceDetailsTable groups={groups} />
        </div>
      </div>
    </PageContent>
  );
}

export function groupByDivision(projectItems: HomeownerProjectItemsFragment[]): DetailTableGroup[] | [] {
  return (
    projectItems?.reduce((acc: DetailTableGroup[], pi: HomeownerProjectItemsFragment) => {
      const currentDivision = pi.item.costCode.division;
      const costCodes = {
        costCode: pi.item.costCode,
        contractPriceInCents: pi.totalPriceInCents || 0,
        paidToDateInCents: pi.homeownerPaidInCents || 0,
      };

      const group = acc.find((d: DetailTableGroup) => currentDivision.id === d.division.id);
      const repeatCostCode = group?.costCodes.find((cc) => cc.costCode.id === pi.item.costCode.id);
      if (!group) {
        acc.push({ division: currentDivision, costCodes: [costCodes] });
      } else if (repeatCostCode) {
        repeatCostCode.contractPriceInCents += pi.totalPriceInCents;
        repeatCostCode.paidToDateInCents += pi.homeownerPaidInCents || 0;
      } else {
        group.costCodes.push(costCodes);
      }

      return acc;
    }, []) || []
  );
}
