import { useState } from "react";
import { useParams } from "react-router-dom";
import { DocumentModal, PageContent, PageTitle, QueryResultHandler } from "src/components";
import { Css } from "src/Css";
import {
  DocumentType,
  HomeownerDocumentFragment,
  HomeownerDocumentsQuery,
  SearchBy,
  useHomeownerDocumentsQuery,
} from "src/generated/graphql-types";
import { ProjectRouteProps } from "src/routes";
import { shortMonthDateFormat } from "src/utils";
import { ArrayParam, useQueryParams } from "use-query-params";
import { DocumentFilterFlyIn, DocumentResults, DocumentsHeader } from "./components";

export function Documents() {
  const { projectId } = useParams<ProjectRouteProps>();
  const result = useHomeownerDocumentsQuery({ variables: { searchBy: SearchBy.Project, projectId } });
  const [query] = useQueryParams({ documentType: ArrayParam });

  const initialDocumentTypeFilters = queryParamsToDocumentTypes(query.documentType);

  return QueryResultHandler<HomeownerDocumentsQuery>({
    result,
    render: ({ homeownerProject: { documents } }) => (
      <DocumentsDataView {...{ documents, initialDocumentTypeFilters }} />
    ),
  });
}

export interface DocumentsDataViewProps {
  initialDocumentTypeFilters?: DocumentType[];
  documents?: HomeownerDocumentFragment[];
}

export interface HomeownerDocument extends HomeownerDocumentFragment {
  executionDate?: Date;
}

/** Documents page view */
export function DocumentsDataView({ documents, initialDocumentTypeFilters }: DocumentsDataViewProps) {
  const [documentTypeFilter, setDocumentTypeFilter] = useState<DocumentType[]>(initialDocumentTypeFilters ?? []);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<DocumentInfo | undefined>(undefined);
  // v2 document refresh new state vars
  const [sortOrder, setSortOrder] = useState<"DESC" | "ASC">("DESC");
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [layoutView, setLayoutView] = useState<"tile" | "list">("tile");

  // Hydrate documents with homeowner contract execution dates
  const homeownerDocuments =
    documents?.map((d) => ({
      executionDate: d.homeownerContract?.executionDate || d.homeownerContractChangeOrder?.executionDate,
      ...d,
    })) ?? [];

  function handleModalOpen(document: HomeownerDocument) {
    setSelectedDocument(formatHomeownerDocument(document));
    setModalOpen(true);
  }

  return (
    <>
      <PageContent>
        <PageTitle title="Documents" />
        <div css={Css.df.fdc.w100.mb2.$}>
          {/* View and Filter controls */}
          <DocumentsHeader
            layoutView={layoutView}
            onLayoutChange={setLayoutView}
            onOpenFilters={setFiltersOpen}
            filtersOpen={filtersOpen}
          />
          {/* MAIN BODY */}
          <div css={Css.df.gap2.w100.pt3.$}>
            {/* CONDITIONAL FILTER CONTAINER */}
            {filtersOpen && (
              <DocumentFilterFlyIn
                checkedTypes={documentTypeFilter}
                documents={homeownerDocuments}
                onFilterChange={setDocumentTypeFilter}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                onClose={() => setFiltersOpen(false)}
              />
            )}
            {/* GROW CONTAINER */}
            {/* if(filtersOpen) here prevents scrolling on mobile when the filters are open */}
            <div css={Css.w100.ifXs.if(filtersOpen).overflowHidden.maxh0.$}>
              <DocumentResults
                documents={homeownerDocuments.filter(({ documentType }) =>
                  documentTypeFilter.length ? documentTypeFilter.includes(documentType.code) : true,
                )}
                onModalOpen={handleModalOpen}
                sortOrder={sortOrder}
                tileView={layoutView === "tile"}
                filtersOpen={filtersOpen}
              />
            </div>
          </div>
        </div>
      </PageContent>
      {selectedDocument && (
        <DocumentModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} document={selectedDocument} />
      )}
    </>
  );
}

/** Formats the homeowner document to the DocumentModal component document format */
export function formatHomeownerDocument({
  displayName: title,
  asset,
  createdAt,
  executionDate,
}: HomeownerDocument): DocumentInfo {
  return {
    title,
    subTitle: executionDate
      ? `Executed ${shortMonthDateFormat(executionDate, true)}`
      : `Added ${shortMonthDateFormat(createdAt)}`,
    url: asset.attachmentUrl,
    contentType: asset.contentType,
    previewUrl: asset.previewUrl ?? undefined,
  };
}

export function queryParamsToDocumentTypes(params: (string | null)[] | null | undefined): DocumentType[] {
  if (!params) {
    return [];
  }
  const docTypes = params
    .map((p) => {
      if (p) {
        return DocumentType[p as keyof typeof DocumentType];
      }
      return undefined;
    })
    .filter((t) => t);

  return docTypes as DocumentType[];
}

export interface DocumentInfo {
  contentType: string;
  subTitle?: string;
  title: string;
  url: string;
  previewUrl?: string;
}
