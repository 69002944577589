import { Link, useParams } from "react-router-dom";
import { SwitcherAppBar } from "src/components";
import { Icon } from "src/components/Icons";
import { Breakpoints, Css, increment, px } from "src/Css";
import { ListSelectionDetailsFragment, LocationDetailsFragment } from "src/generated/graphql-types";
import { useSelectionsBySpace } from "src/hooks";
import { selectionRoute, SpaceRouteProps } from "src/routes";
import { SpaceCard } from "./components";

export function SpacesView() {
  const selectionsBySpace = useSelectionsBySpace();

  return <SpacesDataView selectionsBySpace={selectionsBySpace} />;
}

interface SpacesDataViewProps {
  selectionsBySpace?: HomeownerSpaceSelection[];
}

interface HomeownerSpaceSelection {
  location: LocationDetailsFragment;
  finalizedSelectionCount: number;
  totalSelectionCount: number;
  selections: ListSelectionDetailsFragment[];
}

export function SpacesDataView({ selectionsBySpace }: SpacesDataViewProps) {
  const { projectId } = useParams<SpaceRouteProps>();

  return (
    <div css={Css.df.fdc.fg1.p4.add("overflow", "auto").$}>
      {/* TODO: add onClick function when another option is added */}
      <SwitcherAppBar options={[{ title: "Spaces", icon: <Icon icon="home" /> }]} onClick={() => {}} />
      <div
        css={{
          ...Css.dg.add("gridGap", px(increment(2))).$,
          [Breakpoints.mdAndUp]: Css.add("gridGap", px(increment(3))).add(
            "gridTemplateColumns",
            "repeat(auto-fit, minmax(320px, 1fr))",
          ).$,
        }}
      >
        {selectionsBySpace?.map(({ location, finalizedSelectionCount, totalSelectionCount, selections }) => {
          // Check if we have any "unlocked" selection
          const locked = !selections.some((selection) => selection.canEdit.allowed);
          return (
            <Link to={selectionRoute(projectId, location.id)} css={Css.noUnderline.$} key={location.id}>
              <SpaceCard
                title={location.name}
                finalizedNum={finalizedSelectionCount}
                totalNum={totalSelectionCount}
                imageSrc={location?.image?.downloadUrl}
                locked={locked}
              />
            </Link>
          );
        })}
      </div>
    </div>
  );
}
