import React from "react";

export interface BoxProps extends React.DOMAttributes<HTMLElement> {
  alignItems?: "center" | "flex-end" | "flex-start" | undefined;
  bb?: string | undefined;
  bc?: string | undefined;
  display?: "flex" | "block" | undefined;
  flexDirection?: "row" | "column" | undefined;
  flexGrow?: number | undefined;
  flexWrap?: "nowrap" | "wrap" | "wrap-reverse";
  height?: number | string;
  justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly" | undefined;
  m?: number;
  maxHeight?: number | string;
  maxWidth?: number | string;
  mb?: number;
  ml?: number;
  mr?: number;
  mt?: number;
  mx?: number;
  my?: number;
  p?: number;
  pb?: number;
  pl?: number;
  pr?: number;
  pt?: number;
  px?: number;
  py?: number;
  tag?: React.ElementType;
  whiteSpace?: "normal" | "nowrap" | "pre" | "pre-line" | "pre-wrap" | "initial" | "inherit" | undefined;
  width?: number | string;
  className?: string;
}

export const Box: React.FC<BoxProps> = (props) => {
  const {
    alignItems,
    bb,
    bc,
    children,
    display,
    flexDirection,
    flexGrow,
    flexWrap,
    height,
    justifyContent,
    m,
    maxHeight,
    maxWidth,
    mb,
    ml,
    mr,
    mt,
    mx,
    my,
    p,
    pb,
    pl,
    pr,
    pt,
    px,
    py,
    tag: Tag = "div", // Default to "div" if no custom tag is given
    whiteSpace,
    width,
    ...otherProps
  } = props;

  return (
    <Tag
      css={{
        margin: themeSpacing(m),
        marginBottom: themeSpacing(mb || my),
        marginLeft: themeSpacing(ml || mx),
        marginRight: themeSpacing(mr || mx),
        marginTop: themeSpacing(mt || my),
        padding: themeSpacing(p),
        paddingBottom: themeSpacing(pb || py),
        paddingLeft: themeSpacing(pl || px),
        paddingRight: themeSpacing(pr || px),
        paddingTop: themeSpacing(pt || py),
        borderBottom: bb,
        borderColor: bc,
        width,
        maxWidth,
        height,
        maxHeight,
        display,
        alignItems,
        flexGrow,
        justifyContent,
        flexDirection,
        flexWrap,
        whiteSpace,
      }}
      {...otherProps}
    >
      {children}
    </Tag>
  );
};

function themeSpacing(value: number | undefined) {
  return value !== undefined ? value * 8 : undefined;
}
