import { Palette } from "src/Css";
import { Colors } from "src/theme/palettes";

const DEFAULT_ICON_WIDTH = 36;

export interface HomeboundIconProps {
  width?: number;
  height?: number;
  color?: Colors;
}

export function HomeboundIcon(props: HomeboundIconProps) {
  const color = props.color || Palette.Black;
  const width = props.width ? props.width : props.height ? "auto" : DEFAULT_ICON_WIDTH;

  return icon(color, width, props.height ? props.height : "auto");
}

function icon(fill: string, width: number | "auto", height: number | "auto") {
  return (
    <svg width={width} height={height} viewBox="0 0 158.1 97.6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M158.1,97.6H0.2L0,39.7L76.8,0l68,38.7l-3.1,5.3l-65-37L6.2,43.4l0.2,48h151.7V97.6z" fill={fill} />
    </svg>
  );
}
