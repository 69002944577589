import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { AssetGallery, Icon, ImageCarousel, LinkButton, PageContent, QueryResultHandler } from "src/components";
import { Css } from "src/Css";
import {
  JourneyNoteFragment,
  JourneyPageQuery,
  JourneyPhaseFragment,
  useJourneyPageQuery,
} from "src/generated/graphql-types";
import { homeownerNoteRoute, milestoneRoute, ProjectRouteProps } from "src/routes";
import { longMonthDateFormat, MONTH_LABELS } from "src/utils";
import { JourneyTimelineHeader, NoHomeownerNotesIllustration } from "./components";
import { getSchedulePhaseInfo } from "./utils/schedulePhaseInfo";

export function JourneyPage() {
  const { projectId } = useParams<ProjectRouteProps>();
  const result = useJourneyPageQuery({
    variables: {
      projectId,
    },
  });

  return QueryResultHandler<JourneyPageQuery>({
    result,
    render: (data) => {
      const { homeownerNotes, stage } = data.homeownerProject;
      return (
        <JourneyV2DataView projectId={projectId} homeownerNotes={homeownerNotes} milestones={stage.schedulePhases} />
      );
    },
  });
}

type JourneyV2DataViewProps = {
  homeownerNotes: JourneyNoteFragment[];
  milestones: JourneyPhaseFragment[];
  projectId: string;
};

// TODO: [MOBILE] Do not forget about checking useReducedMotion for scroll
function JourneyV2DataView({ homeownerNotes, projectId, milestones }: JourneyV2DataViewProps) {
  const monthsToDisplay = useMemo(() => {
    return getHomeownerNoteMonthsForDisplay(homeownerNotes);
  }, [homeownerNotes]);

  // Memoizing to prevent a content jump caused by `setMonthInView` triggering all monthSections to attempt a re-render
  const NoteList = useMemo(() => {
    return (
      <div css={Css.mtPx(60).$}>
        {Object.entries(monthsToDisplay)
          .reverse()
          .map(([year, months]) => {
            const ms = Object.entries(months).reverse();
            return ms.map(([month, notes]) => (
              <MonthSection
                key={`${year}-${month}`}
                month={month}
                year={year}
                notes={notes.reverse()}
                projectId={projectId}
              />
            ));
          })}
      </div>
    );
  }, [monthsToDisplay, projectId]);

  return (
    <PageContent>
      <div css={Css.mh("100vh").$}>
        <div
          css={{
            ...Css.pb4.z1.h100.$,
            // Give the final month section some bottom space to help with scroll related events
            ...Css.addIn("> div:last-child", Css.mbPx(100).$).$,
          }}
        >
          <JourneyTimelineHeader projectId={projectId} schedulePhases={milestones} />
          {homeownerNotes?.length > 0 ? NoteList : <NoNotes />}
        </div>
      </div>
    </PageContent>
  );
}

function getHomeownerNoteMonthsForDisplay(homeownerNotes: JourneyNoteFragment[]) {
  let timelinePoints: TimelinePoints = {};

  homeownerNotes.forEach((note) => {
    const noteYear = new Date(note.createdAt).getFullYear();
    const noteMonth = new Date(note.createdAt).getMonth();
    const pointExists = timelinePoints?.[noteYear]?.[noteMonth] !== undefined;
    if (pointExists) {
      timelinePoints[noteYear][noteMonth].push(note);
    } else {
      timelinePoints[noteYear] = { ...timelinePoints[noteYear], [noteMonth]: [note] };
    }
  });

  return timelinePoints;
}

function NoNotes() {
  return (
    <div css={Css.df.fdc.aic.w100.mt7.$}>
      {NoHomeownerNotesIllustration}
      <p css={Css.tc.t16.gray600.mt3.$}>
        Updates are on the way!
        <br />
        Stay tuned.
      </p>
    </div>
  );
}

type MonthSectionProps = {
  month: string;
  year: string;
  notes: JourneyNoteFragment[];
  projectId: string;
};
const MonthSection = ({ month, year, notes, projectId }: MonthSectionProps) => {
  if (notes.length === 0) return null;

  return (
    <div id={`month-section-${month}-${year}`} css={Css.relative.z0.pl4.ifXs.pl0.$}>
      <div
        id={`heading-${month}-${year}`}
        css={
          Css.cursor("none").absolute.z1.taupe.add("fontSize", "100px").lh("96px").add("letterSpacing", "2px").fSerif.$
        }
      >
        {MONTH_LABELS[Number(month)].short}
      </div>
      <div
        // `padding-top: 70px` is sweet spot to line up first note date with month
        css={Css.relative.df.fdc.z2.ptPx(70).pl4.pb4.gapPx(70).$}
      >
        {notes.map((note) => (
          <HomeownerNote homeownerNote={note} projectId={projectId} key={note.id} />
        ))}
      </div>
    </div>
  );
};

type HomeownerNoteProps = {
  homeownerNote: JourneyNoteFragment;
  projectId: string;
};

// NOTE: What we call `SchedulePhases` are shown to our homeowners as `Milestones`
const HomeownerNote = ({ homeownerNote, projectId }: HomeownerNoteProps) => {
  const { id, createdAt, title, description, schedulePhases, jobLogImages } = homeownerNote;

  const numSchedulePhases = schedulePhases.length;

  return (
    <div css={Css.df.fdc.w100.gap1.$}>
      {/* Date */}
      <div css={Css.f12.lh("12px").add("letterSpacing", "0.48px").ttu.$}>{longMonthDateFormat(createdAt)}</div>
      {/* header/milestone row */}
      <div css={Css.df.gap7.pr4.jcsb.pb3.ifXs.fdc.gap3.$}>
        <div css={Css.df.fdc.gap1.$}>
          {/* Title is clickable */}
          <LinkButton
            to={homeownerNoteRoute(projectId, id)}
            xss={Css.cursorPointer.df.aic.f24.lh("40px").add("letterSpacing", "0.48px").ttc.gray800.$}
          >
            {title}
            <Icon icon="chevronRight" xss={Css.mw("24px").mh("24px").$} />
          </LinkButton>
          <div css={Css.f16.fw3.lh("24px").gray600.lineClamp2.ifXs.lineClamp3.$}>{description}</div>
        </div>
        {/* Milestones */}
        <div css={Css.df.fdc.pr4.gap1.nowrap.ifXs.pr0.$}>
          <div css={Css.mb("4px").f12.add("letterSpacing", "0.48px").ttu.$}>Milestones</div>
          <div css={Css.df.fdc.gap1.nowrap.ifXs.fdr.add("flexFlow", "wrap").$}>
            {schedulePhases.slice(0, 2).map(({ name, id }, idx) => {
              const schedulePhaseInfo = getSchedulePhaseInfo(name);
              return (
                <>
                  <LinkButton
                    key={`${name}-${idx}`}
                    xss={Css.f16.fw5.add("letterSpacing", "0.32px").gray600.$}
                    to={milestoneRoute(projectId, id)}
                    underline="initial"
                  >
                    {schedulePhaseInfo.replacementTitle || name}
                  </LinkButton>
                  {idx !== numSchedulePhases - 1 && <div css={Css.bGray300.br.$} />}
                </>
              );
            })}
            {numSchedulePhases >= 3 && (
              <div key="and-more" css={Css.f16.fw5.add("fontStyle", "italic").$}>
                {`& ${numSchedulePhases + 1 - 3} more`}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Carousel Row */}
      <div css={Css.ifXs.w("100vw").relative.left("-56px").$}>
        <AssetGallery assets={jobLogImages.map((i) => i.asset)} showZoomInOutIcons download zoom>
          {(openGallery) => (
            <div css={Css.w100.$}>
              <ImageCarousel
                items={jobLogImages.map(({ asset, id }) => (
                  <div key={id} css={Css.wPx(565).hPx(340).cursorPointer.$} onClick={() => openGallery(asset)}>
                    <img
                      src={asset?.previewUrl || ""}
                      alt={id}
                      placeholder={id}
                      css={Css.w100.objectCover.add("aspectRatio", "5/3").$}
                    />
                  </div>
                ))}
              />
            </div>
          )}
        </AssetGallery>
      </div>
    </div>
  );
};

type TimelinePoints = {
  [year: number]: {
    [month: number]: JourneyNoteFragment[];
  };
};
